import productsTypes from "./products.types";
import productTypes from "./products.types";

const INITIAL_STATE = {
  searchProductResults: [],
  homeTopProducts: [],
  latestProducts: [],
  homeButtomProducts: [],
  
  productSpecials: [],

  categorizedProducts: [],
  products: [],
  product: {},
  filterResults: false,
};

const productsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case productTypes.SET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case productTypes.SET_TOP_PRODUCTS:
      return {
        ...state,
        homeTopProducts: action.payload,
      };
    case productTypes.SET_SEARCH_RESULTS:
      return {
        ...state,
        searchProductResults: action.payload,
      };
    case productTypes.SET_BOTTOM_PRODUCTS:
      return {
        ...state,
        homeButtomProducts: action.payload,
      };
    case productTypes.SET_PRODUCT_SPECIALS:
        return {
          ...state,
          productSpecials: action.payload,
        };
    case productTypes.SET_CATEGORIZED_PRODUCTS:
      return {
        ...state,
        categorizedProducts: action.payload,
      };
    case productTypes.SET_LATEST_PRODUCTS:
      return {
        ...state,
        latestProducts: action.payload,
      };
    case productsTypes.SET_PRODUCT:
      return {
        ...state,
        product: action.payload,
      };

    case productsTypes.SET_FILTER_RESULTS:
      return {
        ...state,
        filterResults: action.payload,
      };

    default:
      return state;
  }
};

export default productsReducer;
