
import React, {  useState , useEffect } from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import { addCategoryStart  } from '../../../redux/Product-categories/categories.actions';

import FormInput from '../../Forms/FormInput/index';
import FormInputArea from '../../Forms/FormInputArea/index';
import { Button, Dropdown, DropdownButton  } from 'react-bootstrap';

import '../style.css';

const mapState =  state => ({
    groups: state.categoryGroupsData.groups
 });

const  AddNewCategoryWrapper = props => {
  
    const { groups } = useSelector(mapState);

    const dispatch = useDispatch();
    //const [hideModal, setHideModal] = useState(true);
    const [categoryName, setCategoryName] = useState('');
    const [positionIndex, setPositionIndex] = useState('');
    const [categoryThumbnail, setCategoryThumbnail] = useState('');
    const [categoryDesc, setCategoryDesc] = useState('');
    const [ categoryGroup, setProductCategoryGroup ] = useState('');

    useEffect(() => {
    }, [dispatch]);
    
    const resetForm = () => {
     // setHideModal(true);
      setPositionIndex('');
      setCategoryName('');
      setCategoryThumbnail('');
      setCategoryDesc('');
      setProductCategoryGroup('');
    };
  
    const handleSubmit = e => {
      e.preventDefault();
  
      dispatch(
        addCategoryStart({
          categoryName,
          positionIndex,
          categoryThumbnail,
          categoryDesc,
          categoryGroup
        })
      );
      resetForm();
    };
  
    return (
                    
    <div className="modal fade" id="categoryModal" tabIndex="-2" role="dialog">
        <div className="modal-dialog modal__container" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>

                <div className="modal-body">
                    <div className="modal-product">
                        
                        <form onSubmit={ handleSubmit }>
                        <div className="product-info">
                            <h1>Category Details</h1>

                            <div className="quick-desc">
                        
                                 <label>Category Group (Click to change)</label>
                                <DropdownButton id="dropdown-basic-button" title={ !categoryGroup ? 'No Selection' : categoryGroup } >
                                    {(Array.isArray(groups) && groups.length > 0) && groups.map((group, index) => {
                                                    
                                        const {
                                            title
                                        } = group;
                                            return (
                                            <Dropdown.Item href={`#/action-${index}`} onSelect={() => setProductCategoryGroup(title.toLowerCase()) }>{ title }</Dropdown.Item>
                                        )
                                    })}

                                </DropdownButton>
                            </div>

                            
                    
                            <div className="quick-desc">
                                <FormInput
                                label="Name"
                                type="text"
                                value={categoryName}
                                handleChange={e => setCategoryName(e.target.value)}
                                /> 
                            </div>

                            <div className="quick-desc">
                                <FormInput
                                label="Position"
                                type="number"
                                value={positionIndex}
                                handleChange={e => setPositionIndex(e.target.value)}
                                /> 
                            </div>

                            <div className="quick-desc">
                                <FormInputArea
                                label="Description"
                                type="textarea"
                                rows="2"
                                value={categoryDesc}
                                handleChange={e => setCategoryDesc(e.target.value)}
                                />
                            </div>
                        
                            <div className="htc__submit__btn">
                                <Button className="btn link" onClick={ handleSubmit } data-dismiss="modal" aria-label="Close">
                                    Add Category
                                </Button>
                            </div>
                            
                        </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
            
    )
}

export default AddNewCategoryWrapper;
