import React, { Component } from 'react';
import { FacebookProvider, Like, Share, ShareButton , Comments, EmbeddedPost, Page, Feed} from 'react-facebook';

export default class PolicyReturnsPage extends Component {
    render() {
        return (
            <div className="wrapper fixed__footer">
                <div className="row">

                <div className="ht__bradcaump__area">
                    <div className="ht__bradcaump__wrap">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="bradcaump__inner text-center">
                                        <h2 className="bradcaump-title">Delivery Policy</h2>
                                        <nav className="bradcaump-inner">
                                        <a className="breadcrumb-item" href="/">Home</a>
                                        <span className="brd-separetor">/</span>
                                        <span className="breadcrumb-item active">Delivery Policy</span>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <section className="htc__store__area ptb--50 bg__white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section__title section__title--2 text-center">
                                    <h2 className="title__line">DELIVERY TERMS AND CONDITIONS</h2>

                                    <p>
                                    Orders will be processed within 24 hours after receipt of payment
                                    In the event of the delivery being to a Regional Area, allow for 72 to 96 hours for delivery. PAXI orders are only collected at preferred PEP branch. Postnet orders are only collected at preferred POSTNET branch.
                                    ARAMEX is store to door delivery.
                                    </p>
                                    <p> 
                                    If we anticipate that delivery will take longer than this,
                                    We will contact you to let you know. All orders placed on weekends will be processed on the following Monday. Deliveries are
                                    Only made to valid addresses within South Africa, on business days only (which excludes Saturdays and
                                    Sundays).
                                    </p>
                                    <br/>
                                    <br/>
                                    <h2 className="title__line">TRACKING</h2>
                                    <p>
                                                                       
                                    Once your order has been shipped by the courier company you will receive
                                    An Email or an SMS confirmation providing full shipping details, as well as a tracking number.
                                    </p>

                                </div>
                             </div>
                        </div>
                    </div>
                </section>

            </div>
        </div>
        )
    }
}
