
import React, {  useState , useEffect } from 'react';
import { useDispatch  } from 'react-redux';
import { fetchCategoryGroupStart  } from '../../../redux/CategoryGroups/category.group.actions';
import FormInput from '../../Forms/FormInput/index';
import FormInputArea from '../../Forms/FormInputArea/index';
import { Button } from 'react-bootstrap';

import '../style.css';

const  AddNewCategoryGroupWrapper = props => {
  
    const dispatch = useDispatch();
    //const [hideModal, setHideModal] = useState(true);
    const [ Title, setTitle ] = useState('');
    //const [ Photo, setPhoto ] = useState('');
    const [ Description, setDescription ] = useState('');
  
    useEffect(() => {
      dispatch(
        fetchCategoryGroupStart()
      );
    }, [dispatch]);
    
    const resetForm = () => {
     
      setTitle('');
      //setPhoto('');
      setDescription('');
    };
  
    const handleSubmit = e => {
      e.preventDefault();
  
    //   dispatch(
    //     addCategoryGroupStart({
    //       Title,
    //       Description,
    //       Photo
    //     })
    //   );
      resetForm();
  
    };
  
    return (
                    
    <div className="modal fade" id="categoryGroupModal" tabIndex="-2" role="dialog">
        <div className="modal-dialog modal__container" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>

                <div className="modal-body">
                    <div className="modal-product">
                        
                        <form onSubmit={ handleSubmit }>
                        <div className="product-info">
                            <h1>Category Group </h1>
                            
                            <div className="quick-desc">
                                <FormInput
                                label="Name"
                                type="text"
                                value={Title}
                                handleChange={e => setTitle(e.target.value)}
                                /> 
                            </div>

                            <div className="quick-desc">
                                <FormInputArea
                                label="Description"
                                type="textarea"
                                rows="2"
                                value={Description}
                                handleChange={e => setDescription(e.target.value)}
                                />
                            </div>

                            <div className="htc__submit__btn">
                                <Button className="btn link" onClick={ handleSubmit } data-dismiss="modal" aria-label="Close">
                                    Add Group
                                </Button>
                            </div>

                        </div>
                        </form>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
            
    )
}

export default AddNewCategoryGroupWrapper;
