const colorTypes = {
    ADD_NEW_COLOR_START: 'ADD_NEW_COLOR_START',
    FETCH_COLORS_START: 'FETCH_COLORS_START',
    FETCH_COLOR_START: 'FETCH_COLOR_START',
    SET_COLORS: 'SET_COLORS',
   
    SET_COLOR: 'SET_COLOR',
    DELETE_COLOR_START: 'DELETE_COLOR_START',
  
    SET_EDIT_COLOR: 'SET_EDIT_COLOR',
    EDIT_COLOR_START: 'EDIT_COLOR_START',
};
  
export default colorTypes;