import React, { useState ,useRef  } from 'react';
import { Button}  from 'react-bootstrap';
import {  Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addProduct } from '../../redux/Cart/cart.actions';
import { SiteConsumer } from '../../context';
import {
  
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,

    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,

  } from "react-share";

import './quikview.css';

const ProductQuickviewWrapper = ( props ) => {

    const dispatch = useDispatch();
    const [selectedColor, setSelectedColor ] = useState('');
    const [selectedSize, setSelectedSize ] = useState('');
    const [ message, setMessage ] = useState('');

    const myCloseBtnRef= useRef(null);

return (
  <SiteConsumer> 

    {(param) => {
        
        const { 
            selectedProduct
        } = param;

        //setMessage("");

    const {
        documentID,
        productThumbnail,
        productName,
        productPrice,
        productDesc,
        productColors,
        productInStock,
        productSizes,
        productCategory
    } = selectedProduct;

    const resetSelections = () =>{
        setSelectedColor('');
        setSelectedSize('');
        setMessage('');
    }
 
    const configAddToCartBtn = {
      type: 'button'
    };

    const handleAddToCart = ( product) => {
   
        if (!product) return;

        if(!selectedColor && productColors.length > 0) {
            setSelectedColor(productColors[0]);
        }

        if(!selectedSize && productSizes.length ===1) {
            setSelectedSize(productSizes[0]);  
        }

        if( productColors.length > 1 && (!selectedColor)) {
            alert('Please select a product color!');
            return;
        }
        if( productSizes.length > 1 &&  !selectedSize){
            alert('Please select a product size!');
            return;
        }
    
        let currentProduct = {};
        currentProduct.documentID = selectedProduct.documentID;
        currentProduct.productThumbnail = selectedProduct.productThumbnail;
        currentProduct.productName =selectedProduct.productName;
        currentProduct.productPrice = selectedProduct.productPrice;
        currentProduct.productDesc = selectedProduct.productDesc;
        currentProduct.productColors = selectedProduct.productColors;
        currentProduct.productSizes = selectedProduct.productSizes;
        currentProduct.productCategory = selectedProduct.productCategory;
        currentProduct.productAdminUserUID = selectedProduct.productAdminUserUID;
        currentProduct.selectedColor = selectedColor;
        currentProduct.selectedSize= selectedSize;

        dispatch( addProduct(currentProduct));
        resetSelections();

        setMessage("product successfuly added to cart");
        setTimeout(() => {
            setMessage("");
            if(myCloseBtnRef) {
                document.getElementById("closeModalBtn").click();
              }
          }, 1000);
    
     };

     const pickColor = (color) => {
        setSelectedColor(color);
    }

    const pickSize = (size) => {
        setSelectedSize(size);
    }

    return(
    
    <div className="modal fade" id="productModal" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal__container" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    { message && (<h3 className="messageClass">{ message }</h3>) }
                    <button id="closeModalBtn" ref={ myCloseBtnRef }  type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body">
                    <div className="modal-product">
                        
                        <div className="product-images">
                            <div className="main-image images">
                                <img className="overview-img" alt="big images" src={productThumbnail} />
                            </div>
                        </div>
                        
                        <div className="product-info">
                            <h1> { productName }</h1>
                            
                            <div className="rating__and__review">
                                <p className="outofstock">{ productInStock === '2' ? "Out of Stock" : ''}</p>
                            </div>
                            
                            <div className="price-box-3">
                                <div className="s-price-box">
                                    <span className="new-price"> R {productPrice}</span>
                                   
                                </div>
                            </div>
                            <div className="quick-desc">
                            
                            { productDesc  &&
                            <div>
                                { productDesc.split(",").map( (feature, index) => 
                                    (
                                        <p key={index}>{ feature }</p>
                                    )
                                )}
                            </div>
                            }

                               
                            </div>
                            <div className="single__filter">
                                <h2>color</h2>

                             
                                {productColors  &&
                                
                                    <ul className="color__list">
                                        {productColors.map( (d, index) => 
                                            (
                                            <li key={d} className={ d }  onClick= { e => pickColor(d) }  ><Link to="#" title={d}></Link> {d} </li>
                                            )
                                            
                                         )} 

                                    </ul>
                                }

                              {productColors &&
                                productColors.length === 1 ? 
                               <p className="small"> selected:  {productColors[0]}</p>
                               : (<p className="small">{ selectedColor ?  'selected: ' + selectedColor : 'no color selected'}</p>) 
                              }
                                
                            </div>
                            <div className="single__filter">
                                <h2>Select size</h2>

                                {productSizes  &&
                                    <ul className="color__list">
                                        {productSizes.map(d => (
                                            <li key={d} className='l__size'  onClick={ e=> pickSize(d) }> 
                                                <Link to="#" title={d} className={ selectedSize === d ? 'selectedbuble': '' }></Link> {d} 
                                            </li>
                                            ))
                                        } 

                                    </ul>
                                }

                            {productSizes &&
                                productSizes.length === 1 ? 
                               <p className="small"> selected:  {productSizes[0]}</p>
                               : (<p className="small">{ selectedSize ?  'selected: ' + selectedSize : 'no size selected'}</p>) 
                            }
                             
                            </div>
                            <div className="social-sharing">
                                <div className="widget widget_socialsharing_widget">
                                    <h3 className="widget-title-modal">Share this product</h3>
                                    <ul className="social-icons">
                                   
                
                                        <FacebookShareButton url={ `${ window.location.host }/product/${documentID}`} quote={"let others know"} className="share">
                                            <FacebookIcon size={32} round={true}/>
                                        </FacebookShareButton>

                                        <WhatsappShareButton url={ `${ window.location.host }/product/${documentID}`} quote={ productName } className="share">
                                            <WhatsappIcon size={32} round={true}/>
                                        </WhatsappShareButton>

                                        <LinkedinShareButton url={ `${ window.location.host }/product/${documentID}`} quote={ productName } className="share">
                                            <LinkedinIcon size={32} round={true}/>
                                        </LinkedinShareButton>

                                        <TwitterShareButton url={ `${ window.location.host }/product/${documentID}`} quote={ productName } className="share">
                                            <TwitterIcon size={32} round={true}/>
                                        </TwitterShareButton>
                                
                                    </ul>
                                </div>
                            </div>
                            <div className="addtocart-btn">
                                <Button {...configAddToCartBtn}  onClick={(e) => { handleAddToCart( selectedProduct); }}  disabled={ productInStock === '2' ? true: false } >
                                    Add to cart
                                </Button>

                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
            
    )
   }}
  </SiteConsumer>

 )
    
};

export default ProductQuickviewWrapper;
