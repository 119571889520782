const categoriesTypes = {
    ADD_NEW_CATEGORY_START: 'ADD_NEW_CATEGORY_START',
    FETCH_CATEGORIES_START: 'FETCH_CATEGORIES_START',
    SET_CATEGORIES: 'SET_CATEGORIES',
    SET_GROUPED_CATEGORIES: 'SET_GROUPED_CATEGORIES',
    SET_CATEGORY: 'SET_CATEGORY',
    DELETE_CATEGORY_START: 'DELETE_CATEGORY_START',
    FETCH_CATEGORY_START: 'FETCH_CATEGORY_START',

    SET_EDIT_CATEGORY: 'SET_EDIT_CATEGORY',
    EDIT_CATEGORY_START: 'EDIT_CATEGORY_START',
};
  
export default categoriesTypes;