
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { signUpUserStart } from './../../redux/User/user.actions';
import AuthWrapper from '../AuthWrapper/AuthWrapper';

import './styles.css';

const mapState = ({ user }) => ({
    currentUser: user.currentUser,
    userErr: user.userErr
  });

const SignUp = props => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { currentUser, userErr } = useSelector(mapState);
    const [displayName, setDisplayName] = useState('');
    const [ email, setEmail ]  = useState(''); 
    const [password, setPassword ]  = useState('');
    const [confirmPassword, setConfirmPassword ]  = useState('');
    const [errors, setErrors ]  = useState([]);

    useEffect(() => {
        if (currentUser) {
          resetForm();
          history.push('/');
        }
      }, [currentUser, history]);

    useEffect(() => {
        if (Array.isArray(userErr) && userErr.length > 0) {
            setErrors(userErr);
        }
    }, [userErr]);

    const resetForm = () => {
        setDisplayName('');
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        setErrors([]);
    }

    const handleFormSubmit = event => {
        event.preventDefault();
        dispatch(signUpUserStart({
          displayName,
          email,
          password,
          confirmPassword
        }));
      }

    const configAuthWrapper = {
        headline: 'Registration'
    };

    return (
      <AuthWrapper {...configAuthWrapper}>
        <div>
            {errors.length > 0 && (
                <ul className="error-list">
                    {errors.map( (err, index) => {
                        return (
                            <li key={index}>{err}</li>
                            )
                    } )}
                </ul>  
            )}

            <form 
                onSubmit= { handleFormSubmit }
                className="login" 
                method="post">
                <input type="text" name="displayName" placeholder="Name*" value={ displayName } onChange={ e => setDisplayName(e.target.value)} />
                <input type="email" name="email" placeholder="Email*" value={ email } onChange={ e => setEmail(e.target.value)} />
                <input type="password" name="password" placeholder="Password*" value={ password } onChange ={ e => setPassword(e.target.value)} />
                <input type="password" name="confirmPassword" value={ confirmPassword} onChange={ e => setConfirmPassword(e.target.value)} placeholder="Confirm-Password*" />
                
                <div className="htc__login__btn">
                    <Button type="submit" className="btn link light" size="lg" >Register</Button>
                </div>
            </form>

            <div className="tabs__checkbox">
                <input type="checkbox" />
                <span> Remember me</span>
            </div>
            

            <div className="htc__social__connect">
                <h2>Or Login With</h2>
                <ul className="htc__soaial__list">
                     <li><Link to={{ pathname: "https://www.instagram.com/anointed_republic" }} target="_blank"><i className="zmdi zmdi-instagram"></i></Link></li>
                     <li><Link to={{ pathname: "https://www.facebook.com/anointedrepublic" }} target="_blank"><i className="zmdi zmdi-facebook"></i></Link></li>
                </ul>
            </div>
        </div>
     </AuthWrapper>
    )
    
}


export default SignUp;