const appAuthTypes = {
   
    FETCH_TOKEN_START: 'FETCH_TOKEN_START',
    FETCH_TOKEN_SUCCESS: 'FETCH_TOKEN_SUCCESS',
    FETCH_TOKEN_FAILURES: 'FETCH_TOKEN_FAILURES',

    FETCH_VALIDATE_TOKEN_START: 'FETCH_VALIDATE_TOKEN_START',
    FETCH_VALIDATE_TOKEN_SUCCESS: 'FETCH_VALIDATE_TOKEN_SUCCESS',
    FETCH_VALIDATE_TOKEN_FAILURES: 'FETCH_VALIDATE_TOKEN_FAILURES',

    CHECK_APP_TOKEN_VALIDITY: "CHECK_APP_TOKEN_VALIDITY",

    SET_TOKEN: 'SET_TOKEN'
};
  
export default appAuthTypes;