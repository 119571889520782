import React, { useState, useEffect } from 'react';
//import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import FormInput from '../Forms/StyledFormInput/index';
import { Button } from 'react-bootstrap';
//import FormSelect from '../Forms/FormSelect/index';

import { CountryDropdown } from 'react-country-region-selector';
//import { apiInstance } from '../../utils/Util';
import { selectCartTotal, selectCartItemsCount, selectCartItems } from '../../redux/Cart/cart.selectors';
import { saveOrderHistory } from '../../redux/Orders/orders.actions';
//import { clearCart } from '../../redux/Cart/cart.actions';
import { createStructuredSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { appEnvConfig } from '../../yoco/config';

import './styles.css';
import paymentOpt1 from '../../assets/payments/01.png';
import paymentOpt2 from '../../assets/payments/02.png';

const initialAddressState = {
  line1: '',
  line2: '',
  city: '',
  state: '',
  postal_code: '',
  country: 'South Africa',
  phone: '',
  email: ''
};

const mapState = createStructuredSelector({
  total: selectCartTotal,
  itemCount: selectCartItemsCount,
  cartItems: selectCartItems,
 
});

const PaymentDetails = () => {

  const history = useHistory();
  const { total, itemCount, cartItems } = useSelector(mapState);
  const dispatch = useDispatch();
  const [ shippingAddress, setShippingAddress] = useState({ ...initialAddressState });
  const [ recipientName, setRecipientName] = useState('');
  
  const [ deliveryOptionsData, setDliveryOptionsData ] = useState([]);
  const [ deliveryOption, setDeliveryOption ] = useState('59.99');
  const [ deliveryOptionID, setDeliveryOptionID ] = useState(1);
  
  const [ totalPrice, setTotalPrice ] = useState(0);
  const [ doorDelivery, setDoorDelivery ] = useState(false);
  const [ deliveryVendor, setDeliveryVendor ] = useState('');
  const [ collectionBranch, setCollectionBranch ] = useState('');
  const [show, setShow] = useState(false);

  const [ initialChange, setInitialChange ] = useState(true);
  const [ freeDelivery, setFreeDelivery ] = useState(false);
  const FREE_DELIVERY_AMOUNT = 400;

  const deliveryOptionById = ({
    id
  }) => {
    return deliveryOptionsData.find(
      option => option.id === id
    );
  };

  useEffect(() => {
    if (itemCount < 1) {
      history.push('/');
    }
   let deliveryOptions= [ 
      { id: 1, value: 59.99 , display: "PAXI (PEP) ........ R59.99 (7 - 9 Business days delivery)", vendor : "PAXI (PEP)" },
      { id: 2, value: 99.99 , display: "PAXI (PEP) ........ R99.99 (2 - 3 Business days delivery)", vendor : "PAXI (PEP)" },
      { id: 3, value: 99 , display: "POSTNET ........ R99 (1 - 3 Business days delivery)", vendor : "POSTNET"  },
      { id: 4, value: 99 , display: "ARAMEX  ........ R99 (1 - 2 Business days door delivery)", vendor : "ARAMEX"  }
    ];

    
    setFreeDelivery(false);
    setInitialChange('59.99');
    
    if(total >= FREE_DELIVERY_AMOUNT ) {
      
      setFreeDelivery(true);

    if(initialChange)
      setDeliveryOption('0.0');

      let objIndex = deliveryOptions.findIndex((obj => obj.id === 1));
          deliveryOptions[objIndex].display = 'PAXI (PEP) FREE DELIVERY....... (7 - 9 Business days delivery)';
          deliveryOptions[objIndex].value = 0;
    } else{

      let option = deliveryOptionById(deliveryOptionID);
      if(option) {
        console.log("option value = " + option.value);
      }
      //deliveryOptions.filter();
      setDeliveryOption('59.99');
    }

    setTotalPrice(roundN((total + Number(deliveryOption)),2));
    setDliveryOptionsData(deliveryOptions);

  }, [itemCount, history, total, deliveryOption ]);

  function roundN(num,n){
    return parseFloat(Math.round(num * Math.pow(10, n)) /Math.pow(10,n)).toFixed(n);
  }

  const handleShipping = evt => {
    const { name, value } = evt.target;
    setShippingAddress({
      ...shippingAddress,
      [name]: value
    });
  };

  const updateTotalPrice = (id, deliveryCharge, vendor) => {

    setDeliveryOptionID(id);
    setDeliveryOption(deliveryCharge);
    setDeliveryVendor(vendor);

    if(initialChange) 
      setInitialChange(false);

    let currentTotal = roundN((total + Number(deliveryCharge)),2);
    setTotalPrice(currentTotal);

    if(id === 4) {
      setDoorDelivery(true);
    }else {
      setDoorDelivery(false);
    }

  }
  const makePaymentPopup = () => {

    if (
      !shippingAddress.line1 || !shippingAddress.city ||
      !shippingAddress.state || !shippingAddress.postal_code ||
      !shippingAddress.country || !recipientName ||
      !shippingAddress.email
    ) {
      return;
    }

    let newitem = {};
    let order_items = [];
    let orderCreate = {};
    
    cartItems.forEach( function(item) {

      newitem = {};
      newitem.quantity = item.quantity;
      newitem.user = item.productAdminUserUID;
      newitem.name = item.productName;
      newitem.price = item.productPrice;
      newitem.thumbnail = item.productThumbnail
      newitem.selectedColor = item.selectedColor;
      newitem.selectedSize = item.selectedSize;

      order_items.push(newitem);
    });  
    
    // yoco
     let yoco = new window.YocoSDK({
      publicKey: appEnvConfig.publicKey,
    });
    

    yoco.showPopup({
      amountInCents: (totalPrice * 100),
      currency: 'ZAR',
      name: 'Anointed Republic',
      description: 'Items Purchased x ' + itemCount,
      callback: function (result) {


        if (result.error) {
            const errorMessage = result.error.message;
            alert("error occured: " + errorMessage);
        } else {
            setShow(true);
           //build up create order json
          orderCreate.appId = appEnvConfig.applicationId;
          orderCreate.recipientName = recipientName;
          orderCreate.token = result.id;
          orderCreate.amountInCents = (totalPrice * 100);
          orderCreate.shippingAddress  = shippingAddress;
          orderCreate.deliveryOption = deliveryOption;
          orderCreate.deliveryVendor = deliveryVendor;
          orderCreate.collectionBranch = collectionBranch;
          orderCreate.orderItems = order_items;
   
          let axios = require('axios');
          let data = JSON.stringify(orderCreate);
       
          var config = {
            method: 'post',
            url: process.env.REACT_APP_YOCO_CHARGES_V2_ENDPOINT,
            headers: { 
              'Content-Type': 'application/json',
              'appid' : appEnvConfig.applicationId
            },
            data : data
          };

          axios(config)
          .then(function (response) {
         
            setShow(false);

            alert(response.data.message);

            if(!response.data.status  || response.data.status !== "successful") {
              setShow(false);
              alert(response.data.message);
              return ;
            }
            let json = response.data;
            let status = json.status;
            let chargeId = json.charge_id;
            let amountInCents = json.amountInCents;
           
            const configOrder = {
              orderTotal: total,
              totalPaid: amountInCents,
              paymentApiChargeId: chargeId,
              paymentApiSatus: status,
              orderItems: cartItems.map(item => {
                const { 
                  documentID, 
                  productThumbnail, 
                  productName,
                  productPrice, 
                  quantity 
                } = item;

                return {
                  documentID,
                  productThumbnail,
                  productName,
                  productPrice,
                  quantity
                };
              })
            }

            dispatch( saveOrderHistory(configOrder));

          })
          .catch(function (error) {
            setShow(false);
            alert("error occured: " + error);
            console.log(error);
          });
        }
      }
    });
  }

  const handleFormSubmit = async evt => {
    evt.preventDefault();
   
   
    if (
      !shippingAddress.line1 || !shippingAddress.city ||
      !shippingAddress.state || !shippingAddress.postal_code ||
      !shippingAddress.country || !recipientName 
    ) {
      return;
    }

  };
  

  return (

    <section className="our-checkout-area ptb--120 bg__white">
     <div className="container">
      <div className="row">

      { show  &&
        <div className="loader"></div>
      }

      <form onSubmit={handleFormSubmit}>

       <div className="col-md-8 col-lg-8">
        <div className="ckeckout-left-sidebar">
        
        <div className="checkout-form">  
            <h2 className="section-title-3">Shipping details</h2>
            { freeDelivery && 
              (
                <p>You Qualify for a free delivery</p>
              ) 
            }
           
          <div className="checkout-form-inner">
              <div className="single-checkout-box">
                    <FormInput
                        required
                        placeholder="Recipient Name"
                        name="recipientName"
                        handleChange={evt => setRecipientName(evt.target.value)}
                        value={recipientName}
                        type="text"
                    />

                    <FormInput
                        required
                        placeholder="Address Line 1"
                        name="line1"
                        handleChange={evt => handleShipping(evt)}
                        value={shippingAddress.line1}
                        type="text"
                    />
                </div>

                <div className="single-checkout-box">
                    <FormInput
                        placeholder="Address Line 2"
                        name="line2"
                        handleChange={evt => handleShipping(evt)}
                        value={shippingAddress.line2}
                        type="text"
                    />

                    <FormInput
                        required
                        placeholder="City"
                        name="city"
                        handleChange={evt => handleShipping(evt)}
                        value={shippingAddress.city}
                        type="text"
                    />
                </div>

                <div className="single-checkout-box">
                    <FormInput
                        required
                        placeholder="State"
                        name="state"
                        handleChange={evt => handleShipping(evt)}
                        value={shippingAddress.state}
                        type="text"
                    />

                    <FormInput
                        required
                        placeholder="Postal Code"
                        name="postal_code"
                        handleChange={evt => handleShipping(evt)}
                        value={shippingAddress.postal_code}
                        type="text"
                    />
                </div>

                <div className="single-checkout-box">
                    <FormInput
                        required
                        placeholder="Phone"
                        name="phone"
                        handleChange={evt => handleShipping(evt)}
                        value={shippingAddress.phone}
                        type="text"
                    />
              
                    <FormInput
                        required
                        placeholder="Email"
                        name="email"
                        handleChange={evt => handleShipping(evt)}
                        value={shippingAddress.email}
                        type="email"
                    />
                </div>

                <div className="single-checkout-box">
                    <div className="formRow checkoutInput">
                        <CountryDropdown
                        required
                        onChange={val => handleShipping({
                            target: {
                            name: 'country',
                            value: val
                            }
                        })}
                        value={shippingAddress.country}
                        valueType="short"
                        />
                    </div>
                </div>

      
              </div>
            </div>

            <div className="our-payment-sestem">
                <br/>
                <h2 className="section-title-3">Delivery Options</h2>

                { freeDelivery && 
                  (
                    <p style={{ fontWeight: 'bold', color: 'maroon', fontStyle: 'italic' }}>You qualify for a free delivery, Ts & Cs Apply</p>
                  ) 
                }
                {(Array.isArray(deliveryOptionsData) && deliveryOptionsData.length > 0) && deliveryOptionsData.map((option, index) => {
                      const {
                          display,
                          value,
                          id,
                          vendor
                      } = option;
                        return (
                          <div  key={ index }> <input key={ index }
                          defaultChecked={index === 0} className="round_radio" type="radio" value={ value } name="delivery" onClick={ () => updateTotalPrice(id,value,vendor) } /> { display }</div>
                        )
                      
                  })}
          

                <br/>

                { !doorDelivery  &&
                <div className="single-checkout-box">
                    <FormInput
                        required
                        placeholder="branch"
                        name="branch"
                        handleChange={evt => setCollectionBranch(evt.target.value)}
                       
                        type="text"
                    />
                </div>
                }
       
            </div>

            <div className="our-payment-sestem">
              
                <h2 className="section-title-3">We  Accept :</h2>

                <div className="paymentOptions" >
                  <img src={ paymentOpt1 } alt="payment option 1" />
                  <img src={ paymentOpt2 } alt="payment option 2" />
                </div>

                <ul className="payment-menu">
                </ul>   
            </div>
     
            <div id="payment-form">
              <div className="one-liner">
                  <div id="card-frame">
                  </div>
                  <Button id="pay-button" onClick={ (e) => makePaymentPopup() }>
                      PAY ZAR { roundN(totalPrice, 2) } 
                  </Button>
                  <span>Incl. Delivery </span>
              </div>
              <p className="success-payment-message" />
            </div>
     
         </div>
        </div>
        </form>
        
        <div className="col-md-4 col-lg-4">
            <div className="checkout-right-sidebar">
                <div className="our-important-note">
                    <h2 className="section-title-3">Note :</h2>
                    <p className="note-desc">
                      Orders will be processed within 24 hours after receipt of payment
                      In the event of the delivery being to a Regional Area, allow for 72 to 96 hours for delivery. 
                      PAXI orders are only collected at preferred PEP branch. Postnet orders are only collected at preferred POSTNET branch.
                      ARAMEX is store to door delivery.
                    </p>
                    <p>
                      If we anticipate that delivery will take longer than this,
                      We will contact you to let you know. All orders placed on weekends will be processed on the following Monday. Deliveries are
                      Only made to valid addresses within South Africa, on business days only (which excludes Saturdays and
                      Sundays).
                    </p>
                    <ul className="important-note">
                        <li key="index-2" ><Link key="index-1" to="/policy/returns"><i className="zmdi zmdi-caret-right-circle" key="index-3" ></i>Read Returns Policy</Link></li>
                       
                    </ul>
                </div>
                <div className="puick-contact-area mt--60">
                    <h2 className="section-title-3">More Details</h2>
                    <br/>
                    <p>Once your order has been shipped by the courier company you will receive
                      An Email or an SMS confirmation providing full shipping details, as well as a tracking number.</p>
                </div>
            </div>
        </div>
  
        </div>
      </div>
    
    </section>
  );
}

export default PaymentDetails;
