import React from 'react';
import ProductViewDetails from '../components/ProductViewDetails/index';

const ProductDetailsPage = ({}) => {
  return (
    <div>
        <ProductViewDetails />
      
    </div>
  );
}

export default ProductDetailsPage;
