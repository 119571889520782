const categoryGroupTypes = {

    FETCH_CATEGORY_GROUPS_START: 'FETCH_CATEGORY_GROUPS_START',
    FETCH_CATEGORY_GROUPS_SUCCESS: 'FETCH_CATEGORY_GROUPS_SUCCESS',
    FETCH_CATEGORY_GROUPS_FAILURES: 'FETCH_CATEGORY_GROUPS_FAILURES',

    //FETCH_CATEGORY_GROUP_START: 'FETCH_CATEGORY_GROUP_START',
    //FETCH_CATEGORY_GROUP_SUCCESS: 'FETCH_CATEGORY_GROUP_SUCCESS',
    //FETCH_CATEGORY_GROUP_FAILURES: 'FETCH_CATEGORY_GROUP_FAILURES',

    ADD_NEW_CATEGORY_GROUP_START: 'ADD_NEW_CATEGORY_GROUP_START',
    ADD_NEW_CATEGORY_GROUP_SUCCESS: 'ADD_NEW_CATEGORY_GROUP_SUCCESS',
    ADD_NEW_CATEGORY_GROUP_FAILURES: 'ADD_NEW_CATEGORY_GROUP_FAILURES',

    EDIT_CATEGORY_GROUP_START: 'EDIT_CATEGORY_GROUP_START',
    EDIT_CATEGORY_GROUP_SUCCESS: 'EDIT_CATEGORY_GROUP_SUCCESS',
    EDIT_CATEGORY_GROUP_FAILURES: 'EDIT_CATEGORY_GROUP_FAILURES',

    DELETE_CATEGORY_GROUP_START: 'DELETE_CATEGORY_GROUP_START',
    DELETE_CATEGORY_GROUP_SUCCESS: 'DELETE_CATEGORY_GROUP_SUCCESS',
    DELETE_CATEGORY_GROUP_FAILURES: 'DELETE_CATEGORY_GROUP_FAILURES',

    SET_CATEGORY_GROUPS: 'SET_CATEGORY_GROUPS',
    SET_CATEGORY_GROUP: 'SET_CATEGORY_GROUP'
};
  
export default categoryGroupTypes;