import { auth } from '../../firebase/util';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import { setArticles, setArticle, fetchArticlesStart } from './articles.actions';
import { handleAddArticle, handleFetchArticles, handleFetchArticlesByCategory,
  handleFetchArticle, handleDeleteArticle } from './articles.helpers';
import articlesTypes from './articles.types';

export function* addArticle({ payload }) {

  try {
    const timestamp = new Date();
    yield handleAddArticle({
      ...payload,
      articleAdminUserUID: auth.currentUser.uid,
      createdDate: timestamp
    });
    yield put(
      fetchArticlesStart()
    );


  } catch (err) {
    // console.log(err);
  }

}

export function* onAddArticleStart() {
  yield takeLatest(articlesTypes.ADD_NEW_ARTICLE_START, addArticle);
}

export function* fetchArticles({ payload }) {
  try {
    const news = yield handleFetchArticles(payload);
    yield put(
      setArticles(news)
    );

  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchArticlesStart() {
  yield takeLatest(articlesTypes.FETCH_ARTICLES_START, fetchArticles);
}




export function* fetchArticlesByCategory({ payload }) {
  try {
    const news = yield handleFetchArticlesByCategory(payload);
    yield put(
      setArticles(news)
    );

  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchArticlesByCategoryStart() {
  yield takeLatest(articlesTypes.FETCH_ARTICLES_CATEGORY_START, fetchArticlesByCategory);
}


export function* deleteArticle({ payload }) {
  try {
    yield handleDeleteArticle(payload);
    yield put (
      fetchArticlesStart()
    );

  } catch (err) {
    // console.log(err);
  }
}

export function* onDeleteArticleStart() {
  yield takeLatest(articlesTypes.DELETE_ARTICLE_START, deleteArticle);
}

export function* fetchArticle({ payload }) {
  try {
    const article = yield handleFetchArticle(payload);
    yield put(
      setArticle(article)
    );

  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchArticleStart() {
  yield takeLatest(articlesTypes.FETCH_ARTICLE_START, fetchArticle);
}

export default function* newsSagas() {
  yield all([
    call(onAddArticleStart),
    call(onFetchArticlesStart),
    call(onDeleteArticleStart),
    call(onFetchArticleStart),
  ])
}