import React, { Component } from 'react'
import { SiteConsumer } from '../context';

export default class RegisterPage extends Component {
    render() {
        return (
            <SiteConsumer>
              { (param) => {
                
                return(
                    
                    <div className="htc__login__register bg__white ptb--130" style={{ background: "#fff" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-md-offset-3">
                                <ul className="login__register__menu" role="tablist">
                                    <li role="presentation" className="register active"><a href="#register" role="tab" data-toggle="tab">Register</a></li>
                                </ul>
                            </div>
                        </div>
                      
                        <div className="row">
                            <div className="col-md-6 col-md-offset-3">
                                <div className="htc__login__register__wrap">
                                    <div id="register" role="tabpanel" className="single__tabs__panel tab-pane fade">
                                        <form className="login" method="post">
                                            <input type="text" placeholder="Name*" />
                                            <input type="email" placeholder="Email*" />
                                            <input type="password" placeholder="Password*" />
                                        </form>
                                        <div className="tabs__checkbox">
                                            <input type="checkbox" />
                                            <span> Remember me</span>
                                        </div>
                                        <div className="htc__login__btn">
                                            <a href="#">register</a>
                                        </div>
                                        <div className="htc__social__connect">
                                            <h2>Or Login With</h2>
                                            <ul className="htc__soaial__list">
                                                <li><a className="bg--twitter" href="#"><i className="zmdi zmdi-twitter"></i></a></li>
                                                <li><a className="bg--instagram" href="#"><i className="zmdi zmdi-instagram"></i></a></li>
                                                <li><a className="bg--facebook" href="#"><i className="zmdi zmdi-facebook"></i></a></li>
                                                <li><a className="bg--googleplus" href="#"><i className="zmdi zmdi-google-plus"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>

                 )
              }}
               
            </SiteConsumer>
        )
    }
}
