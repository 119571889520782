import { firestore } from "../../firebase/util";

/**
 * handleAddProduct
 */
export const handleAddProduct = (product) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("products")
      .doc()
      .set(product)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * handleEditProduct
 */
export const handleEditProduct = (product) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("products")
      .doc(product.documentID)
      .update(product)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

/**
 * handleSearchProducts
 */
export const handleSearchProducts = ({ keywords, persistProducts = [] }) => {
  return new Promise((resolve, reject) => {
    let ref;
    //ref = firestore.collection('products').where('productName', '>=',  keywords ).where('productName', '<=', keywords + '\uf8ff');
    ref = firestore
      .collection("products")
      .orderBy("createdDate", "desc")
      .limit(100);

    ref
      .get()
      .then((snapshot) => {
        const totalCount = snapshot.size;

        let results = [];
        const data = [
          ...persistProducts,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              documentID: doc.id,
            };
          }),
        ];

        //results  = data.find(p => p.name === keywords);
        data.forEach((product) => {
          if (
            product.productName.toLowerCase().includes(keywords.toLowerCase())
          ) {
            results.push(product);
          }
        });

        resolve({
          data: results,
          queryDoc: snapshot.docs[totalCount - 1],
          isLastPage: totalCount < 1,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleFetchLatestProductsInStock = ({
  filterType,
  startAfterDoc,
  persistProducts = [],
}) => {
  return new Promise((resolve, reject) => {
    let ref;
    let filter = filterType ? filterType.split(",") : [];
    const pageSize = 20;

    if (filter.length === 0)
      ref = firestore
        .collection("products")
        .where("productInStock", "==", "1")
        .orderBy("createdDate", "desc")
        .limit(pageSize);
    if (filter.length > 0)
      ref = firestore
        .collection("products")
        .where("productCategory", "in", filter)
        .orderBy("productCategory")
        .orderBy("createdDate", "desc")
        .limit(pageSize);

    ref
      .get()
      .then((snapshot) => {
        const totalCount = snapshot.size;

        const data = [
          ...persistProducts,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              documentID: doc.id,
            };
          }),
        ];

        resolve({
          data,
          queryDoc: snapshot.docs[totalCount - 1],
          isLastPage: totalCount < 1,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * handleFetchProducts
 */
export const handleFetchProducts = ({
  filterType,
  startAfterDoc,
  persistProducts = [],
}) => {
  return new Promise((resolve, reject) => {
    let filterArray = filterType ? filterType.split(",") : [];
    const pageSize = 20;
    let ref;

    if (filterArray.length === 0) {
      ref = firestore
        .collection("products")
        .orderBy("productCategoryGroup")
        .orderBy("createdDate", "desc")
        .limit(pageSize);
      if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
    }
    if (filterArray.length > 0) {
      ref = firestore
        .collection("products")
        .where("productCategory", "in", filterArray);
    }

    ref
      .get()
      .then((snapshot) => {
        const totalCount = snapshot.size;
        const data = [
          ...persistProducts,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              documentID: doc.id,
            };
          }),
        ];

        resolve({
          data,
          queryDoc: snapshot.docs[totalCount - 1],
          isLastPage: totalCount < 1,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};


/**
 * handleFetchProductsOnSpecial
 */
 export const handleFetchProductsOnSpecial = ({ persistProducts = [], pagesize }) => {
  return new Promise((resolve, reject) => {
    let ref = firestore
      .collection("products")
      .orderBy("productInStock")
      .startAt(0)
      .limit(pagesize);
    //let ref = firestore.collection('products').orderByChild('createdDate','').startAt(0).limitToLast(pagesize);

     ref = firestore
     .collection("products")
     .where("productOnSale", "==", '1');

    ref
      .get()
      .then((snapshot) => {
        const totalCount = snapshot.size;

        const data = [
          ...persistProducts,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              documentID: doc.id,
            };
          }),
        ];

        resolve({
          data,
          queryDoc: snapshot.docs[totalCount - 1],
          isLastPage: totalCount < 1,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * handleFetchHomeProducts
 */
export const handleFetchHomeProducts = ({ persistProducts = [], pagesize }) => {
  return new Promise((resolve, reject) => {
    let ref = firestore
      .collection("products")
      .orderBy("productInStock")
      .startAt(0)
      .limit(pagesize);
    //let ref = firestore.collection('products').orderByChild('createdDate','').startAt(0).limitToLast(pagesize);

    ref
      .get()
      .then((snapshot) => {
        const totalCount = snapshot.size;

        const data = [
          ...persistProducts,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              documentID: doc.id,
            };
          }),
        ];

        resolve({
          data,
          queryDoc: snapshot.docs[totalCount - 1],
          isLastPage: totalCount < 1,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * handleDeleteProduct
 */
export const handleDeleteProduct = (documentID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("products")
      .doc(documentID)
      .delete()
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * handleFetchProduct
 */
export const handleFetchProduct = (productID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("products")
      .doc(productID)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          resolve({
            ...snapshot.data(),
            documentID: productID,
          });
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
