import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { signOutUserStart } from './../redux/User/user.actions';

import VerticalNav from '../components/VerticalNav/VerticalNav';
import Navbar  from '../components/Navbar/Navbar';
import Footer  from '../components/Footer/Footer';
import BodyOverlay from '../components/BodyOverlay/BodyOverlay';
import OffsetWrapper from '../components/OffsetWrapper/OffsetWrapper';

import AddNewProductWrapper from '../components/OffsetWrapper/products/AddNewProductWrapper';
import EditProductWrapper from '../components/OffsetWrapper/products/EditProductWrapper';
import DeleteProductWrapper from '../components/OffsetWrapper/products/DeleteProductWrapper';

import EditCategoryWrapper from '../components/OffsetWrapper/productCategories/EditCategoryWrapper';
import AddNewCategoryWrapper from '../components/OffsetWrapper/productCategories/AddNewCategoryWrapper';

import EditCategoryGroupWrapper from '../components/OffsetWrapper/productGroups/EditGroupWrapper';
import AddNewCategoryGroupWrapper from '../components/OffsetWrapper/productGroups/AddNewGroupWrapper';

import SizeAddModal from '../components/OffsetWrapper/productSizes/SizeAddModal';
import SizeEditModal from '../components/OffsetWrapper/productSizes/SizeEditModal';
import SizeDeleteModal from '../components/OffsetWrapper/productSizes/SizeDeleteModal';

import ColorAddModal from '../components/OffsetWrapper/productColors/ColorAddModal';
import ColorEditModal from '../components/OffsetWrapper/productColors/ColorEditModal';
import ColorDeleteModal from '../components/OffsetWrapper/productColors/ColorDeleteModal';


import '../App.css';

const AdminLayout = props => {
    const dispatch = useDispatch();
  
    const signOut = () => {
      dispatch(signOutUserStart());
    };

    return (
      <React.Fragment>
        <Navbar {...props} />

        <BodyOverlay />

        <OffsetWrapper />

        <AddNewProductWrapper />
        <AddNewCategoryWrapper />
        
        <EditCategoryWrapper />
        <EditProductWrapper />

        <AddNewCategoryGroupWrapper />
        <EditCategoryGroupWrapper />

        <DeleteProductWrapper />

        <SizeAddModal />
        <SizeEditModal />
        <SizeDeleteModal />

        <ColorAddModal />
        <ColorEditModal />
        <ColorDeleteModal />
        

          <div className="main admin-layout">

          <VerticalNav>
            <ul className="admimLeftPanel">
              <li>
                <Link to="/admin">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/admin/specials">
                  Specials
                </Link>
              </li>
              <li>
                <Link to="/admin/categories">
                  Categories
                </Link>
              </li>

              <li>
                <Link to="/admin/category-groups">
                  Groups
                </Link>
              </li>

              <li>
                <Link to="/admin/product-sizes">
                  Sizes
                </Link>
              </li>

              <li>
                <Link to="/admin/product-colors">
                  Colors
                </Link>
              </li>
           
              <li>
                <Link  to="#/signout" className="signOut" onClick={ (e) => { signOut(); e.preventDefault(); }}>
                  Sign Out
                </Link>
              </li>
            </ul>
          </VerticalNav>

            { props.children }
          </div>

          <Footer />

  

      </React.Fragment>
    );
  };
  
  export default AdminLayout;