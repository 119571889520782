import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategoryGroupStart, setCategoryGroup } from '../../redux/CategoryGroups/category.group.actions';

import { Link } from 'react-router-dom';
import { Button }  from 'react-bootstrap';
import './styles.css';

const mapState = ({ categoryGroupsData }) => ({
  groups: categoryGroupsData.groups
});

const AdminCategoryGroup = props => {

  const { groups } = useSelector(mapState);

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(
        fetchCategoryGroupStart()
     );

    }, [dispatch]);

    const handleEditCategoryGroup = (category) => {
      if(!category) return;

      dispatch(
        setCategoryGroup(category)
      );
    }
  
    
    return (    
        <section className="our-checkout-area bg__white">
        <div className="container">
            <div className="row main-table-row">

              <div className="col-md-9">
                <div className="product-style-tab">
                    
                    <div className="product-tab-list">
                        
                        <ul className="tab-style" role="tablist">
                            <li className="active">
                                <Link to="#home1" data-toggle="tab">
                                    <div className="tab-menu-text">
                                        <div className="htc__login__btn">
                                            <li>
                                            <Link data-toggle="modal" data-target="#categoryGroupModal" title="Quick View" className="quick-view modal-view detail-link" to="#/addcatogorygroup">Add Group</Link>
                                            </li>

                                            <li></li>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                            
                        </ul>
                    </div>

                    <div className="tab-content another-product-style jump wishlist-area">
                        <div className="">
                        <div className="row main-table-row">
                            <div className="">

                           <div className="wishlist-table table-responsive">
                            <table>
                                <tbody>


                                  <tr key={"01"} className="table-header">
                                    <td>
                                        Name
                                    </td>
                                    <td>
                                        Position
                                    </td>
                                    <td>
                                        Action
                                    </td>
                                    
                                    </tr>

                                {(Array.isArray(groups) && groups.length > 0) && groups.map((group, idx) => {
                                    const {
                                      title,
                                      position,
                                      ID
                                    } = group;

                                    return (
                                        <tr key={idx}>
                                        <td>
                                            {title}
                                        </td>
                                        <td>
                                            {position}
                                        </td>
                                       
                                        <td>
                                            <Button id={ID} onClick={() => handleEditCategoryGroup(group)}  data-toggle="modal" data-target="#categorytGroupEditModal" title="Quick View" className="quick-view modal-view detail-link" to="#/editcategorygroup">
                                             Edit
                                            </Button>
                                        </td>
                                       
                                        </tr>
                                    )
                                    })}
                                </tbody>
                                </table>

                               
                             </div>
                            </div>

                            </div>
                        </div>
                    
                    </div>

                </div>
            </div>

           
       </div>
       </div>
       </section>
    );
}
    
export default AdminCategoryGroup;