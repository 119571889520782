import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addProductStart } from "../../../redux/Products/products.actions";
import { fetchCategoriesStart } from "../../../redux/Product-categories/categories.actions";
import { fetchColorsStart } from "../../../redux/ProductColors/color.actions";

import { fetchSizesStart } from "../../../redux/ProductSizes/size.actions";

import { default as ReactSelect } from "react-select";
import { components } from "react-select";

import FormInput from "../../Forms/FormInput/index";
import FormInputArea from "../../Forms/FormInputArea/index";
import {
  Button,
  ButtonGroup,
  ToggleButton,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { appEnvConfig } from "../../../yoco/config";

import "../style.css";

const mapState = (state) => ({
  categories: state.categoriesData.categories,
  colors: state.colorsData.colors,
  sizes: state.sizesData.sizes,
});

const AddNewProductWrapper = (props) => {
  const { categories, colors, sizes } = useSelector(mapState);

  const stockOptions = [
    { name: "In Stock", value: "1" },
    { name: "Out Of Stock", value: "2" },
  ];

  const onSaleOptions = [
    { name: "On Sale", value: "1" },
    { name: "Not On Sale", value: "2" },
  ];

  const dispatch = useDispatch();

  const [productName, setProductName] = useState("");
  const [productThumbnail, setProductThumbnail] = useState("");
  const [thumbnailFile, setThumbnailFile] = useState("");
  const [thumbnailActualFile, setThumbnailActualFile] = useState("");
  const [productPrice, setProductPrice] = useState(0);
  const [productDesc, setProductDesc] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [productCategoryGroup, setProductCategoryGroup] = useState("");
  const [productColors, setProductColors] = useState([]);
  const [productSelectedColors, setProductSelectedColors] = useState([]);
  const [productSizes, setProductSizes] = useState([]);
  const [productSelectedSizes, setProductSelectedSizes] = useState([]);
  const [productInStock, setProductInStock] = useState("");
  const [productOnSale, setProductOnSale] = useState("");
  const [productSalePrice, setProductSalePrice] = useState("");

  const { data } = categories;

  useEffect(() => {
    dispatch(fetchCategoriesStart());

    dispatch(fetchColorsStart());

    dispatch(fetchSizesStart());
  }, [dispatch]);

  const resetForm = () => {
    setProductCategory("none");
    setProductCategoryGroup("");
    setProductName("");
    setProductThumbnail("");
    setProductPrice(0);
    setProductDesc("");
    setThumbnailFile("");
    setThumbnailActualFile("");
    setProductColors([]);
    setProductSelectedColors([]);
    setProductSizes([]);
    setProductSelectedSizes([]);
    setProductInStock("");
    setProductOnSale("");
    setProductSalePrice("");
  };

  const handleColorClicked = (e) => {
    let colors = [];
    e.map((color, index) => {
      colors.push(color.value);
    });
    console.log(colors);
    setProductSelectedColors(colors);
    setProductColors(e);

    return;
  };

  const handleSizeClicked = (e) => {
    let sizeArray = [];
    e.map((size, index) => {
      sizeArray.push(size.value);
    });
    console.log(sizeArray);
    setProductSelectedSizes(sizeArray);
    setProductSizes(e);

    return;
  };

  const onFileChange = (e) => {
    let file = e.target.files[0];
    let filename = file.name;
    let fileURL = URL.createObjectURL(e.target.files[0]);

    setProductThumbnail(fileURL);
    setThumbnailFile(filename);
    setThumbnailActualFile(file);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!productCategory || !productCategoryGroup) {
      alert("Please Select Both Category & Group");
      return;
    }

    let saveProduct = {
      productCategory,
      productCategoryGroup,
      productName,
      productThumbnail,
      productPrice,
      productDesc,
      productColors: productSelectedColors,
      productSizes: productSelectedSizes,
      productInStock,
      productOnSale,
      productSalePrice
    };

    // lets save image first
    let axios = require("axios");
    let FormData = require("form-data");

    let data = new FormData();
    data.append("fileDoc", thumbnailActualFile);
    data.append("appid", "AnoRepw01");

    let config = {
      method: "post",
      url: appEnvConfig.uploadServerEndpoint,
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response == null || response.data == null || !response.data.ok) {
          alert(
            "product create failed: image could not be uploaded, pleaset try again"
          );
        }
        let productThumbnail =
          appEnvConfig.fileServerDomain + response.data.linkURL;

        saveProduct.productThumbnail = productThumbnail;
        setProductThumbnail(productThumbnail);

        dispatch(addProductStart(saveProduct));

        resetForm();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const Option = (props) => {
    return (
      <div className="optiondiv">
        <components.Option {...props}>
          <input
            className={props.label}
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label className={props.label}>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  return (
    <div
      className="modal fade"
      id="productAddModal"
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog modal__container" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <div className="modal-product">
              <form onSubmit={handleSubmit}>
                <div className="product-info">
                  <h1>Product Details</h1>

                  <div className="quick-desc">
                    <label>Category (Click to change)</label>
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={
                        !productCategory
                          ? "No Selection"
                          : ` ${productCategory} (${
                              !productCategoryGroup
                                ? `Select group`
                                : ` ${productCategoryGroup}`
                            })`
                      }
                    >
                      {Array.isArray(data) &&
                        data.length > 0 &&
                        data.map((category, index) => {
                          const { categoryName, categoryGroup } = category;
                          return (
                            <Dropdown.Item
                              href={`#/category-${index}`}
                              onSelect={() => {
                                setProductCategory(categoryName.toLowerCase());
                                setProductCategoryGroup(
                                  categoryGroup.toLowerCase()
                                );
                              }}
                            >{`${categoryName} (${categoryGroup})`}</Dropdown.Item>
                          );
                        })}
                    </DropdownButton>
                  </div>

                  <div className="quick-desc">
                    <FormInput
                      label="Product Name"
                      type="text"
                      value={productName}
                      handleChange={(e) => setProductName(e.target.value)}
                    />
                  </div>

                  <div className="quick-desc">
                    {productThumbnail && (
                      <img
                        className="thumb editthumb"
                        src={productThumbnail}
                        alt={thumbnailFile}
                      />
                    )}

                    <FormInput
                      label="File Upload"
                      type="file"
                      handleChange={(e) => {
                        onFileChange(e);
                      }}
                    />
                  </div>

                  <div className="quick-desc">
                    <FormInput
                      label="Price"
                      type="number"
                      min="0.00"
                      max="10000.00"
                      step="0.01"
                      value={productPrice}
                      handleChange={(e) => setProductPrice(e.target.value)}
                    />
                  </div>

                  <div className="quick-desc">
                    <FormInputArea
                      label="Description"
                      sublabel="Please make sure you comma seperate your statements(,)"
                      type="textarea"
                      rows="2"
                      value={productDesc}
                      handleChange={(e) => setProductDesc(e.target.value)}
                    />
                  </div>

                  <div className="quick-desc stockoptions">
                    <label>Product availability</label>
                    <ButtonGroup toggle>
                      {stockOptions.map((radio, idx) => (
                        <ToggleButton
                          key={idx}
                          type="radio"
                          variant="secondary"
                          name="radio"
                          value={radio.value}
                          checked={productInStock === radio.value}
                          onChange={(e) =>
                            setProductInStock(e.currentTarget.value)
                          }
                        >
                          {radio.name}
                        </ToggleButton>
                      ))}
                    </ButtonGroup>
                  </div>

                  <div className="quick-desc stockoptions">
                    <label>Product On Sale</label>
                    <ButtonGroup toggle>
                      {onSaleOptions.map((radio, idx) => (
                        <ToggleButton
                          key={idx}
                          type="radio"
                          variant="secondary"
                          name="sale-radio"
                          value={radio.value}
                          checked={productOnSale === radio.value}
                          onChange={(e) =>
                            setProductOnSale(e.currentTarget.value)
                          }
                        >
                          {radio.name}
                        </ToggleButton>
                      ))}
                    </ButtonGroup>
                  </div>

                  { productOnSale && productOnSale ==="1" && ( 
                        <div className="quick-desc">
                        <FormInput
                            label="Sale Price"
                            type="number"
                            min="0.00"
                            max="10000.00"
                            step="0.01"
                            value={productSalePrice}
                            handleChange={(e) => setProductSalePrice(e.target.value)}
                        />
                        </div>
                        )}


                  <div className="quick-desc">
                    <label>Select colors</label>
                    <div className="select__color">
                      <span
                        className="d-inline-block"
                        data-toggle="popover"
                        data-trigger="focus"
                        data-content="Please select color(s)"
                      >
                        <ReactSelect
                          style={{ width: "400px" }}
                          options={colors ? colors.data : []}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{
                            Option,
                          }}
                          onChange={handleColorClicked}
                          allowSelectAll={true}
                          value={productColors}
                        />
                      </span>
                    </div>
                  </div>

                  <div className="quick-desc">
                    <label>Select size</label>

                    <div className="select__size">
                      <span
                        className="d-inline-block"
                        data-toggle="popover"
                        data-trigger="focus"
                        data-content="Please select size(s)"
                      >
                        <ReactSelect
                          style={{ width: "400px" }}
                          options={sizes ? sizes.data : []}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{
                            Option,
                          }}
                          onChange={handleSizeClicked}
                          allowSelectAll={true}
                          value={productSizes}
                        />
                      </span>
                    </div>
                  </div>

                  <div className="htc__submit__btn">
                    <Button
                      className="btn link"
                      onClick={handleSubmit}
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      Add Product
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewProductWrapper;
