import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategoriesStart, setCategory } from '../../redux/Product-categories/categories.actions';
import { fetchCategoryGroupStart, setCategoryGroup } from '../../redux/CategoryGroups/category.group.actions';

import { Link } from 'react-router-dom';
import { Button }  from 'react-bootstrap';
import './styles.css';

const mapState = ({ categoriesData }) => ({
  categories: categoriesData.categories
});

const AdminCategory = props => {

  const { categories } = useSelector(mapState);

    const dispatch = useDispatch();
    const { data, queryDoc, isLastPage } = categories;
  
    useEffect(() => {
      dispatch(
        fetchCategoriesStart()
      );

      dispatch(fetchCategoryGroupStart());

    }, [dispatch]);

    const handleEditCategory = (category) => {
      if(!category) return;

      dispatch(
        setCategory(category)
      );
    }
  
  
    const handleLoadMore = () => {
      dispatch(
        fetchCategoriesStart({
          startAfterDoc: queryDoc,
          persistProducts: data
        })
      );
    };
  
    const configLoadMore = {
      onLoadMoreEvt: handleLoadMore,
     };

    
    return (    
        <section className="our-checkout-area bg__white">
        <div className="container">
            <div className="row main-table-row">

              <div className="col-md-9">
                <div className="product-style-tab">
                    
                    <div className="product-tab-list">
                        
                        <ul className="tab-style" role="tablist">
                            <li className="active">
                                <Link to="#home1" data-toggle="tab">
                                    <div className="tab-menu-text">
                                        <div className="htc__login__btn">
                                            <li>
                                            <Link data-toggle="modal" data-target="#categoryModal" title="Quick View" className="quick-view modal-view detail-link" to="#/addcatogory">Add Category</Link>
                                            </li>

                                            <li></li>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                            
                        </ul>
                    </div>

                    <div className="tab-content another-product-style jump wishlist-area">
                        <div className="">
                        <div className="row main-table-row">
                            <div className="">

                           <div className="wishlist-table table-responsive">
                            <table>
                                <tbody>


                                <tr key={"01"} className="table-header">
                                    <td>
                                        Name
                                    </td>
                                    <td>
                                        Position
                                    </td>
                                    <td>
                                      Group Name
                                    </td>
                                    <td>
                                        Action
                                    </td>
                                    
                                    </tr>

                                {(Array.isArray(data) && data.length > 0) && data.map((category, index) => {
                                    const {
                                      categoryName,
                                      positionIndex,
                                      categoryGroup,
                                      documentID
                                    } = category;

                                    return (
                                        <tr key={index}>
                                        <td>
                                            {categoryName}
                                        </td>
                                        <td>
                                          { positionIndex }
                                        </td>
                                        <td>
                                            { categoryGroup ? categoryGroup: 'No Group'
                                             
                                            }
                                        </td>
                                        <td>
                                            <Button id={documentID} onClick={() => handleEditCategory(category)}  data-toggle="modal" data-target="#categorytEditModal" title="Quick View" className="quick-view modal-view detail-link" to="#/editcategory">
                                             Edit
                                            </Button>
                                        </td>
                                       
                                        </tr>
                                    )
                                    })}
                                </tbody>
                                </table>

                             
                             </div>
                            </div>

                            </div>
                        </div>
                    
                    </div>

                </div>
            </div>

           
       </div>
       </div>
       </section>
    );
}
    
export default AdminCategory;