
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteColorStart } from '../../../redux/ProductColors/color.actions';
import { Button } from 'react-bootstrap';
import '../style.css';

const mapState = state => ({
    color: state.colorsData.color
});

const ColorDeleteModal = props => {
    
    const dispatch = useDispatch();
    const { color } = useSelector(mapState);
    
    const {
        documentID
    } =  color;


    useEffect(() => {
     },[color]);

    const handleSubmit = e => {
      e.preventDefault();
      dispatch( deleteColorStart(documentID));
    };

   return (             
    <div className="modal fade" id="colorDelModal" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal__container" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>

                <div className="modal-body">
                    <div className="modal-product">
                        
                        <form onSubmit={ handleSubmit }>
                        <div className="product-info-delete">
                            <h1>Remove Color Completely</h1>
                            
                            <div className="quick-desc">
                                <h4>This will remove the color completely on the store and it is un recoveratble, Are you sure you want to delete the color?</h4>
                            </div>

                            <div className="htc__submit__btn">
                            <Button className="btn link" data-dismiss="modal" aria-label="Close">
                                  Cancel
                                </Button>

                                <Button className="btn link" onClick={ handleSubmit } data-dismiss="modal" aria-label="Close">
                                    Delete Product Color
                                </Button>
                            </div>   
                        </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
  )
  
};

export default ColorDeleteModal;
