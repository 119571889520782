import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { emailSignInStart, googleSignInStart } from './../../redux/User/user.actions';
import AuthWrapper from '../AuthWrapper/AuthWrapper';

import { Button }  from 'react-bootstrap';

import './styles.css';

const mapState = ({ user }) => ({
    currentUser: user.currentUser,
    userErr: user.userErr
 });

const SignIn = props => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { currentUser, userErr } = useSelector(mapState);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('')
    const [ errors, setErrors] = useState([]);

    useEffect(() => {
        if (currentUser) {
          resetForm();
          history.push('/');
        }
    
      }, [currentUser, history]);

      useEffect(() => {
    
        setErrors(userErr);
        if (Array.isArray(userErr) && userErr.length > 0) {
            setErrors(userErr);  
        }
       
    }, [userErr]);


    const resetForm = () => {
        setEmail('');
        setPassword('');
        setErrors([]);
    }

    const handleSubmit =  e => {
        
        e.preventDefault();
        dispatch(emailSignInStart({ email, password }));
      
    }

    const handleGoogleSignIn = () => {
        dispatch(googleSignInStart());
    }

    const configAuthWrapper = {
      headline: 'LogIn'
    };
   
    return (
      <AuthWrapper {...configAuthWrapper}>
        <div>
            
            { errors && (
                <ul className="error-list">
                  <li key={1}>{errors}</li>
                </ul>  
            )}
            <form
                onSubmit={ handleSubmit } 
                className="login" 
                method="post">
                <input type="text" 
                name="email" 
                placeholder="Email*" 
                value={ email }  
                onChange={ e => setEmail(e.target.value) } />
                <input type="password" 
                placeholder="Password*" 
                name="password" 
                value={ password }  
                onChange={ e => setPassword(e.target.value)  } />

                <div className="htc__login__btn mt--30">
                    <Button type="submit" className="btn link light" size="lg" >Login</Button>
                </div>
            </form>
            <div className="tabs__checkbox">
                <input type="checkbox" />
                <span> Remember me</span>
                <span className="forget">
                    <Link to="/recovery">Forget Pasword?</Link>
                </span>
            </div>
            
            
            <div className="htc__social__connect">
                <h2>Or Login With</h2>
                <ul className="htc__soaial__list">
                    
                    {/* <li><a className="bg--twitter" href="#"><i className="zmdi zmdi-twitter"></i></a></li>

                    <li><a className="bg--instagram" href="#"><i className="zmdi zmdi-instagram"></i></a></li>

                    <li><a className="bg--facebook" href="#"><i className="zmdi zmdi-facebook"></i></a></li>
                        */}
                    <li>
                        <Link className="bg--googleplus" to="#"
                            onClick={ handleGoogleSignIn }
                        ><i className="zmdi zmdi-google-plus"></i></Link>
                    </li>
                </ul>
            </div>
        </div>
       </AuthWrapper>
    )

}


export default SignIn;