
export const appEnvConfig = {
    publicKey : process.env.REACT_APP_YOCO_PUB_KEY,
    serverEndpoint: process.env.REACT_APP_YOCO_CHARGES_ENDPOINT,

    serverMiniSlides: process.env.REACT_APP_MINI_SLIDES_ENDPOINT,
    serverCategoryGroups: process.env.REACT_APP_CATEGORY_GROUPS_ENDPOINT,
 
    applicationId: process.env.REACT_APP_ID,
    uploadServerEndpoint: process.env.REACT_APP_FILE_UPLOAD_ENDPOINT,
    fileServerDomain: process.env.REACT_APP_FILE_SERVER_DOMAIN
}