import { auth } from '../../firebase/util';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import { setSlides, setSlide, fetchSlidesStart  } from './slides.actions';
import { handleAddSlide, handleFetchSlides,
  handleFetchSlide, handleDeleteSlide } from './slides.helpers';
import slidesTypes from './slides.types';

/**
 * addSlide
 */
export function* addSlide({ payload }) {

  try {
    const timestamp = new Date();
    yield handleAddSlide({
      ...payload,
      slideAdminUserUID: auth.currentUser.uid,
      createdDate: timestamp
    });
    yield put(
      fetchSlidesStart()
    );


  } catch (err) {
  }

}

export function* onAddSlideStart() {
  yield takeLatest(slidesTypes.ADD_NEW_SLIDE_START, addSlide);
}


/**
 * fetchSlides
 */
export function* fetchSlides({ payload }) {
  try {
    const slides = yield handleFetchSlides(payload);
    yield put(
      setSlides(slides)
    );

  } catch (err) {
  }
}

export function* onFetchSlidesStart() {
  yield takeLatest(slidesTypes.FETCH_SLIDES_START, fetchSlides);
}


/**
 * deleteSlide
 */
export function* deleteSlide({ payload }) {
  try {
    yield handleDeleteSlide(payload);
    yield put (
      fetchSlidesStart()
    );

  } catch (err) {
  }
}

export function* onDeleteSlideStart() {
  yield takeLatest(slidesTypes.DELETE_SLIDE_START, deleteSlide);
}


/**
 * fetchSlide
 */
export function* fetchSlide({ payload }) {
  try {
    const slide = yield handleFetchSlide(payload);
    yield put(
      setSlide(slide)
    );

  } catch (err) {
  }
}

export function* onFetchSlideStart() {
  yield takeLatest(slidesTypes.FETCH_SLIDE_START, fetchSlide);
}


/**
 * slides
 */
export default function* slidesSagas() {
  yield all([
    call(onAddSlideStart),
    call(onFetchSlidesStart),
    call(onDeleteSlideStart),
    call(onFetchSlideStart),
  ])
}