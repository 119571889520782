import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetPasswordStart, resetUserState } from './../../redux/User/user.actions';
import AuthWrapper from '../AuthWrapper/AuthWrapper';
import { Button } from 'react-bootstrap';
import './styles.css';

const mapState = ({ user }) => ({
    resetPasswordSuccess: user.resetPasswordSuccess,
    userErr: user.userErr
});

const ResetPassword = props => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { resetPasswordSuccess, userErr } = useSelector(mapState);
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (resetPasswordSuccess) {
          dispatch(resetUserState());
          history.push('/login');
        }
    
      }, [resetPasswordSuccess, history, dispatch]);

    useEffect(() => {
        if (Array.isArray(userErr) && userErr.length > 0) {
          setErrors(userErr);
        }
    
      }, [userErr]);

      const handleSubmit = e => {
        e.preventDefault();
        dispatch(resetPasswordStart({ email }));
      }

      const configAuthWrapper = {
        headline: 'Email Password'
      };

 
    return (
        <AuthWrapper {...configAuthWrapper}>
        <div>
            {errors.length > 0 && (
                <ul className="error-list">
                    {errors.map( (err, index) => {
                        return (
                            <li key={index}>{err}</li>
                            )
                    } )}
                </ul>  
            )}

            <form 
                onSubmit= { handleSubmit }
                className="login" 
                method="post">
                
                <input type="email" name="email" placeholder="Email*" value={ email } onChange={ e => setEmail(e.target.value)} />
                
                <div className="htc__login__btn">
                    <Button type="submit" className="btn link light" size="lg" >Email Password</Button>
                </div>
            </form>

            
        </div>
     </AuthWrapper>
    )
}

export default ResetPassword;
