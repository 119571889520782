import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { removeCartItem } from '../../../redux/Cart/cart.actions';

const Item = (product) => {
  const dispatch = useDispatch();
  const {
    productName,
    productThumbnail,
    productPrice,
    quantity,
    OnSpecial,
    special,
    documentID
  } = product;

  const handleRemoveCartItem = ( e, documentID) => {
    e.preventDefault();
    dispatch(
      removeCartItem({
        documentID
      })
    );
  }


  return (

    <div className="shp__single__product">
        <div className="shp__pro__thumb">
            <a href={ documentID }>
                <img src={ productThumbnail } alt={ productName } />
            </a>
        </div>
        <div className="shp__pro__details">
            <h2><Link to={documentID } >{ productName }</Link></h2>
           
            { (OnSpecial === true) && ( <span className="quantity" style={{ fontStyle:'italic', color: 'green' }}>{ special.description }<br/></span> )}
            <span className="quantity">QTY: { quantity }</span>
            <span className="shp__price">{ productPrice === 0 ? 'Combo Item' : 'R ' + productPrice }</span>
        </div>
        <div className="remove__btn">
            <a href={ documentID } title="Remove this item" onClick={(e) => handleRemoveCartItem( e, documentID )} ><i className="zmdi zmdi-close"></i></a>
        </div>
    </div>


  );
}

export default Item;
