
import React, {  useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  editCategoryStart } from '../../../redux/Product-categories/categories.actions';


import FormInput from '../../Forms/FormInput/index';
import FormInputArea from '../../Forms/FormInputArea/index';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { appEnvConfig } from '../../../yoco/config';


import '../style.css';

const mapState = state => ({
    category: state.categoriesData.category,
    groups: state.categoryGroupsData.groups
});

const EditCategoryWrapper = props => {
    
 
    const dispatch = useDispatch();
   
    const [categoryName, setCategoryName] = useState('');
    const [positionIndex , setPositionIndex] = useState('');
    const [categoryThumbnail, setCategoryThumbnail] = useState('');
    const [categoryDesc, setCategoryDesc] = useState('');
    const [categoryGroup, setCategoryGroup] = useState('');

    const [thumbnailFile, setThumbnailFile] = useState('');
    const [thumbnailActualFile, setThumbnailActualFile] = useState('');
    
    const [ message, setMessage ] = useState('');
    
    const { category } = useSelector(mapState);
    const { groups } = useSelector(mapState);
    
    const { 
        data 
    } = groups;

    const {
        documentID
    } =  category;

  
    useEffect(() => {
      
       if(category)
            initialSet(category);
    },[category]);

    const initialSet = ( category) => {
       
        setCategoryName(category.categoryName);
        setPositionIndex(category.positionIndex);
        setCategoryDesc(category.categoryDesc);
        setCategoryThumbnail(category.categoryThumbnail);
        setCategoryGroup(category.categoryGroup);

        setThumbnailFile('');
        setThumbnailActualFile('');
    };

    const resetForm = () => {
      setCategoryName('');
      setPositionIndex('');
      setCategoryThumbnail('');
      setCategoryDesc('');
      setCategoryGroup('');
    };

    const handleCategoryClick = (e) => {

        console.log(e);
    }

     const onFileChange = e => {

        let file = e.target.files[0];
        let filename = file.name;
        let fileURL = URL.createObjectURL(e.target.files[0]);
    
        setCategoryThumbnail(fileURL);
        setThumbnailFile(filename);
        setThumbnailActualFile(file);
     }
  
    const handleSubmit = e => {
      e.preventDefault();

      let modifiedCategory ={
        documentID,
        positionIndex,
        categoryName,
        categoryThumbnail,
        categoryDesc,
        categoryGroup
      }
      

      if(thumbnailActualFile === '') {
        console.log(modifiedCategory);
        dispatch( editCategoryStart(
            modifiedCategory
        ));
        resetForm();
        return;
      }


      if(thumbnailActualFile !== '') {
       
        let axios = require('axios');
        let FormData = require('form-data');
    
        let data = new FormData();
        data.append('fileDoc', thumbnailActualFile);
        data.append('appid', 'AnoRepw01');

        let config = {
        method: 'post',
        url: appEnvConfig.uploadServerEndpoint ,
        data : data
        };
        axios(config).then(function (response) {

            if (response == null || response.data == null || !response.data.ok) {
                alert("product create failed: image could not be uploaded, pleaset try again");
            }
            let categoryThumbnail = appEnvConfig.fileServerDomain + response.data.linkURL;
          
            setCategoryThumbnail(categoryThumbnail);
            modifiedCategory.categoryThumbnail = categoryThumbnail;

               dispatch(
                editCategoryStart(modifiedCategory)
              );
            resetForm();
        })
        .catch(function (error) {
             console.log(error);
        });
      }

    };

  
   return (             
    <div className="modal fade" id="categorytEditModal" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal__container" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>

                <div className="modal-body">
                    <div className="modal-product">
                        
                        <form onSubmit={ handleSubmit }>
                        <div className="product-info">
                        <h1>Category Details Update</h1>


                         <div className="quick-desc">
                        
                            <label>Category Group (Click to change)</label>
                            <DropdownButton id="dropdown-basic-button" title={ categoryGroup } >
                                {(Array.isArray(groups) && groups.length > 0) && groups.map((group, index) => {
                                                
                                    const {
                                        title
                                    } = group;
                                        return (
                                        <Dropdown.Item href={`#/action-${index}`} onSelect={() => setCategoryGroup(title.toLowerCase()) }>{ title }</Dropdown.Item>
                                    )
                                })}

                            </DropdownButton>
                          </div>

                          
                            <div className="quick-desc">
                                <FormInput
                                label="Name"
                                type="text"
                                value={categoryName}
                                handleChange={e => setCategoryName(e.target.value)}
                                /> 
                            </div>

                            <div className="quick-desc">
                                <FormInput
                                label="Position"
                                type="number"
                                value={positionIndex}
                                handleChange={e => setPositionIndex(e.target.value)}
                                /> 
                            </div>

                            <div className="quick-desc">

                                { (categoryThumbnail && categoryThumbnail !== 'none') &&
                                    <img className="thumb editthumb" src={categoryThumbnail } alt={ categoryName } />
                                }
                                <FormInput
                                    label="Main image URL"
                                    type="url"
                                    value={categoryThumbnail}
                                    handleChange={e => setCategoryThumbnail(e.target.value)}
                                />
                            </div>

                            <div className="quick-desc">
                                <FormInputArea
                                label="Description"
                                type="textarea"
                                rows="2"
                                value={categoryDesc}
                                handleChange={e => setCategoryDesc(e.target.value)}
                                />
                            </div>
                          
                            <div className="htc__submit__btn">
                                <Button className="btn link" onClick={ handleSubmit } data-dismiss="modal" aria-label="Close">
                                    Update Category
                                </Button>
                            </div>
  
                        </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>   

 )

  
};

export default EditCategoryWrapper;
