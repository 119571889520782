import { firestore } from '../../firebase/util';

export const handleAddSize = size => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('product-sizes')
      .doc()
      .set(size)
      .then(() => {
        resolve();
      })
      .catch(err => {
        reject(err);
      })
  });
}

export const handleFetchSizes = ({ filterType, startAfterDoc, persistSizes=[] }) => {
  return new Promise((resolve, reject) => {

    let ref = firestore.collection('product-sizes').orderBy('value');

    if (startAfterDoc) ref = ref.startAfter(startAfterDoc);

    ref
      .get()
      .then(snapshot => {
        const totalCount = snapshot.size;

        const data = [
          ...persistSizes,
          ...snapshot.docs.map(doc => {
            return {
              ...doc.data(),
              documentID: doc.id
            }
          })
        ];

        resolve({
          data,
          queryDoc: snapshot.docs[totalCount - 1],
          isLastPage: totalCount < 1
        });
      })
      .catch(err => {
        reject(err);
      })
  })
}

export const handleDeleteSize = documentID => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('product-sizes')
      .doc(documentID)
      .delete()
      .then(() => {
        resolve();
      })
      .catch(err => {
        reject(err);
      })
  });
}

export const handleFetchSize = (sizeID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('product-sizes')
      .doc(sizeID)
      .get()
      .then(snapshot => {

        if (snapshot.exists) {
          resolve({
            ...snapshot.data(),
            documentID: sizeID
          });
        }
      })
      .catch(err => {
        reject(err);
      })
  })
}


/**
 * handleEditSize
 */
 export const handleEditSize = size => {

  return new Promise((resolve, reject) => {
    firestore
      .collection('product-sizes')
      .doc(size.documentID)
      .update (size)
      .then(() => {
        resolve();
      })
      .catch(err => {
        console.log(err);
        reject(err);
      })
  });
}