import React, { Component } from 'react'
import { SiteConsumer } from '../../context';

export default class BodyOverlay extends Component {
    render() {
        return (
            <SiteConsumer>
                {( param ) => {
                    const {
                        cartOpen,
                        settingsOpen,
                        fitlerOpen
                    } = param;
                   
                    return(
                        <div className={`body__overlay ${cartOpen || settingsOpen || fitlerOpen ? "is-visible" : ""} `}></div>
                    )

                }}
           
            </SiteConsumer>

            
        )
    }
}
