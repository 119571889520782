import { auth } from '../../firebase/util';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import { setCategories, setGroupedCategories ,setCategory, fetchCategoriesStart } from './categories.actions';
import { fetchCategoryGroupStart } from '../CategoryGroups/category.group.actions';

import { 
  handleAddCategory, 
  handleFetchCategories,
  handleFetchCategory, 
  handleDeleteCategory,
  handleEditCategory 
} from './categories.helpers';
import CategoriesTypes from './categories.types';

export function* addCategory({ payload }) {

  try {
    const timestamp = new Date();
    yield handleAddCategory({
      ...payload,
      CategoryAdminUserUID: auth.currentUser.uid,
      createdDate: timestamp
    });
    yield put(
      fetchCategoriesStart()
    );


  } catch (err) {
    // console.log(err);
  }

}

export function* onAddCategoryStart() {
  yield takeLatest(CategoriesTypes.ADD_NEW_CATEGORY_START, addCategory);
}

export function* fetchCategories({ payload }) {
  try {


    const Categories = yield handleFetchCategories(payload);
    
   
     Categories.data.sort((a, b) => a.positionIndex-b.positionIndex);


    yield put(
      setCategories(Categories)
    );
      
    //sort out the goruped array
    const groupedArrayValue = Categories.data.reduce((categorySoFar, { categoryGroup,categoryName  }) => {
        
      if (!categorySoFar[categoryGroup]) categorySoFar[categoryGroup] = [];
            categorySoFar[categoryGroup].push(categoryName);
        return categorySoFar;
      }, {});


     
      let categoriesGrouped = [];
      for (const groupedKey in groupedArrayValue) {

       categoriesGrouped.push(
         {
          "key":groupedKey,
          "value": groupedArrayValue[groupedKey]
         }
       );
     }


      yield put(
        setGroupedCategories(categoriesGrouped)
      )

  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchCategoriesStart() {
  yield takeLatest(CategoriesTypes.FETCH_CATEGORIES_START, fetchCategories);
}

export function* deleteCategory({ payload }) {
  try {
    yield handleDeleteCategory(payload);
    yield put (
      fetchCategoriesStart()
    );

  } catch (err) {
    // console.log(err);
  }
}

export function* onDeleteCategoryStart() {
  yield takeLatest(CategoriesTypes.DELETE_CATEGORY_START, deleteCategory);
}

export function* fetchCategory({ payload }) {
  try {
    const Category = yield handleFetchCategory(payload);
    yield put(
      setCategory(Category)
    );

  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchCategoryStart() {
  yield takeLatest(CategoriesTypes.FETCH_CATEGORY_START, fetchCategory);
}


//----
/**
 * editCategory
 */
 export function* editCategory({ payload }) {

  try {
    const timestamp = new Date();
    yield handleEditCategory({
      ...payload,
      modifiedByAdminUserUID: auth.currentUser.uid,
      midifiedDate: timestamp
    });
    yield put(
      fetchCategoriesStart()
    );

  } catch (err) {
  }
}

export function* onEditCategorytStart() {
  yield takeLatest(CategoriesTypes.EDIT_CATEGORY_START, editCategory);
}

export default function* CategoriesSagas() {
  yield all([
    call(onAddCategoryStart),
    call(onFetchCategoriesStart),
    call(onDeleteCategoryStart),
    call(onFetchCategoryStart),
    call(onEditCategorytStart),

  ])
}