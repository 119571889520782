import slidesTypes from './slides.types';

export const addSlideStart = slideData => ({
  type: slidesTypes.ADD_NEW_SLIDE_START,
  payload: slideData
});

export const fetchSlidesStart = (filters={}) => ({
  type: slidesTypes.FETCH_SLIDES_START,
  payload: filters
});

export const setSlides = slides => ({
  type: slidesTypes.SET_SLIDES,
  payload: slides
});

export const deleteSlideStart = slideID => ({
  type: slidesTypes.DELETE_SLIDE_START,
  payload: slideID
});

export const fetchSlideStart = slideID => ({
  type: slidesTypes.FETCH_SLIDE_START,
  payload: slideID
});

export const setSlide = slide => ({
  type: slidesTypes.SET_SLIDE,
  payload: slide
});