import categoryGroupTypes from './category.group.types';

const INITIAL_STATE = {
  groups: [],
  group: {},
  loading: false,
  error: null
};

const CategoryGroupsReducer = (state = INITIAL_STATE,  action ) => {
  switch(action.type) {
    case categoryGroupTypes.FETCH_CATEGORY_GROUPS_START:
      return {
        ...state,
        loading: true,
        error: null
      }
    case categoryGroupTypes.FETCH_CATEGORY_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.payload,
        loading: false
      }
      case categoryGroupTypes.FETCH_CATEGORY_GROUPS_FAILURES:
      return {
        ...state,
        loading: false,
        error: action.error
      }

      case categoryGroupTypes.SET_CATEGORY_GROUPS:
        return {
          ...state,
          groups: action.payload
        }
      case categoryGroupTypes.SET_CATEGORY_GROUP:
        return {
          ...state,
          group: action.payload
        }

    default:
      return state;
  }

};

export default CategoryGroupsReducer;