import slidesTypes from './home.slides.types';

const INITIAL_STATE = {
  slides: [],
  loading: false,
  error: null
};

const RandomSlidesReducer = (state = INITIAL_STATE,  action ) => {
  switch(action.type) {
    case slidesTypes.FETCH_SLIDES_START:
      return {
        ...state,
        loading: true,
        error: null
      }
    case slidesTypes.FETCH_SLIDES_SUCCESS:
      return {
        ...state,
        slides: action.payload,
        loading: false
      }
      case slidesTypes.FETCH_SLIDES_FAILURES:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    default:
      return state;
  }

};

export default RandomSlidesReducer;