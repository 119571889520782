import productsTypes from "./products.types";

export const addProductStart = (productData) => ({
  type: productsTypes.ADD_NEW_PRODUCT_START,
  payload: productData,
});

export const fetchProductsStart = (filters = {}, categories) => ({
  type: productsTypes.FETCH_PRODUCTS_START,
  payload: { filters, categories },
});

export const fetchSearchStart = (keywords = {}) => ({
  type: productsTypes.FETCH_KEYWORDS_START,
  payload: keywords,
});

//fetchHomeTopProducts
export const fetchHomeProductStart = (filters) => ({
  type: productsTypes.FETCH_HOME_PRODUCTS_START,
  payload: filters,
});

export const fetchProductsOnSpecialStart = ( filters = {}, categories) =>({
  type: productsTypes.FETCH_PRODUCTS_ON_SPECIAL_START,
  payload: filters,
});

export const setProducts = (products) => ({
  type: productsTypes.SET_PRODUCTS,
  payload: products,
});

export const setHomeTopProducts = (products) => ({
  type: productsTypes.SET_TOP_PRODUCTS,
  payload: products,
});

export const setHomeBottomProducts = (products) => ({
  type: productsTypes.SET_BOTTOM_PRODUCTS,
  payload: products,
});

export const setProductSpecials = (products) => ({
  type: productsTypes.SET_PRODUCT_SPECIALS,
  payload: products,
});

export const setCategorizedProducts = (products) => ({
  type: productsTypes.SET_CATEGORIZED_PRODUCTS,
  payload: products,
});

export const setSearchResults = (products) => ({
  type: productsTypes.SET_SEARCH_RESULTS,
  payload: products,
});

export const setLatestProductsInStock = (products) => ({
  type: productsTypes.SET_LATEST_PRODUCTS,
  payload: products,
});

export const deleteProductStart = (productID) => ({
  type: productsTypes.DELETE_PRODUCT_START,
  payload: productID,
});

export const editProductStart = (productData) => ({
  type: productsTypes.EDIT_PRODUCT_START,
  payload: productData,
});

export const fetchProductStart = (productID) => ({
  type: productsTypes.FETCH_PRODUCT_START,
  payload: productID,
});

export const setProduct = (product) => ({
  type: productsTypes.SET_PRODUCT,
  payload: product,
});

export const setFilterResult = (flag) => ({
  type: productsTypes.SET_FILTER_RESULTS,
  payload: flag,
});

export const setEditProduct = (product) => ({
  type: productsTypes.SET_EDIT_PRODUCT,
  payload: product,
});
