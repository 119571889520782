import React, { useEffect } from 'react';
import { SiteConsumer } from '../../context';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategoriesStart } from '../../redux/Product-categories/categories.actions';
import MailchimpSubscribe from "react-mailchimp-subscribe"
import  './footer.css';

const mapState = ({ categoriesData }) => ({
    categories: categoriesData.categories,
    groupedCategories: categoriesData.groupedCategories
 });


const Footer = () => {

    const { categories } = useSelector(mapState);
    const { groupedCategories } = useSelector(mapState);

    const dispatch = useDispatch();
    const { data } = categories;
    const mailChimpURL = 'https://anointedrepublic.us6.list-manage.com/subscribe/post?u=a3cff99d057fe020cef5d4cea&amp;id=4d3f63ecfb';

    const SimpleForm = () => <MailchimpSubscribe url={mailChimpURL}/>


    useEffect(() => {
        dispatch(
          fetchCategoriesStart()
        );

        const height = document.getElementById('appFooter').clientHeight;
        document.getElementById("root").style.marginBottom = height +"px";

      }, [ dispatch ]);
    
    // componentDidMount() {
    //     const height = document.getElementById('appFooter').clientHeight;
    //     document.getElementById("root").style.marginBottom = height +"px";
    // }
   
    return (
        <SiteConsumer>
            {( param ) => {
                // const {} = param;

                return(
                    <footer id="appFooter" className="htc__foooter__area gray-bg">
                    <div className="container">
                        <div className="row">
                            <div className="footer__container clearfix">
                                
                                <div className="col-md-3 col-lg-3 col-sm-6">
                                    <div className="ft__widget">
                                        <div className="ft__logo">
                                        <h2 className="ft__title">Contact Us</h2>

                                        </div>
                                        <div className="footer-address">
                                            <ul>
                                                <li>
                                                    <div className="address-icon">
                                                        <i className="zmdi zmdi-pin"></i>
                                                    </div>
                                                    <div className="address-text">
                                                        <p> Johanessburg, South Africa</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="address-icon">
                                                        <i className="zmdi zmdi-email"></i>
                                                    </div>
                                                    <div className="address-text">
                                                        <Link to="mailto:info@anointedrepublic.co.za"> info@anointedrepublic.co.za</Link>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="address-icon">
                                                        <i className="zmdi zmdi-phone-in-talk"></i>
                                                    </div>
                                                    <div className="address-text">
                                                        <p> +27 (63) 448-1630</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <ul className="social__icon">
                                            
                                            <li><Link to={{ pathname: "https://www.instagram.com/anointed_republic" }} target="_blank"><i className="zmdi zmdi-instagram"></i></Link></li>
                                            <li><Link to={{ pathname: "https://www.facebook.com/anointedrepublic" }} target="_blank"><i className="zmdi zmdi-facebook"></i></Link></li>
                                
                                        </ul>
                                    </div>
                                </div>
                                
                                <div className="col-md-3 col-lg-2 col-sm-6 smt-30 xmt-30">
                                    <div className="ft__widget">
                                        <h2 className="ft__title">Categories</h2>
                                        <ul className="footer-categories">
                                        {(Array.isArray(groupedCategories) && groupedCategories.length > 0) && groupedCategories.map((group, mainIndex) => {
                                            const {
                                                key,
                                                value
                                            } = group;
                                            return (
                                                <li key={mainIndex} value={ key }>
                                                    <Link to={`/shop/${value.join(",").toLowerCase()}`} className="main" title={ key }>{ (key ==='undefined' ? 'None': key ) }</Link>
                                                    
                                                </li>
                                                )
                                            })}
                                            
                                        </ul>
                                    </div>
                                </div>
                                
                                <div className="col-md-3 col-lg-2 col-sm-6 smt-30 xmt-30">
                                    <div className="ft__widget">
                                        <h2 className="ft__title">Infomation</h2>
                                        <ul className="footer-categories">
                                            <li><Link to="/about-us">About Us</Link></li>
                                            <li><Link to="/contact-us">Contact Us</Link></li>
                                            <li><Link to="/policy/delivery">Terms & Conditions</Link></li>
                                            <li><Link to="/policy/returns">Returns & Exchanges</Link></li>
                                            <li><Link to="/policy/delivery">Shipping & Delivery</Link></li>
                                            <li><Link to="/policy/privacy">Privacy Policy</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                
                                <div className="col-md-3 col-lg-3 col-lg-offset-1 col-sm-6 smt-30 xmt-30">
                                    <div className="ft__widget">
                                        <h2 className="ft__title">Newsletter</h2>
                                        <div className="newsletter__form">
                                            <p>Subscribe to our newsletter and get some special deals sent directly to you.</p>
                                            <div className="input__box">
                                                <div id="mc_embed_signup">

            
                                                    <div id="mc_embed_signup">
                                                        <SimpleForm />
                                                    </div> 

                                                </div>
                                            </div>        
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        
                        <div className="htc__copyright__area">
                            <div className="row">
                                <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                    <div className="copyright__inner">
                                        <div className="copyright">
                                            <p>© 2021 <Link to="/">Anointed Republic.  </Link>
                                            All Right Reserved.</p>
                                        </div>
                                        <ul className="footer__menu">
                                            <li><Link to="/">Home</Link></li>
                                            <li><Link to="/shop">Shop</Link></li>
                                            <li><Link to="/contact-us">Contact Us</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </footer>
                )

            }}
        
        </SiteConsumer>

    )

}


export default Footer;