import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserOrderHistory } from '../redux/Orders/orders.actions';
import OrderHistory from '../components/OrderHistory/index';
import './Page.css';

const mapState = ({ user, ordersData }) => ({
    currentUser: user.currentUser,
    orderHistory: ordersData.orderHistory.data
  });

  
  
const Dashboard = props => {

    const dispatch = useDispatch();
    const { currentUser, orderHistory } = useSelector(mapState);

    useEffect(() => {
        dispatch(
          getUserOrderHistory(currentUser.id)
        );
      }, []);

    return (
        <h1>You logged in</h1>
    );
};

export default Dashboard