import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategoriesStart } from "../../../redux/Product-categories/categories.actions";
import { fetchCategoryGroupStart } from "../../../redux/CategoryGroups/category.group.actions";

const mapState = ({ categoriesData, categoryGroupsData }) => ({
  groups: categoryGroupsData.groups,
  categories: categoriesData.categories,
  groupedCategories: categoriesData.groupedCategories,
});

const ShopCategories = () => {
  const { groups } = useSelector(mapState);
  const { categories } = useSelector(mapState);
  const { groupedCategories } = useSelector(mapState);

  const dispatch = useDispatch();
  const history = useHistory();
  const { data } = categories;

  const handleClickFilter = (nextFilter) => {
    const filterValue = nextFilter.toLowerCase();

    history.push(`/shop/${filterValue}`);
  };

  useEffect(() => {
    dispatch(fetchCategoryGroupStart());

    dispatch(fetchCategoriesStart());
  }, [dispatch]);

  return (
    <div className="row shop_filter">
      <div className="col-md-12">
        <div className="filter__menu__container">
          <div className="product__menu">
            <button key="0" value="" onClick={() => handleClickFilter("")}>
              Show All
            </button>

            {Array.isArray(groupedCategories) &&
              groupedCategories.length > 0 &&
              groupedCategories.map((group, mainIndex) => {
                const { key, value } = group;
                return (
                  <button
                    key={key}
                    value={key === "undefined" ? "None" : key}
                    onClick={() =>
                      handleClickFilter(value.join(",").toLowerCase())
                    }
                  >
                    {key === "undefined" ? "None" : key}
                  </button>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopCategories;
