import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addSlideStart, fetchSlidesStart } from '../../redux/slides/slides.actions';

import { Link } from 'react-router-dom';
import LoadMore from '../../components/LoadMore/index';
import { Button }  from 'react-bootstrap';
import './styles.css';

const mapState = (state ) => ({
  

    slides: state.slides
    
});

const AdminSlide = props => {


    const { slides } = useSelector(mapState);
    const dispatch = useDispatch();
    
    
   

    const [ hideModal, setHideModal] = useState(true);
    const [ slideName, setSlideName] = useState('');
    const [ slideThumbnail, setSlideThumbnail] = useState('');
    
  console.log(slides);

    useEffect(() => {
      dispatch(fetchSlidesStart());
     
    }, [dispatch]);
  
    const toggleModal = () => setHideModal(!hideModal);
  
    const configModal = {
      hideModal,
      toggleModal
    };
  
    const resetForm = () => {
      setHideModal(true);
      setSlideName('');
      setSlideThumbnail('');
    };
  
    const handleSubmit = e => {
      e.preventDefault();
  
      dispatch(
        addSlideStart({
          slideName,
          slideThumbnail
        })
      );
      resetForm();
  
    };
  
    const handleLoadMore = () => {
      dispatch( fetchSlidesStart()  );
    };
  
    const configLoadMore = {
      onLoadMoreEvt: handleLoadMore,
    };

    
    return (    
        <section className="our-checkout-area bg__white">
        <div className="container">
            <div className="row main-table-row">

              <div className="col-md-9">
                <div className="product-style-tab">
                    
                    <div className="product-tab-list">
                        
                        <ul className="tab-style" role="tablist">
                            <li className="active">
                                
                                    <div className="tab-menu-text">
                                        <div className="htc__login__btn">
                                            <li>
                                            <Link data-toggle="modal" data-target="#productModal" title="Quick View" className="quick-view modal-view detail-link" to="#/addproduct">Add Slide</Link>
                                          
                                            </li>

                                            <li></li>
                                        </div>
                                    </div>
                               
                            </li>
                            
                        </ul>
                    </div>

                    <div className="tab-content another-product-style jump wishlist-area">
                        <div className="">
                        <div className="row main-table-row">
                            <div className="">

                           <div className="wishlist-table table-responsive">
                            <table>
                                <tbody>
                                {(Array.isArray(slides) && slides.length > 0) && slides.map((slide, index) => {
                                    const {
                                        title,
                                        full_url,
                        
                                        documentID
                                    } = slide;

                                    return (
                                        <tr key={index}>
                                        <td>
                                            <img className="thumb" src={ slideThumbnail } />
                                        </td>
                                        <td>
                                            {slideName}
                                        </td>
                                        <td>
                                          
                                        </td>
                                        <td>
                                          <span>edit</span>
                                        </td>
                                        </tr>
                                    )
                                    })}
                                </tbody>
                                </table>

                                <table border="0" cellPadding="10" cellSpacing="0">
                                    <tbody>
                                        <tr>
                                        <td>
                                           
                                        </td>
                                        </tr>
                                    </tbody>
                                </table>
                             </div>
                            </div>

                            </div>
                        </div>
                    
                    </div>

                </div>
            </div>

           
       </div>
       </div>
       </section>
    );
}
    
export default AdminSlide;