
import { all, call } from 'redux-saga/effects';

import userSagas from './User/user.sagas';
import productsSagas from './Products/products.sagas';

//import appAuthSagas from './AppAuth/app.auth.sagas';
import categoriesSagas from './Product-categories/categories.sagas';
import shirtsSagas from './Shirts/shirts.sagas';
import ordersSagas from './Orders/orders.sagas';
import articlesSagas from './articles/articles.sagas';
import slidesSagas from './slides/slides.sagas';
import sizeSagas from './ProductSizes/size.sagas';
import colorSagas from './ProductColors/color.sagas';

export default function* rootSaga() {
  yield all([
    call(userSagas),
    call(categoriesSagas),
    call(shirtsSagas),
    call(productsSagas),
    call(ordersSagas),
    call(articlesSagas),
    call(slidesSagas),
    call(sizeSagas),
    call(colorSagas)
  ])
}