import { auth } from '../../firebase/util';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import { setColors ,setColor, fetchColorStart } from './color.actions';
import { 
  handleAddColor, 
  handleFetchColors,
  handleFetchColor, 
  handleDeleteColor,
  handleEditColor 
} from './color.helpers';
import colorTypes from './color.types';

export function* addColor({ payload }) {

  try {
    const timestamp = new Date();
    yield handleAddColor({
      ...payload,
      ProductColorAdminUserUID: auth.currentUser.uid,
      createdDate: timestamp
    });
    yield put(
      fetchColorStart()
    );

  } catch (err) {
    // console.log(err);
  }

}

export function* onAddColorStart() {
  yield takeLatest(colorTypes.ADD_NEW_COLOR_START, addColor);
}

export function* fetchColors({ payload }) {
  try {
    const groups = yield handleFetchColors(payload);
    yield put(setColors(groups));
    
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchColorsStart() {
  yield takeLatest(colorTypes.FETCH_COLORS_START, fetchColors);
}

export function* deleteColor({ payload }) {
  try {
    yield handleDeleteColor(payload);
    yield put (
      fetchColorStart()
    );

  } catch (err) {
    // console.log(err);
  }
}

export function* onDeleteColorStart() {
  yield takeLatest(colorTypes.DELETE_COLOR_START, deleteColor);
}

export function* fetchColor({ payload }) {
  try {
    const group = yield handleFetchColor(payload);
    yield put(
      setColor(group)
    );

  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchColorStart() {
  yield takeLatest(colorTypes.FETCH_COLOR_START, fetchColor);
}


//----
/**
 * editCategory
 */
 export function* editColor({ payload }) {

  try {
    const timestamp = new Date();
    yield handleEditColor ({
      ...payload,
      modifiedByAdminUserUID: auth.currentUser.uid,
      midifiedDate: timestamp
    });
    yield put(
      fetchColorStart()
    );

  } catch (err) {
  }
}

export function* onEditColorStart() {
  yield takeLatest(colorTypes.EDIT_COLOR_START, editColor);
}

export default function* ColorSagas() {
  yield all([
    call(onAddColorStart),
    call(onFetchColorsStart),
    call(onDeleteColorStart),
    call(onFetchColorStart),
    call(onEditColorStart),

  ])
}