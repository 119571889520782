import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSizesStart, setSize } from '../../redux/ProductSizes/size.actions';

import { Link } from 'react-router-dom';
import LoadMore from '../../components/LoadMore/index';

//import AddRoundedIcon from '@material-ui/icons/AddRounded';
import Table from 'react-bootstrap/Table'

const mapState = ({ sizesData }) => ({
    sizes: sizesData.sizes
});

const AdminProductSizes = props => {

  
  const dispatch = useDispatch();
  const { sizes } = useSelector(mapState);
  const { data, queryDoc, isLastPage } = sizes;


  const [ currentDocumentID, setCurrentDocumentID] = useState('');
  const [ showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
 
    useEffect(() => {
      dispatch(
        fetchSizesStart()
      );
    }, [dispatch]);

    const handleLoadMore = () => {
      dispatch(
        fetchSizesStart()
      );
    };

    const handleEditSize = (size) => {
        if(!size) return;
  
        dispatch(
          setSize(size)
        );
      }

      const handleDeletePopup = (size) =>{
        if (!size) return;

        dispatch(
          setSize(size)
       );
    }
    
      const configLoadMore = {
        onLoadMoreEvt: handleLoadMore,
       };
  

    return (   
      <React.Fragment>
       
            <div className="col-lg-9 col-md-8"  style={{ minHeight: '600px' }}>

               <div className="property-filter d-sm-flex">

                  <ul className="property-short list-unstyled d-sm-flex mb-0">
                    <li>
                        <div className="form-group d-lg-flex d-block">
                          <label className="justify-content-start">Product Sizes</label>
                        </div>
                    </li>
                  </ul>

                  <ul className="property-view-list list-unstyled d-flex mb-0">
                    <li>
                    <Link data-toggle="modal" data-target="#SizeAddModal" title="Quick View" className="btn btn-primary btn-block quick-view modal-view detail-link" to="#/addcatogorygroup">Add Product Size</Link>
                    </li>
                  </ul>
                </div>

                  <Table striped bordered hover size="sm">
                
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Value</th>
                      <th>Description</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>

          
                  {(Array.isArray(data) && data.length > 0) && data.map((size, index) => {
                      const {
                          value,
                          label,
                          documentID
                      } = size;

                      return (

                        <tr key={index}> 
                          <td title={ documentID }>{ index }</td>
                          <td>{ value }</td>
                          <td>{ label  }</td>
                          <td>
                              <div className="agent-social ml-auto mt-2 mt-sm-0">
                                <ul className="list-unstyled list-inline">
                                  <li className="list-inline-item"><a href="#/editsize" id={documentID} onClick={() => handleEditSize(size)} data-toggle="modal" data-target="#sizeEditModal" title="Update Product Size"><i className="fab fa-edit"></i>EDIT </a></li>
                                  <li className="list-inline-item"><a href="#/deletesize" id={documentID} onClick={() => handleDeletePopup(size) } data-toggle="modal" data-target="#sizeDelModal" title="Remove Product Size"><i className="fab fa-delete"></i>REMOVE </a></li>
                                </ul>
                              </div>
                          </td>
                        </tr>

      
                      )
                      })}

                        </tbody>
                      </Table>

                      <br />
          
                      {!isLastPage && (
                      <LoadMore {...configLoadMore} />
                      )}
        </div>

     </React.Fragment>
  );
}

export default AdminProductSizes;