import slidesTypes from './slides.types';
import slideTypes from './slides.types';

const INITIAL_STATE = {
  slides: [],
  slide: {},
  
};

const slidesReducer = (state=INITIAL_STATE, action) => {
  switch(action.type) {
    case slideTypes.SET_SLIDES:
      return {
        ...state,
        slides: action.payload
      }
   
    case slidesTypes.SET_SLIDE:
      return {
        ...state,
        slide: action.payload
      }
    default:
      return state;
  }
};

export default slidesReducer;