import React from 'react'

import SignIn from '../components/Auth/SignIn';
import SignUp from '../components/Auth/SignUp';

const LoginRegisterPage = props => {
  
    return (
        
        <div className="htc__login__register bg__white ptb--130" style={{ background: "#fff" }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-md-offset-3">
                        <ul className="login__register__menu" role="tablist">
                            <li role="presentation" className="login active"><a href="#login" role="tab" data-toggle="tab">Login</a></li>
                            <li role="presentation" className="register"><a href="#register" role="tab" data-toggle="tab">Register</a></li>
                        </ul>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-md-6 col-md-offset-3">
                        <div className="htc__login__register__wrap">
                            
                            <div id="login" role="tabpanel" className="single__tabs__panel tab-pane fade in active">
                                <SignIn />
                            </div>
                            
                            <div id="register" role="tabpanel" className="single__tabs__panel tab-pane fade">
                                <SignUp />
                            </div>
                            
                        </div>
                    </div>
                </div>
                
            </div>
        </div>

    )

}


export default LoginRegisterPage;
