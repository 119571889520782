
import React, {  useState , useEffect } from 'react';
import { useDispatch  } from 'react-redux';
import { addSizeStart  } from '../../../redux/ProductSizes/size.actions';
import FormInput from '../../Forms/FormInput/index';
import { Button } from 'react-bootstrap';

import '../style.css';

const  SizeAddModal = props => {
  
    const dispatch = useDispatch();
    const [ value, setValue ] = useState('');
    const [ label, setLabel ] = useState('');
  
  
    useEffect(() => {
    }, [dispatch]);
  
    const resetForm = () => {
     
        setValue('');
        setLabel('');
      };
  
  
      const handleSubmit = e => {
        e.preventDefault();
    
        dispatch(
            addSizeStart({
            value,
            label
          })
        );
        resetForm();
    
      };
  
    return (
                    
    <div className="modal fade" id="SizeAddModal" tabIndex="-2" role="dialog">
        <div className="modal-dialog modal__container" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>

                <div className="modal-body">
                    <div className="modal-product">
                        
                        <form onSubmit={ handleSubmit }>
                        <div className="product-info">
                            <h1>Product Size </h1>


                            <div className="quick-desc">
                                <FormInput
                                label="Name"
                                type="text"
                                value={value}
                                handleChange={e => setValue(e.target.value)}
                                /> 
                            </div>

                            <div className="quick-desc">
                                <FormInput
                                label="Label"
                                type="text"
                               
                                value={label}
                                handleChange={e => setLabel(e.target.value)}
                                />
                            </div>
                        
                            <div className="htc__submit__btn">
                                <Button className="btn link" onClick={ handleSubmit } data-dismiss="modal" aria-label="Close">
                                    Add Size
                                </Button>
                            </div>

                        </div>
                        </form>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
            
    )
}

export default SizeAddModal;
