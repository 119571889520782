import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import userReducer from './User/user.reducer';
import productsReducer from './Products/products.reducer';
import shirtsReducer from './Shirts/shirts.reducer';
import categoriesReducer from './Product-categories/categories.reducer';
import cartReducer from './Cart/cart.reducer';
import ordersReducer from './Orders/orders.reducer';
import articlesReducer from './articles/articles.reducer';
import slidesReducer from './slides/slides.reducer';
import sizesReducer from './ProductSizes/size.reducer';
import colorsReducer from './ProductColors/color.reducer';

import RandomSlidesReducer from './HomeSlides/home.slides.reducer';
import AppAuthReducer from './AppAuth/app.auth.reducer';
import CategoryGroupsReducer  from './CategoryGroups/category.group.reducer';

export const rootReducer = combineReducers({
   user: userReducer,
   productsData: productsReducer,
   shirtsData: shirtsReducer,
   categoriesData: categoriesReducer, 
   cartData: cartReducer,
   ordersData: ordersReducer,
   aricleData: articlesReducer,
   slidesData: slidesReducer,
   sizesData: sizesReducer,
   colorsData: colorsReducer,

   randomSlidesData : RandomSlidesReducer,
   appAuthData : AppAuthReducer,
   categoryGroupsData: CategoryGroupsReducer
});

const configStorage = {
  key: 'root',
  storage,
  whitelist: ['cartData']
};

export default persistReducer(configStorage, rootReducer);
