import React from 'react';
import { useSelector } from 'react-redux'
import UserProfile from '../UserProfile/UserProfile';
import './styles.css';

const mapState = ({ user }) => ({
  currentUser: user.currentUser
})

const VerticalNav = ({ children }) => {
  const { currentUser } = useSelector(mapState);

  const configUserProfile = {
    currentUser
  }

  return (
      <div className="col-md-3">
        <div className="product-categories-all">
            <UserProfile {...configUserProfile} />
            <div className="product-categories-menu">
                { children }
            </div>
        </div>
      </div>
  );
}

export default VerticalNav;