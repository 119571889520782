import React, { Component } from 'react';
import '../Page.css';

export default class AboutPage extends Component {

   
    render() {

        let bgStyle = {
            background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(209,185,190,0.33237044817927175) 100%, rgba(0,212,255,1) 100%)'
        };
        let txtStyle = {
            color: '#ffffff'
         };

        return (
            <div className="wrapper fixed__footer">
                <div className="row">

                <div className="ht__bradcaump__area" style={  bgStyle } >
                    <div className="ht__bradcaump__wrap">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="bradcaump__inner text-center">
                                        <h2 className="bradcaump-title" style={ txtStyle }>About us</h2>
                                        <nav className="bradcaump-inner">
                                        <a className="breadcrumb-item" style={ txtStyle } href="/">Home</a>
                                        <span className="brd-separetor" style={ txtStyle } >/</span>
                                        <span className="breadcrumb-item active" style={ txtStyle } >About us</span>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="htc__store__area ptb--60 bg__white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section__title section__title--2 text-center">
                                    <h2 className="title__line">Anointed Republic</h2>
                                    <p>Anointed Republic is a clothing brand known for its urban fashion for men, women and teenagers.
                                    It was founded in November 2009, with the aim of creating affordable urban fashion for persons looking
                                    for unique self-identity.</p>
                                </div>
                                <div className="store__btn">
                                    <a href="contact-us">contact us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="htc__choose__us__ares bg__white">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                        <div className="video__wrap bg--3" data--black__overlay="5" >
                            <div className="video__inner">
                               
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                        <div className="htc__choose__wrap bg__cat--4">
                            <h2 className="choose__title">What we do </h2>
                            <div className="choose__container">
                                <div className="single__chooose">
                                    <div className="choose__us">
                                        <div className="choose__icon">
                                            <span className="ti-heart"></span>
                                        </div>
                                        <div className="choose__details">
                                            <h4>Make clothes</h4>
                                            <p>We love designing your clothes </p>
                                        </div>
                                    </div>
                                    <div className="choose__us">
                                        <div className="choose__icon">
                                            <span className="ti-truck"></span>
                                        </div>
                                        <div className="choose__details">
                                            <h4>Delivery</h4>
                                            <p>We deliver to your location </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="single__chooose">
                                    <div className="choose__us">
                                        <div className="choose__icon">
                                            <span className="ti-reload"></span>
                                        </div>
                                        <div className="choose__details">
                                            <h4>Fashion</h4>
                                            <p>We always bring you the latest fashion </p>
                                        </div>
                                    </div>
                                    <div className="choose__us">
                                        <div className="choose__icon">
                                            <span className="ti-time"></span>
                                        </div>
                                        <div className="choose__details">
                                            <h4>Support</h4>
                                            <p>We will answer all your questions </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="htc__team__area bg__white ptb--120">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="section__title section__title--2 text-center">
                            <h2 className="title__line"></h2>
                            <p></p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="team__wrap clearfix mt--60">
                      
                        
                    
                       
                   
                       
                      
                    </div>
                </div>
            </div>
        </section>




            </div>
        </div>
    )
 }
}
