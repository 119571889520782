import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchSearchStart } from '../../redux/Products/products.actions';
import ShopProduct from '../ProductsResults/ShopProduct/index';
import LoadMore from '../LoadMore/index';

import './styles.css';

const mapState = ({ productsData }) => ({
  products: productsData.searchProductResults
});

const SearchResults = () => {
  
  const dispatch = useDispatch();
  const { keywords } = useParams();

   const { products } = useSelector(mapState);
   const { data, queryDoc, isLastPage } = products;
 
  useEffect(() => {
   
    dispatch(
      fetchSearchStart({ keywords })
    )
  }, [dispatch, keywords]);

  if (!Array.isArray(data)) return null;
  
  if (data.length < 1) {
    return (

       <section className="htc__product__area shop__page ptb--130 bg__white">
          <div className="container">
            <div className="htc__product__container">
            
                <div className="row  text-center  pt-15 mt--80">
                    <h2><span className="zmdi zmdi-mood-bad mood-bad" ></span> Looks like</h2>
                    <p>No search results found at the moment.</p>
                </div>
            </div>
           </div>
        </section>
    );
  }



  const handleLoadMore = () => {
    dispatch(
      fetchSearchStart({
        keywords,
        startAfterDoc: queryDoc,
        persistProducts: data
      })
    )
  };

  const configLoadMore = {
    onLoadMoreEvt: handleLoadMore,
  };
               
  return(
      <section className="htc__product__area shop__page ptb--130 bg__white">
        <div className="container">
            <div className="htc__product__container">
                
            
                <div className="row">
                    <div className="product__list another-product-style" style={{ textAlign: 'center' }}>
                    <h2 className="category-heading"> 
                        
                    </h2>
                         

                        {data && data.map( (product, idx) => {
                              const { productThumbnail, productName, productPrice } = product;
                              
                              if (!productThumbnail || !productName ||
                              typeof productPrice === 'undefined') return null;

                              const configProduct = {
                                ...product
                            };
                            return (
                              <ShopProduct key={idx} {...configProduct} />
                            );

                          }
                          )}
                    
                     
                    </div>

                  </div>
    
              
            
        </div>
    </div>
  </section>
  )

};

export default SearchResults;
