import articlesTypes from './articles.types';
import articleTypes from './articles.types';

const INITIAL_STATE = {
  artciles: [],
  article: {},
};

const articlesReducer = (state=INITIAL_STATE, action) => {
  switch(action.type) {
    case articleTypes.SET_ARTICLES:
      return {
        ...state,
        articles: action.payload
      }
    case articlesTypes.SET_ARTICLE:
      return {
        ...state,
        article: action.payload
      }
    default:
      return state;
  }
};

export default articlesReducer;