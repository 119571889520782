import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './slider.css';

const SlideshowCarausel = () => {


    useEffect(() => {
      }, []);

    return (
        <section className="categories-slider-area bg__white">
            <div className="container">
                <div className="row">
                    
                    <div className="col-md-12 col-lg-12 col-sm-10 col-xs-12 float-left-style">
                        
                        <div className="slider__container slider--one">
                            
                            <OwlCarousel className='slider__activation__wrap owl-carousel owl-theme' 
                                loop 
                                margin={0}
                                autoplay={true}
                                autoplayHoverPause={true}
                                center={true}
                                autoWidth={true}
                                nav
                                navText ={['<i class="zmdi zmdi-chevron-left"></i>', '<i class="zmdi zmdi-chevron-right"></i>']}
                                navElement={'div'}
                                autoplayTimeout={10000}
                                items={1}
                                dots={false}
                                lazyLoad
                                responsive={{
                                    0:{
                                        items:1
                                    },
                                    600:{
                                        items:1
                                    },
                                    800:{
                                        items:1
                                    },
                                    1024:{
                                        items:1
                                    },
                                    1200:{
                                        items:1
                                    },
                                    1400:{
                                        items:1
                                    },
                                    1920:{
                                        items:1
                                    }
                                    }}
                                >

                            <div className="slide slider__full--screen slider-height-inherit  slider-text-right" 
                                    style={{ background: `rgba(0, 0, 0, 0) url('https://anointedrepublic.co.za/resources/slides/slide-main.jpg') no-repeat scroll center center / contain` }}
                                    >
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12">
                                                <div className="slider__inner">
                                                    <h1> <span className="text--theme"></span></h1>
                                                    <div className="slider__btn">
                                                    <Link className="htc__btn" to="/"></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                  

                                <div className="slide slider__full--screen slider-height-inherit slider-text-left" 
                                    style={{ background: `rgba(0, 0, 0, 0) url('https://anointedrepublic.co.za/resources/slides/slide1.jpg') no-repeat scroll center center / contain` }}
                                    >
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-10 col-lg-8 col-md-offset-2 col-lg-offset-4 col-sm-12 col-xs-12">
                                                <div className="slider__inner">
                                                    <h1> <span className="text--theme"></span></h1>
                                                    <div className="slider__btn">
                                                        <Link className="htc__btn" to="/"></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="slide slider__full--screen slider-height-inherit  slider-text-right" 
                                    style={{ background: `rgba(0, 0, 0, 0) url('https://anointedrepublic.co.za/resources/slides/slide2.jpg') no-repeat scroll center center / contain` }}
                                    >
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12">
                                                <div className="slider__inner">
                                                    <h1> <span className="text--theme"></span></h1>
                                                    <div className="slider__btn">
                                                    <Link className="htc__btn" to="/"></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                   
                              
                               
                            
                                </OwlCarousel>
                        
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
    
}

export default SlideshowCarausel;
