import React from 'react'
import ProductBottomCarouselCol from './ProductBottomCarouselCol';
const FullWidthProductList = (props) => {

    return (    
        <section className="htc__product__area bg__white">
          <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="product-style-tab">
                        <div className="tab-content another-product-style jump">
                            <div className="tab-pane active" id="home-sale-products">
                                <div className="row">
                                    <ProductBottomCarouselCol  columns ={4} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
           </div>
        </section>
    )
}

export default FullWidthProductList;