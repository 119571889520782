import React from 'react';

const FormInput = ({ handleChange, label, ...otherProps }) => {
  return (
 
      <input onChange={handleChange} {...otherProps} />

  );
}

export default FormInput;