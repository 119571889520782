import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import { SiteConsumer } from '../../context';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import  { fetchSlidesStart } from '../../redux/HomeSlides/home.slides.actions';

const mapState = state => ({
    appAuth : state.appAuthData,
    slidesData: state.randomSlidesData
});

const RandomSlides = () => {

    const dispatch = useDispatch();
    const { appAuth, slidesData } = useSelector(mapState);
    
    const {
        token,
        loading
    } =  appAuth;

    const { slides  } = slidesData;
  
   // const [ state, token]  = useSelector( (state) => [ state.randomSlidesData, state.appAuthData ]);

    let column = 4;

    useEffect(() => {
    
        if(token) {
            dispatch(fetchSlidesStart(token));
        }

    }, [dispatch, token]);


    const renderSlides = () => {

        if(loading) {
            return(<h1>loading</h1>)
        }

    return (
        <OwlCarousel className='product-slider-active owl-carousel' 
                loop 
                margin={0}
                nav
                navText ={['<i class="zmdi zmdi-chevron-left"></i>', '<i class="zmdi zmdi-chevron-right"></i>']}
                navElement={'div'}
                autoplayTimeout={10000}
                items={4}
                responsive={{
                    0:{
                        items:1
                        },
                        600:{
                        items:2
                        },
                        800:{
                        items:2
                        },
                        1024:{
                        items:`${column}`
                        },
                        1200:{
                        items: `${column}`
                        },
                        1400:{
                        items:`${column}`
                        },
                        1920:{
                        items: `${column}`
                        }
                    }}
                >
                {slides.map( (slide, pos) => {
                
                return(
                 <div  key= {`parent_${pos} `} className="col-md-4 single__pro col-lg-4 cat--1 col-sm-4 col-xs-12">
                    <div key={pos} className="product">
                        <div className="product__inner">
                            <div className="pro__thumb">
                                <Link to="#">
                                    <img src={ slide.full_url } alt={ slide.title } />
                                </Link>
                            </div>
                            <div className="product__hover__info">
                               
                            </div>
                        </div>
                    </div>
                 </div>
                )
                
             })}

         </OwlCarousel>
        )
    }
   

    return (
      <SiteConsumer>
            {( param ) => {

            return (
                <section className="htc__blog__area bg__white pb--130">
                  <div className="container">
      
                    <div className="row">
                        <div className="blog__wrap clearfix mt--60 xmt-30">
                        
                            {
                                renderSlides()
                            }
                        
                        </div>
                    </div>
                  </div>
                </section>
             )

            }}
    
         </SiteConsumer>  
    )
};

export default RandomSlides;