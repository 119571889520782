import React, { Component } from 'react'
import { SiteConsumer } from '../context';
import ResetPassword from '../components/Auth/ResetPassword';

export default class RecoveryPage extends Component {
    render() {
        return (
            <SiteConsumer>
            { (param) => {
              
              return(
                  
                <div className="htc__login__register bg__white ptb--130" style={{ background: "#fff" }}>
                  <div className="container">
              
                      <div className="row">
                          <div className="col-md-6 col-md-offset-3">
                              <div className="htc__login__register__wrap">
                               
                                  <div id="login" role="tabpanel" className="single__tabs__panel tab-pane fade in active">
                                      <ResetPassword />
                                  </div>
                               
                              </div>
                          </div>
                      </div>
                     
                  </div>
              </div>

               )
            }}
             
          </SiteConsumer>
        )
    }
}
