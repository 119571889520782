import shirtsTypes from './shirts.types';


export const fetchShirtsStart = (filters={}) => ({
  type: shirtsTypes.FETCH_SHIRTS_START,
  payload: filters
});

export const setShirts = shirts => ({
  type: shirtsTypes.SET_SHIRTS,
  payload: shirts
});

export const fetchShirtStart = shirtID => ({
  type: shirtsTypes.FETCH_SHIRT_START,
  payload: shirtID
});

export const setShirt = shirt => ({
  type: shirtsTypes.SET_SHIRT,
  payload: shirt
});