import React from 'react';
import { Link } from 'react-router-dom';
import { SiteConsumer } from '../../../context';

const ShopProduct = (product, props) => {

return (
  <SiteConsumer>

  {(param) => {
            
    const { 
        setSelectedProduct
    } = param;

    const {
      documentID,
      productThumbnail,
      productName,
      productPrice,
      productInStock,
      productCategory
    } = product;

    if (!documentID || !productThumbnail || !productName ||
      typeof productPrice === 'undefined') return null;

 
    const handleAddToCart = (product) => {
      setSelectedProduct(product);
    };

  return (
    <div className="col-md-3 single__pro col-lg-3 cat--1 col-sm-4 col-xs-12">
        <div className="product foo">

        <div className="product__inner">

            <div className="thumb">
              <Link to={`/product/${documentID}`}>
                <img src={productThumbnail} alt={ productName } />
              </Link>
            </div>

                <div className="product__hover__info">
                    <ul className="product__action">
                        <li><Link id={documentID} onClick={ () => handleAddToCart(product) }  to="#/product-quick-view" data-toggle="modal" data-target="#productModal" title="Quick View" className="quick-view modal-view detail-link" ><span className="ti-plus"></span></Link></li>
                        <li><Link to="#" title="Add To Cart" onClick={ (e) => {param.openCart(); e.preventDefault(); }}><span className="ti-shopping-cart"></span></Link></li>
                        <li><Link to="#" title="Wishlist" onClick={ (e) => {param.openCart(); e.preventDefault(); }} ><span className="ti-heart"></span></Link></li>
                    </ul>
                </div>
          </div>
          <div className="product__details">
              <h2>
                <Link to={`/product/${documentID}`}>
                  {productName}
                  { productInStock &&
                      <span className="outofstock">{ productInStock === '2' ? "Out of Stock" : ''}</span>
                    }
                </Link>
              </h2>
          
              <span className="price">
                R {productPrice}
              </span>
          
          
              <div className="addToCart">
                {/* <Button {...configAddToCartBtn} onClick={() => handleAddToCart(product)}>
                  Add to cart
                </Button> */}
              </div>
          
          </div>
   
      </div>
    </div>
  )

  }}
  </SiteConsumer>
)
};

export default ShopProduct;