import categoryGroupTypes from "./category.group.types";
import Axios from "axios";

export const fetchCategoryGroupStart =
  (token) => async (dispatch, getState) => {
    dispatch({
      type: categoryGroupTypes.FETCH_CATEGORY_GROUPS_START,
      loading: true,
    });

    try {
      const headers = {
        headers: {
          Authorization: token,
          appid: process.env.REACT_APP_ID,
        },
      };

      const response = await Axios.get(
        process.env.REACT_APP_CATEGORY_GROUPS_ENDPOINT
      );

      if (
        !response.data.ok &&
        (response.data.code === "InvalidToken" ||
          response.data.code === "NoAuthProvided")
      ) {
        //get token dispatch
        dispatch({
          type: categoryGroupTypes.FETCH_CATEGORY_GROUPS_FAILURES,
          error: response.data.code,
        });
      }

      if (response.data.ok) {
        dispatch({
          type: categoryGroupTypes.FETCH_CATEGORY_GROUPS_SUCCESS,
          payload: response.data.result,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: categoryGroupTypes.FETCH_CATEGORY_GROUPS_FAILURES,
        error,
      });
    }
  };

//---
export const editCategoryGroupStart = (groupData) => ({
  type: categoryGroupTypes.EDIT_CATEGORY_GROUP_START,
  payload: groupData,
});
export const setCategoryGroup = (group) => ({
  type: categoryGroupTypes.SET_CATEGORY_GROUP,
  payload: group,
});
