import { auth } from '../../firebase/util';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import { setSizes ,setSize, fetchSizeStart } from './size.actions';
import { 
  handleAddSize, 
  handleFetchSizes,
  handleFetchSize, 
  handleDeleteSize,
  handleEditSize 
} from './size.helpers';
import sizeTypes from './size.types';

export function* addSize({ payload }) {

  try {
    const timestamp = new Date();
    yield handleAddSize({
      ...payload,
      ProductSizeAdminUserUID: auth.currentUser.uid,
      createdDate: timestamp
    });
    yield put(
      fetchSizeStart()
    );

  } catch (err) {
    // console.log(err);
  }

}

export function* onAddSizeStart() {
  yield takeLatest(sizeTypes.ADD_NEW_SIZE_START, addSize);
}

export function* fetchSizes({ payload }) {
  try {
    const groups = yield handleFetchSizes(payload);
    yield put(setSizes(groups));
    
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchSizesStart() {
  yield takeLatest(sizeTypes.FETCH_SIZES_START, fetchSizes);
}

export function* deleteSize({ payload }) {
  try {
    yield handleDeleteSize(payload);
    yield put (
      fetchSizeStart()
    );

  } catch (err) {
    // console.log(err);
  }
}

export function* onDeleteSizeStart() {
  yield takeLatest(sizeTypes.DELETE_SIZE_START, deleteSize);
}

export function* fetchSize({ payload }) {
  try {
    const group = yield handleFetchSize(payload);
    yield put(
      setSize(group)
    );

  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchSizeStart() {
  yield takeLatest(sizeTypes.FETCH_SIZE_START, fetchSize);
}


//----
/**
 * editCategory
 */
 export function* editSize({ payload }) {

  try {
    const timestamp = new Date();
    yield handleEditSize ({
      ...payload,
      modifiedByAdminUserUID: auth.currentUser.uid,
      midifiedDate: timestamp
    });
    yield put(
      fetchSizeStart()
    );

  } catch (err) {
  }
}

export function* onEditSizeStart() {
  yield takeLatest(sizeTypes.EDIT_SIZE_START, editSize);
}

export default function* SizeSagas() {
  yield all([
    call(onAddSizeStart),
    call(onFetchSizesStart),
    call(onDeleteSizeStart),
    call(onFetchSizeStart),
    call(onEditSizeStart),

  ])
}