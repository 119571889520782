import React, { useState, useEffect } from 'react';
import { fetchProductStart, setProduct } from '../../redux/Products/products.actions';

import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addProduct } from '../../redux/Cart/cart.actions';
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,

  } from "react-share";
import { Button}  from 'react-bootstrap';
import './styles.css';

const mapState = state => ({
    product: state.productsData.product
});

const ProductViewDetails = ( props ) => {

    const dispatch = useDispatch();
    //const history = useHistory();
    const [selectedColor, setSelectedColor ] = useState('');
    const [selectedSize, setSelectedSize ] = useState('');
    const { productID } = useParams();
    const { product } = useSelector(mapState);
    const [ message, setMessage ] = useState('');
  
    const {
        documentID,
        productThumbnail,
        productName,
        productPrice,
        productDesc,
        productColors,
        productSizes,
        productInStock
    } =  product;

    useEffect(() => {
        dispatch(
            fetchProductStart(productID)
        )
        return () => {
            dispatch(
            setProduct({})
            )
        }
    },[dispatch,productID]);


    const resetSelections = () =>{
        setSelectedColor('');
        setSelectedSize('');
    }

    const pickColor = (color) => {
        setSelectedColor(color);
    }

    const pickSize = (size) => {
        setSelectedSize(size);
    }

    const handleAddToCart = ( product) => {
       
        if (!product) return;

        if(!selectedColor && productColors.length ===1) {
            setSelectedColor(productColors[0]);
        }

        if(!selectedSize && productSizes.length ===1) {
            setSelectedSize(productSizes[0]);
        }

        if( productColors.length > 1 && (!selectedColor || !selectedSize)) {
            alert('Please select both color and size!');
            return;
        }

        if( productSizes.length > 1 &&  !selectedSize){
            alert('Please select a product size!');
            return;
        }

        if(product.productCategory === 't-shirts') {
            // get number of t-shirts in cart get mod 2
           // alert('t-shirt selected');
        }
        
        product.selectedColor = selectedColor;
        product.selectedSize = selectedSize;

        dispatch( addProduct(product));

        resetSelections();

        setMessage("product successfuly added to cart");
        setTimeout(() => {
            setMessage("");
          }, 3000);
       
     };

  

   return(
       <div>
        <section className="htc__product__details pt--120 pb--100 bg__white">
        <div className="container">
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        <h3 className="message">{ message }</h3>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                        <div className="product__details__container">

                           
                    
                            <div className="product__big__images">
                                <div className="portfolio-full-image tab-content">
                                    <div role="tabpanel" className="tab-pane fade in active product-video-position" id="img-tab-1">
                                        <img src={ productThumbnail } alt="product" style={{ margintop: "120px" }} />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 smt-30 xmt-30">
                        <div className="htc__product__details__inner">
                            <div className="pro__detl__title">
                                <h2>{ productName } </h2>
                            </div>
                            
                            <div className="pro__dtl__rating">
                                <p className="outofstock">{ productInStock === '2' ? "Out of Stock" : ''}</p>
                            </div>

                            <div className="pro__details">
                                { productDesc  &&
                                <div>
                                    { productDesc.split(",").map( (feature, index) => 
                                        (
                                            <p key={index}>{ feature }</p>
                                        )
                                    )}
                                </div>
                                }
                            </div>
                            <ul className="pro__dtl__prize">
                                <li>R {productPrice}</li>
                            </ul>
                            <div className="single__filter">
                                <h2 className="title__5">Colours available</h2>
                            
                                { productColors  &&
                                    <ul className="color__list">
                                        {productColors.map(d => (<li key={d} className={ d } onClick= { e => pickColor(d) } ><Link to="#" title={d}></Link> {d} </li>))} 

                                    </ul>
                                }
                               { productColors &&
                                    productColors.length === 1 ? 
                                <p className="small"> selected:  {productColors[0]}</p>
                                : (<p className="small">{ selectedColor ?  'selected: ' + selectedColor : 'no color selected'}</p>) 
                                }

                              
                            </div>
                            <div className="single__filter">
                                <h2 className="title__5">Size</h2>

                                {productSizes  &&
                                    <ul className="color__list">
                                        {productSizes.map(d => (<li key={d} className='l__size' onClick={ e=> pickSize(d) }><Link to="#" title={d}></Link> {d} </li>))} 

                                    </ul>
                                }
                                {productSizes &&
                                    productSizes.length === 1 ? 
                                <p className="small"> selected:  {productSizes[0]}</p>
                                : (<p className="small">{ selectedSize ?  'selected: ' + selectedSize : 'no size selected'}</p>) 
                                }

                            </div>
                          
                            <ul className="pro__dtl__btn">

                            

                                <Button onClick={(e) => { handleAddToCart( product); }}  disabled={ productInStock === '2' ? true: false } >
                                     buy now
                                </Button>
                                <li><Link to="#"><span className="ti-heart"></span></Link></li>

                                <EmailShareButton url={ `${ window.location.host }/product/${documentID}`} quote={ productName } className="share email_share">
                                     <EmailIcon size={42} round={false}/>
                                </EmailShareButton>

                            </ul>

                            <div className="pro__social__share">
                                <h2>Share :</h2>
                                <ul className="pro__soaial__link">
                                    <FacebookShareButton url={ `${ window.location.host }/product/${documentID}`} quote={ productName } className="share">
                                        <FacebookIcon size={32} round={true}/>
                                    </FacebookShareButton>

                                    <WhatsappShareButton url={ `${ window.location.host }/product/${documentID}`} quote={ productName } className="share">
                                        <WhatsappIcon size={32} round={true}/>
                                    </WhatsappShareButton>

                                    <LinkedinShareButton url={ `${ window.location.host }/product/${documentID}`} quote={ productName } className="share">
                                        <LinkedinIcon size={32} round={true}/>
                                    </LinkedinShareButton>
                                    
                                    <TwitterShareButton url={ `${ window.location.host }/product/${documentID}`} quote={ productName } className="share">
                                        <TwitterIcon size={32} round={true}/>
                                    </TwitterShareButton>
                                    
                                   
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section className="htc__product__details__tab bg__white pb--120">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        <ul className="product__deatils__tab mb--60" role="tablist">
                            <li role="presentation" className="active">
                                <a href="#description" role="tab" data-toggle="tab">Description</a>
                            </li>
                           
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="product__details__tab__content">
                        
                            <div role="tabpanel" id="description" className="product__tab__content fade in active">
                                <div className="product__description__wrap">
                                    <div className="product__desc">
                                        <h2 className="title__6">Details</h2>
                                        <p>{ productDesc }</p>
                                    </div>
                                    <div className="pro__feature">
                                        <h2 className="title__6">Features</h2>

                                        { productDesc  &&
                                           <ul className="feature__list">
                                                { productDesc.split(",").map( (feature, index) => 
                                                    (    
                                                     <li key={ index }><Link to="#"><i className="zmdi zmdi-play-circle"></i> { feature }</Link></li>
                                                    )
                                                )}
                                            </ul>
                                        }

                                        
                                    </div>
                                </div>
                            </div>
                        
                          
                        
                            <div role="tabpanel" id="reviews" className="product__tab__content fade">
                                <div className="review__address__inner">
                                  <p>No reviews</p>
                                </div>
                          
                             
                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>

        </section>
       </div>
    );

    
}

export default ProductViewDetails;
