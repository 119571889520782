import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import './App.css';
import {SiteProvider} from './context';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/createStore';

import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';

ReactDOM.render(
  <React.StrictMode>
  <SiteProvider>
    <Provider store={ store }>
     <Router>
        <PersistGate persistor={persistor}>
          <App />  
        </PersistGate>
      </Router>
    </Provider>
  </SiteProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


