import colorTypes from './color.types';

const INITIAL_STATE = {
  colors: [],
  color: {},
};

const colorsReducer = (state=INITIAL_STATE, action) => {
  switch(action.type) {
    case colorTypes.SET_COLORS:
      return {
        ...state,
        colors: action.payload
      }

    case colorTypes.SET_COLOR:
      return {
        ...state,
        color: action.payload
      }
    default:
      return state;
  }
};

export default colorsReducer;