import { auth } from "../../firebase/util";
import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  setSearchResults,
  setHomeBottomProducts,
  setCategorizedProducts,
  setFilterResult,
  setProducts,
  setProduct,
  setLatestProductsInStock,
  setProductSpecials,
  fetchProductsStart,
} from "./products.actions";
import {
  handleSearchProducts,
  handleAddProduct,
  handleFetchProducts,
  handleFetchProductsOnSpecial,
  handleFetchLatestProductsInStock,
  handleFetchHomeProducts,
  handleFetchProduct,
  handleEditProduct,
  handleDeleteProduct,
} from "./products.helpers";
import productsTypes from "./products.types";

/**
 * addProduct
 */
export function* addProduct({ payload }) {
  try {
    const timestamp = new Date();
    yield handleAddProduct({
      ...payload,
      productAdminUserUID: auth.currentUser.uid,
      createdDate: timestamp,
    });
    yield put(fetchProductsStart());
  } catch (err) {}
}

export function* onAddProductStart() {
  yield takeLatest(productsTypes.ADD_NEW_PRODUCT_START, addProduct);
}

/**
 * editProduct
 */
export function* editProduct({ payload }) {
  try {
    const timestamp = new Date();
    yield handleEditProduct({
      ...payload,
      modifiedByAdminUserUID: auth.currentUser.uid,
      midifiedDate: timestamp,
    });
    yield put(fetchProductsStart());
  } catch (err) {}
}

export function* onEditProductStart() {
  yield takeLatest(productsTypes.EDIT_PRODUCT_START, editProduct);
}

/**
 * fetchSearchKeywords
 */
export function* fetchSearchStart({ payload }) {
  try {
    const keywodProducts = yield handleSearchProducts({
      keywords: payload.keywords,
      persistProducts: [],
    });

    yield put(setSearchResults(keywodProducts));
  } catch (err) {}
}

export function* onFetchSearchStart() {
  yield takeLatest(productsTypes.FETCH_KEYWORDS_START, fetchSearchStart);
}

/**
 * fetchProducts
 */
export function* fetchProducts({ payload }) {
  try {
    const products = yield handleFetchProducts(payload.filters);

    yield put(setProducts(products));

    const groupedProductsValue = products.data.reduce(
      (
        productSoFar,
        {
          productCategory,
          documentID,
          productThumbnail,
          productName,
          productDesc,
          productPrice,
          productInStock,
          createdDate,
          midifiedDate,
          modifiedByAdminUserUID,
          productAdminUserUID,
          productCategoryGroup,
          productColors,
          productSizes,
        }
      ) => {
        let product = {
          documentID,
          productThumbnail,
          productName,
          productDesc,
          productPrice,
          productInStock,
          createdDate,
          midifiedDate,
          modifiedByAdminUserUID,
          productAdminUserUID,
          productCategoryGroup,
          productColors,
          productSizes,
        };

        if (!productSoFar[productCategory]) productSoFar[productCategory] = [];
        productSoFar[productCategory].push(product);
        return productSoFar;
      },
      {}
    );

    let productsGrouped = [];
    let reoganisedProducts = [];
    for (const groupedKey in groupedProductsValue) {
      productsGrouped.push({
        category: groupedKey,
        products: groupedProductsValue[groupedKey],
      });
    }

    if (payload.categories) {
      let grouped = payload.categories.data;
      if (
        productsGrouped &&
        Array.isArray(productsGrouped) &&
        grouped &&
        Array.isArray(grouped) &&
        grouped.length > 0
      ) {
        
        grouped.forEach((group) => {
          let productsObj = productsGrouped.filter((prod) => {
            return prod.category === group.categoryName.toLowerCase();
          });

          if (productsObj.length > 0) reoganisedProducts.push(productsObj[0]);
        });

        setProducts(reoganisedProducts);
       
        yield put(setCategorizedProducts(reoganisedProducts));
      }
    }
  } catch (err) {}
}

export function* onFetchProductsStart() {
  yield takeLatest(productsTypes.FETCH_PRODUCTS_START, fetchProducts);
}

/**
 * fetchHomeTopProducts
 */
export function* fetchHomeProducts({ payload }) {
  try {
    const homeProducts = yield handleFetchHomeProducts({
      persistProducts: [],
      pagesize: 30,
    });

    const latestProductsInStock = yield handleFetchLatestProductsInStock(
      payload
    );

    yield put(setLatestProductsInStock(latestProductsInStock));


    const productsOnSpecial = yield handleFetchProductsOnSpecial({
      persistProducts: [],
      pagesize: 30,
    });
    // do some math & get specials
    yield put(setHomeBottomProducts(productsOnSpecial));

  } catch (err) {}
}

export function* onFetchHomeProductsStart() {
  yield takeLatest(productsTypes.FETCH_HOME_PRODUCTS_START, fetchHomeProducts);
}

export function* fetchProductsOnSpecial(payload) {

  try {

    const productsOnSpecial = yield handleFetchProductsOnSpecial({
      persistProducts: [],
      pagesize: 30,
    });

    yield put(setProductSpecials(productsOnSpecial));

  } catch (err) {}
}

export function* onFetchProductsOnSaleStart() {
  yield takeLatest(productsTypes.FETCH_PRODUCTS_ON_SPECIAL_START, fetchProductsOnSpecial)
}

/**
 * deleteProduct
 */
export function* deleteProduct({ payload }) {
  try {
    yield handleDeleteProduct(payload);
    yield put(fetchProductsStart());
  } catch (err) {}
}

export function* onDeleteProductStart() {
  yield takeLatest(productsTypes.DELETE_PRODUCT_START, deleteProduct);
}

/**
 * fetchProduct
 */
export function* fetchProduct({ payload }) {
  try {
    const product = yield handleFetchProduct(payload);
    yield put(setProduct(product));
  } catch (err) {}
}

export function* onFetchProductStart() {
  yield takeLatest(productsTypes.FETCH_PRODUCT_START, fetchProduct);
}

/**
 * productsSagas
 */
export default function* productsSagas() {
  yield all([
    call(onAddProductStart),
    call(onEditProductStart),
    call(onFetchProductsStart),
    call(onFetchSearchStart),
    call(onFetchHomeProductsStart),
    call(onFetchProductsOnSaleStart),
    call(onDeleteProductStart),
    call(onFetchProductStart),
  ]);
}
