import AppAuthTypes from './app.auth.types';

const INITIAL_STATE = {
  token: "",
  ok: false,
  message: "",
  loading: false,
  error: null
};

const AppAuthReducer = (state = INITIAL_STATE,  action ) => {
  switch(action.type) {
    case AppAuthTypes.FETCH_TOKEN_START:
      return {
        ...state,
        loading: true,
        error: null
      }
    case AppAuthTypes.FETCH_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.payload,
        ok: true,
        loading: false
      }
      case AppAuthTypes.FETCH_TOKEN_FAILURES:
      return {
        ...state,
        loading: false,
        error: action.error
      }
      case AppAuthTypes.SET_TOKEN:
        return {
          ...state,
          token: action.payload
      }



      case AppAuthTypes.FETCH_VALIDATE_TOKEN_START:
      return {
        ...state,
        loading: true,
        error: null
      }
      case AppAuthTypes.FETCH_VALIDATE_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.payload,
        ok: true,
        loading: false
      }
      case AppAuthTypes.FETCH_VALIDATE_TOKEN_FAILURES:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    default:
      return state;
  }

};

export default AppAuthReducer;