const sizeTypes = {
    ADD_NEW_SIZE_START: 'ADD_NEW_SIZE_START',
    FETCH_SIZES_START: 'FETCH_SIZES_START',
    FETCH_SIZE_START: 'FETCH_SIZE_START',
    SET_SIZES: 'SET_SIZES',
   
    SET_SIZE: 'SET_SIZE',
    DELETE_SIZE_START: 'DELETE_SIZE_START',
  
    SET_EDIT_SIZE: 'SET_EDIT_SIZE',
    EDIT_SIZE_START: 'EDIT_SIZE_START',
};
  
export default sizeTypes;