import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchProductsStart,
  setFilterResult,
} from "../../redux/Products/products.actions";
import ShopProduct from "./ShopProduct/index";
import ShopCategories from "./ShopCategories/index";
import LoadMore from "../LoadMore/index";

import "./styles.css";
import { filter } from "dom-helpers";

const mapState = ({ productsData, categoriesData, categoryGroupsData }) => ({
  groups: categoryGroupsData.groups,
  products: productsData.products,
  categorizedProducts: productsData.categorizedProducts,
  categories: categoriesData.categories,
});

const ProductResults = () => {
  const dispatch = useDispatch();
  const { filterType } = useParams();

  const { products } = useSelector(mapState);
  const { categorizedProducts } = useSelector(mapState);
  const { categories } = useSelector(mapState);

  const { data, queryDoc, isLastPage } = products;

  useEffect(() => {
    if (categories) {
      dispatch(fetchProductsStart({ filterType }, categories));
    }
  }, [dispatch, filterType, categories]);

  if (!Array.isArray(categorizedProducts)) return null;

  if (categorizedProducts.length < 1) {
    return (
      <section className="htc__product__area shop__page ptb--130 bg__white">
        <div className="container">
          <div className="htc__product__container">
            <ShopCategories />

            <div className="row  text-center  pt-15 mt--80">
              <h3>:( Looks like</h3>
              <p>No products found at the moment.</p>
            </div>
          </div>
        </div>
      </section>
    );
  }

  const getCategoryGroupName = (category) => {
    if (!categories || !categories.data) {
      return category;
    }

    let groupName = category;
    let obj = categories.data.find(
      (o) => o.categoryName.toLowerCase() === category.toLowerCase()
    );

    if (obj === undefined || obj.categoryGroup === undefined) {
      return "";
    }

    groupName = "" + obj.categoryGroup + "  ";
    return groupName;
  };

  const handleLoadMore = () => {
    dispatch(
      fetchProductsStart({
        filterType,
        startAfterDoc: queryDoc,
        persistProducts: data,
      })
    );
  };

  const configLoadMore = {
    onLoadMoreEvt: handleLoadMore,
  };

  return (
    <section className="htc__product__area shop__page ptb--130 bg__white">
      <div className="container">
        <div className="htc__product__container">
          <ShopCategories />

          <div className="row">
            <div className="product__list another-product-style">
              {Array.isArray(categorizedProducts) &&
                categorizedProducts.length > 0 &&
                categorizedProducts.map((group, mainIndex) => {
                  const { products, category } = group;

                  return (
                    <div className="row">
                      <h2 key={mainIndex} className="category-heading">
                        {category === "" ? "not specified" : `${category}`}
                        <div className="group-name">
                          {getCategoryGroupName(category)}
                        </div>
                      </h2>

                      {products &&
                        products.map((product, idx) => {
                          const {
                            productThumbnail,
                            productName,
                            productPrice,
                          } = product;

                          if (
                            !productThumbnail ||
                            !productName ||
                            typeof productPrice === "undefined"
                          )
                            return null;

                          product.productCategory = category;
                          const configProduct = {
                            ...product,
                          };

                          return <ShopProduct key={idx} {...configProduct} />;
                        })}
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="row mt--60">
            <div className="col-md-12">
              <div className="htc__loadmore__btn">
                {!isLastPage && <LoadMore {...configLoadMore} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductResults;
