import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCartItems, selectCartTotal } from '../../../redux/Cart/cart.selectors';
import { createStructuredSelector } from 'reselect';

import { SiteConsumer } from '../../../context';
import Item from '../Item/Item';

const mapState = createStructuredSelector({
  cartItems: selectCartItems,
  total: selectCartTotal
});

function roundN(num,n){
  return parseFloat(Math.round(num * Math.pow(10, n)) /Math.pow(10,n)).toFixed(n);
}

const Checkout = ( props ) => {
  const history = useHistory();

  const { cartItems, total } = useSelector(mapState);

  const errMsg = 'You have no items in your cart.';

  useEffect( () =>{
  },[]);

  

return (
  <SiteConsumer> 
    {(param) => {      
    const {
        cartOpen,
        closeCart
    } = param;

  return (
      <div className={`shopping__cart ${cartOpen ? "shopping__cart__on": "" }`}>
        <div className="shopping__cart__inner">
            
            <div className="offsetmenu__close__btn">
                <span 
                    onClick={ () => closeCart() }
                ><i className="zmdi zmdi-close"></i></span>
            </div>

           
             {cartItems.length > 0 ? (
               <div>
                <div className="shp__cart__wrap">
                    <h2>Total Item(s)  { cartItems.length }</h2>
                    {cartItems.map((item, pos) => {
                        return (
                          <div key={pos}>
                            <Item {...item} />
                          </div>
                        );
                    })}

                </div>

                <ul className="shoping__total">
                    <li className="subtotal">Subtotal:</li>
                    <li className="total__price">R { roundN(total, 2) }</li>
                </ul>
                <ul className="shopping__btn">
                    <li><Link to="" onClick={ (e) => { e.preventDefault(); closeCart(); }}>Continue Shopping</Link></li>
                    <li className="shp__checkout"><Link to="" onClick={ (e) => { e.preventDefault(); closeCart(); history.push('/payment'); }} >Checkout</Link></li>
                </ul>
            </div>
           ) : (
            <p>{ errMsg } </p>
          )}
   
        </div>
      </div>

 
    )
  }}
 </SiteConsumer>
 );
}

export default Checkout;
