import shirtsTypes from './shirts.types';
import shirtType from './shirts.types';

const INITIAL_STATE = {
  shirts: [],
  shirt: {}
};

const shirtsReducer = (state=INITIAL_STATE, action) => {
  switch(action.type) {
    case shirtType.SET_SHIRTS:
      return {
        ...state,
        shirts: action.payload
      }
    case shirtsTypes.SET_SHIRT:
      return {
        ...state,
        shirt: action.payload
      }
    default:
      return state;
  }
};

export default shirtsReducer;