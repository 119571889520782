import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import onlineShoppingImg from '../assets/online-shopping.jpg';
import './Page.css';
import './contact.css';


export default class ContactPage extends Component {
    render() {
        return (
            <div className="wrapper fixed__footer">
                <div className="row">

                <div className="ht__bradcaump__area">
                    <div className="ht__bradcaump__wrap">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="bradcaump__inner text-center">
                                        <h2 className="bradcaump-title">Contact us</h2>
                                        <nav className="bradcaump-inner">
                                        <a className="breadcrumb-item" href="/">Home</a>
                                        <span className="brd-separetor">/</span>
                                        <span className="breadcrumb-item active">Contact us</span>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="htc__contact__area ptb--120 bg__white">
                 <div className="container">
                    <div className="row">
                
                        
                    <div className="col-md-7 col-lg-7 col-sm-12 col-xs-12">
                        
                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">

                        <div className="htc__contact__container">
                            <div className="htc__contact__address">
                                <h2 className="contact__title">contact info</h2>
                                <div className="contact__address__inner">
                                   
                                    <div className="single__contact__address">
                                        <div className="contact__icon">
                                            <span className="ti-location-pin"></span>
                                        </div>
                                        <div className="contact__details">
                                            <p>Location: <br /> Johanessburg, South Africa</p>
                                        </div>
                                    </div>

                                    <div className="single__contact__address">
                                        <div className="contact__icon">
                                            <span className="zmdi zmdi-whatsapp"></span>
                                        </div>
                                        <div className="contact__details">
                                            <p>WhatsApp : <br />+27 (63) 448-1630 </p>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                         </div>

                         </div>

                         <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                            <div className="htc__contact__container">
                            <div className="htc__contact__address">

                                <div className="contact__address__inner">
                                   
                                   <div className="single__contact__address">
                                       <div className="contact__icon">
                                           <span className="ti-location-pin"></span>
                                       </div>
                                       <div className="contact__details">
                                           <p>Mail: <br /> info@anointedrepublic.co.za</p>
                                       </div>
                                   </div>

                                   <div className="single__contact__address">
                                       <div className="contact__icon">
                                           <span className="ti-mobile"></span>
                                       </div>
                                       <div className="contact__details">
                                           <p>Phone : <br /> +27 (63) 448-1630</p>
                                       </div>
                                   </div>
                                   
                               </div>
                            </div>

                            

                        <div className="form-output">
                            <p className="form-messege"></p>
                        </div>
                        
                        </div>
                    </div>
                
                     </div>

                     <div className="col-md-5 col-lg-5 col-sm-12 col-xs-12 smt-30 xmt-30">
                        <div className="banner-container">
                           <img src={ onlineShoppingImg } alt="OnlineShopping" />
                        </div>
                    </div>

                    </div>

                    </div>

            </section>

            </div>
        </div>
    )
 }
}
