import React from 'react';
import userIMG from './../../assets/user.png';

const UserProfile = props => {
  const { currentUser } = props;
  const { displayName, photoUrl } = currentUser;


  return (
    <div className="userProfile">
          
     
      <div className="product-categories-title">
          <h3> {displayName && displayName}  <img src={userIMG} /> </h3>
      </div>
    
    </div>
  );
}

export default UserProfile;