import React from 'react';

const FormInputArea = ({ handleChange, label, ...otherProps }) => {
  return (
    <div className="formRow">
      {label && (
        <label>
          {label}
         
        </label>
      )}

      { otherProps.sublabel &&
      
        <label className="highlight">{ otherProps.sublabel }</label>
      }

      <textarea rows="5" className="formInput" onChange={handleChange} {...otherProps} />
    </div>
  );
}

export default FormInputArea;