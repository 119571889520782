import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { signOutUserStart } from '../redux/User/user.actions';

import UserProfilePic from '../assets/user.png';
import './Page.css';

const mapState = (state) => ({
  currentUser: state.user.currentUser,
});

const UserAccountPage = props =>{

  const dispatch = useDispatch();
  const { currentUser  } = useSelector(mapState);

  const signOut = () => {
    dispatch(signOutUserStart());
  };

  return (
      <div className="container">
      <div className="row">
          <div className="col-10 mx-auto text-center text-title text-uppercase pt-5">
              <h1 className="display-3">
                  <img src={ UserProfilePic } />
              </h1>
              
              { currentUser && (
                <div className="secureSection">
                  <h3>Welcome back , { currentUser.displayName }</h3>
                  <div className="section__title section__title--2 text-center">
                    <p>You are currently logged in as  <b>{ currentUser.displayName }</b>, and you can logout by clicking logout button below.</p>
                  </div>
                  <div className="store__btn">
                      <Link className="left" to="/" onClick={ (e) => { e.preventDefault(); signOut();}}>Logout</Link>
                      <Link className="right" to="/" title="Home"><span className="ti-gear"></span>Home</Link>
                  </div>
                  <br/>
                  
                  </div>
              )}

              { !currentUser && (
                <div className="unsecureSection">
                    <h3>Welcome guest, We love seeing you here <span className="ti-heart love"></span></h3>
                    <div className="actions col-md-8 col-lg-12 col-sm-12 col-xs-12">
                      <div className="col-md-12  col-sm-12 col-xs-12 ">
                          <div className="store__btn">
                            <Link className="left" to="/login" title="Sign In"><span className="ti-lock"></span>Click to Sign In</Link>
                          </div>
                        </div>
                        <div className="col-md-12  col-sm-12 col-xs-12">
                           <div className="store__btn">
                            <Link className="right" to="/login" title="Sign Up"><span className="ti-user"></span>Click to Sign Up</Link>
                           </div>
                        </div>
                    </div>
                  </div>
              )} 
          </div>
        
      </div>
  </div>
  )
}

UserAccountPage.defaultProps = {
  currentUser: null
};

export default UserAccountPage;