import { createSelector } from 'reselect';

export const selectCartData = state => state.cartData;

export const selectCartItems = createSelector(
  [selectCartData],
  cartData => cartData.cartItems
);

export const selectCartItemsCount = createSelector(
  [selectCartItems],
  cartItems =>
    cartItems.reduce(
      (quantity, cartItem) =>
        quantity + cartItem.quantity
      , 0)
);


export const selectCartTotal = createSelector(
 
  [selectCartItems],

  cartItems =>
    {
      let totalPrice = 0;
      cartItems.map( (item) => {
         
         if(item.OnSpecial) {
           totalPrice += parseFloat(item.productPrice);
         }else {
           totalPrice += ( parseFloat(item.productPrice) * item.quantity);
         }
        
      });
      
      return totalPrice;
    }
);