import React from 'react';
import Navbar  from '../components/Navbar/Navbar';
import Footer  from '../components/Footer/Footer';
import BodyOverlay from '../components/BodyOverlay/BodyOverlay';
import OffsetWrapper from '../components/OffsetWrapper/OffsetWrapper';
import ProductQuickviewWrapper from '../components/OffsetWrapper/ProductQuickviewWrapper';
import '../App.css';

const MainLayout = props => {
 

  return (
    <React.Fragment>
      <Navbar {...props} />

      <BodyOverlay />

      <OffsetWrapper />

      <div className="main">
        {props.children}
      </div>
      <Footer />

      <ProductQuickviewWrapper />

    </React.Fragment>
  );
};
  
  export default MainLayout;