
import React, {  useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  editCategoryGroupStart } from '../../../redux/CategoryGroups/category.group.actions';

import FormInput from '../../Forms/FormInput/index';
import FormInputArea from '../../Forms/FormInputArea/index';
import { Button } from 'react-bootstrap';
import { appEnvConfig } from '../../../yoco/config';

import '../style.css';

const mapState = state => ({
    group: state.categoryGroupsData.group
});

const EditCategoryGroupWrapper = props => {
    
    const dispatch = useDispatch();
   
    const [ title, setTitle] = useState('');
    //const [ photo, setPhoto ] = useState('');
    const [ description, setDescription ] = useState('');

    const [thumbnailFile, setThumbnailFile] = useState('');
    const [thumbnailActualFile, setThumbnailActualFile] = useState('');
    
    const [ message, setMessage ] = useState('');
    const { group } = useSelector(mapState);
    const {
        ID
    } =  group;


    useEffect(() => {
       if( group )
        initialSet( group);
    },[ group]);

    const initialSet = (group) => {
       
        setTitle(group.Title);
        setDescription(group.Description);
      //  setPhoto(group.Photo);
        setThumbnailFile('');
        setThumbnailActualFile('');
    
    };

    const resetForm = () => {
       
      setTitle('');
  //    setPhoto('');
      setDescription('');
    };

  
    const handleSubmit = e => {
      e.preventDefault();

      let modifiedCategoryGroup ={
        ID,
        title,
        description
      }
      
      if(thumbnailActualFile === '') {
        console.log(modifiedCategoryGroup);
        // dispatch( editCategoryGroupStart(
        //     modifiedCategoryGroup
        // ));
        resetForm();
        return;
      }

      if(thumbnailActualFile !== '') {
        console.log('NEED TO CALL IMAGE UPLOADE URL THING');
        
        let axios = require('axios');
        let FormData = require('form-data');
    
        let data = new FormData();
        data.append('fileDoc', thumbnailActualFile);
        data.append('appid', 'AnoRepw01');

        let config = {
        method: 'post',
        url: appEnvConfig.uploadServerEndpoint ,
        data : data
        };

        axios(config).then(function (response) {

            if (response == null || response.data == null || !response.data.ok) {
                alert("group create failed: image could not be uploaded, pleaset try again");
            }
            let groupThumbnail = appEnvConfig.fileServerDomain + response.data.linkURL;
          
           // setPhoto(groupThumbnail);
            modifiedCategoryGroup.Photo = groupThumbnail;

               dispatch(
                editCategoryGroupStart(modifiedCategoryGroup)
              );
            resetForm();
        })
        .catch(function (error) {
             console.log(error);
        });
      }

    };
  
   return (             
    <div className="modal fade" id="categorytGroupEditModal" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal__container" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>

                <div className="modal-body">
                    <div className="modal-product">
                        
                        <form onSubmit={ handleSubmit }>
                        <div className="product-info">
                            <h1>Category Group Update</h1>
                            

                            <div className="quick-desc">
                                <FormInput
                                label="Name"
                                type="text"
                                value={title}
                                handleChange={e => setTitle(e.target.value)}
                                /> 
                            </div>

                            <div className="quick-desc">
                                <FormInputArea
                                label="Description"
                                type="textarea"
                                rows="2"
                                value={description}
                                handleChange={e => setDescription(e.target.value)}
                                />
                            </div>
                      
                            <div className="htc__submit__btn">
                                <Button className="btn link" onClick={ handleSubmit } data-dismiss="modal" aria-label="Close">
                                    Update Category Group
                                </Button>
                            </div>
 
                        </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>   

 )

  
};

export default EditCategoryGroupWrapper;
