import slidesTypes from './home.slides.types';
import Axios from 'axios';

export const fetchSlidesStart = (token) => async (dispatch, getState) => {
  

  dispatch({ 
    type: slidesTypes.FETCH_SLIDES_START,
    loading: true
  });

  try {
    

    const headers = {
        headers:{
          'Authorization': token,
          'appid': process.env.REACT_APP_ID,
        }
      };

      const response = await Axios.get(process.env.REACT_APP_MINI_SLIDES_ENDPOINT);
     
      if(!response.data.ok && (response.data.code === 'InvalidToken' || response.data.code==='NoAuthProvided')) {
        //get token dispatch
        dispatch({
          type: slidesTypes.FETCH_SLIDES_FAILURES,
          error: response.data.code
        });
      }

      if(response.data.ok) {

        dispatch({
          type: slidesTypes.FETCH_SLIDES_SUCCESS,
          payload: response.data.result
        });
      }


  } catch(error) {
    console.log(error);
    dispatch({
      type: slidesTypes.FETCH_SLIDES_FAILURES,
      error
    });
  }
  
};
