import React from 'react'
import SearchResults from '../../components/SearchResults/index';

const SearchResultPage = ( props) =>{

    let bgStyle = {
        background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(209,185,190,0.33237044817927175) 100%, rgba(0,212,255,1) 100%)'
    };

    let txtStyle = {
       color: '#ffffff'
    };

    return (

        <div className="wrapper fixed__footer">
                <div className="row">

                <div className="ht__bradcaump__area" style={ bgStyle } >
                    <div className="ht__bradcaump__wrap">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="bradcaump__inner text-center">
                                        <h2 className="bradcaump-title" style={ txtStyle } >Search Results</h2>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              

                <SearchResults />
            </div>
        </div>
    )  
}

export default SearchResultPage;