import appAuthTypes from './app.auth.types';
import Axios from 'axios';

export const fetchTokenStart = () => async (dispatch, getState) => {
  
  dispatch({ 
    type: appAuthTypes.FETCH_TOKEN_START,
    loading: true
  });

  try {

    const options = {
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      }
    };

    const data = {
        "secret": process.env.REACT_APP_AUTH_SECRET,
        "appid": process.env.REACT_APP_ID,
        "username": process.env.REACT_APP_AUTH_USERNAME,
        "password": process.env.REACT_APP_AUTH_PASSWORD
    };
    const response = await Axios.post(process.env.REACT_APP_AUTH_TOKEN_ENDPOINT, data, options );
   // console.log(response);

    if(response.data.ok) {
      console.log("response data is ok = true");
      dispatch({
        type: appAuthTypes.FETCH_TOKEN_SUCCESS,
        payload: response.data.token
      });
    }
    if(!response.data.ok) {
      console.log("must put up a banner that app is out of service, means ok = false ");
    }

  } catch(error) {
    console.log(error);
    dispatch({
      type: appAuthTypes.FETCH_TOKEN_FAILURES,
      error
    });
  }
};

export const fetchTokenValidateStart = (token) => async (dispatch, getState) => {
  
    dispatch({ 
      type: appAuthTypes.FETCH_VALIDATE_TOKEN_START,
      loading: true
    });
  
    try {
  
      console.log("check if we have a token saved on state");
      console.log("token = "  + token);

      const headers = {
        'Authorization': 'Bearer ' + token,
        'appid': process.env.REACT_APP_ID
      };

      const response = await Axios.get(process.env.REACT_APP_AUTH_VALIDATE_ENDPOINT, headers );
      console.log(response);

        if(!response.data.ok && (response.data.code === 'InvalidToken' || response.data.code==='NoAuthProvided')) {
          //dispatch get token
          console.log("must dispatch get token");
          dispatch(fetchTokenStart());
        }
        if(response.data.ok) {
          dispatch({
            type: appAuthTypes.FETCH_VALIDATE_TOKEN_SUCCESS,
            payload: response.data.token
          });

          dispatch({
            type: appAuthTypes.SET_TOKEN,
            payload: response.data.token
          });
        }
  
    } catch(error) {
      console.log(error);
      dispatch({
        type: appAuthTypes.FETCH_VALIDATE_TOKEN_FAILURES,
        error
      });
    }
  };
  
