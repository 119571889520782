import colorTypes from './color.types';

export const addColorStart = data => ({
  type: colorTypes.ADD_NEW_COLOR_START,
  payload: data
});

export const fetchColorsStart = (filters={}) => ({
  type: colorTypes.FETCH_COLORS_START,
  payload: filters
});

export const setColors = data => ({
  type: colorTypes.SET_COLORS,
  payload: data
});

export const deleteColorStart = colorID => ({
  type: colorTypes.DELETE_COLOR_START,
  payload: colorID
});

export const fetchColorStart = colorID => ({
  type: colorTypes.FETCH_COLOR_START,
  payload: colorID
});

export const setColor = data => ({
  type: colorTypes.SET_COLOR,
  payload: data
});

//---
export const editColorStart = data => ({
  type: colorTypes.EDIT_COLOR_START,
  payload: data
});
export const setEditColor = data => ({
  type: colorTypes.SET_EDIT_COLOR,
  payload: data
});