import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProductsOnSpecialStart,
  setProduct,
} from "../../redux/Products/products.actions";
import { Link } from "react-router-dom";
import LoadMore from "../../components/LoadMore/index";
import { Button } from "react-bootstrap";
import "./styles.css";

const mapState = ({ productsData }) => ({
  products: productsData.productSpecials,
});

const AdminSpecial = (props) => {
  const dispatch = useDispatch();
  const [currentDocumentID, setCurrentDocumentID] = useState("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { products } = useSelector(mapState);
  const { data, queryDoc, isLastPage } = products;

  useEffect(() => {
    dispatch(fetchProductsOnSpecialStart());
  }, [dispatch]);

  const handleLoadMore = () => {
    dispatch(
      fetchProductsOnSpecialStart({
        startAfterDoc: queryDoc,
        persistProducts: data,
      })
    );
  };

  const handleDeletePopup = (product) => {
    if (!product) return;

    dispatch(setProduct(product));
  };

  const handleEditProduct = (product) => {
    if (!product) return;

    dispatch(setProduct(product));
  };

  const configLoadMore = {
    onLoadMoreEvt: handleLoadMore,
  };

  return (
    <section className="our-checkout-area bg__white">
      <div className="container">
        <div className="row main-table-row">
          <div className="col-md-9">
            <div className="product-style-tab">
              <div className="product-tab-list">
                <ul className="tab-style" role="tablist">
                  <li className="active">
                    <Link to="#home1" data-toggle="tab"></Link>
                    <div className="tab-menu-text">
                      <div className="htc__login__btn">
                        <li>
                          <Link
                            data-toggle="modal"
                            data-target="#productAddModal"
                            title="Quick View"
                            className="quick-view modal-view detail-link"
                            to="#/addproduct"
                          >
                            Add Product on Special
                          </Link>
                        </li>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="tab-content another-product-style jump wishlist-area">
                <div className="">
                  <div className="row main-table-row">
                    <div className="">
                      <div className="wishlist-table table-responsive">
                        <table>
                          <tbody>
                            <tr key={"01"} className="table-header">
                              <td>Thumbnail</td>
                              <td>Name</td>
                              <td>Cost</td>
                              <td>Sale Price</td>
                              <td>Remove</td>
                              <td>Update</td>
                            </tr>

                            {Array.isArray(data) &&
                              data.length > 0 &&
                              data.map((product, index) => {
                                const {
                                  productName,
                                  productThumbnail,
                                  productPrice,
                                  productSalePrice,
                                  documentID,
                                } = product;

                                return (
                                  <tr key={index}>
                                    <td>
                                      <img
                                        className="thumb"
                                        src={productThumbnail}
                                        alt={productName}
                                      />
                                    </td>
                                    <td>{productName}</td>
                                    <td>R {productPrice}</td>
                                    <td>R {productSalePrice}</td>
                                    <td>
                                      <Button
                                        id={documentID}
                                        onClick={() =>
                                          handleDeletePopup(product)
                                        }
                                        data-toggle="modal"
                                        data-target="#deleteProductModal"
                                        title="Remove Product"
                                        className="quick-view modal-view detail-link"
                                        to="#/deleteproduct"
                                      >
                                        Delete
                                      </Button>
                                    </td>
                                    <td>
                                      <Button
                                        id={documentID}
                                        onClick={() =>
                                          handleEditProduct(product)
                                        }
                                        data-toggle="modal"
                                        data-target="#productEditModal"
                                        title="Update Product"
                                        className="quick-view modal-view detail-link"
                                        to="#/editproduct"
                                      >
                                        Edit
                                      </Button>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>

                        <table border="0" cellPadding="10" cellSpacing="0">
                          <tbody>
                            <tr>
                              <td>
                                {!isLastPage && (
                                  <LoadMore {...configLoadMore} />
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdminSpecial;
