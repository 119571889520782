import cartspecialsCheck  from "./cart.specials.util";

export const existingCartItem = ({
  prevCartItems,
  nextCartItem
}) => {
  return prevCartItems.find(
    cartItem => cartItem.documentID === nextCartItem.documentID 
  );
};

export const itemsOnSpecial = ({
  prevCartItems
}) => {
  return prevCartItems.find(
    cartItem => cartItem.OnSpecial === true
  );
};

export const docIdOnSpecial = ({
  prevCartItems, 
  docid
}) => {
  return prevCartItems.find(
    cartItem => cartItem.documentID === docid 
  );
};

export const handleAddToCart = ({
  prevCartItems,
  nextCartItem
}) => {
  const quantityIncrement = 1;
  const cartItemExists = existingCartItem({ prevCartItems, nextCartItem });
  const SPECIAL_ITEM = "t-shirts";

  //-------------------------------------------------------------------
  // begin special calculation
  //-------------------------------------------------------------------
 

  let specialItemes = [];

  let cartItems = [];
  prevCartItems.map( (product) => {
   
    if(product.productCategory.toLowerCase() === SPECIAL_ITEM) {
      product.OnSpecial = true;
      product.special ={
        name: 'combo special',
        description: 'On special: buy 2 for R 399'
      };
      specialItemes.push(product);
    }
    cartItems.push(product);
  });

 
  if(nextCartItem.productCategory.toLowerCase() === SPECIAL_ITEM ) {
 
    nextCartItem.OnSpecial = true;
    nextCartItem.special ={
      name: 'combo special',
      description: 'On special: buy 2 for R 399'
    };
   // specialItemes.push(nextCartItem);
  }


  if(nextCartItem.OnSpecial) 
     cartspecialsCheck(specialItemes, (cartItemExists ? cartItemExists: nextCartItem),   (cartItemExists ? "H": "V") );
  

  //-------------------------------------------------------------------
  // end of special calculation
  //-------------------------------------------------------------------


  if (cartItemExists) {
    return prevCartItems.map(cartItem =>
      cartItem.documentID === nextCartItem.documentID
        ? {
          ...cartItem,
          quantity: cartItem.quantity + quantityIncrement
        } : cartItem
    );
  }

  return [
    ...prevCartItems,
    {
      ...nextCartItem,
      quantity: quantityIncrement
    }
  ];
};

export const handleRemoveCartItem = ({
  prevCartItems,
  cartItemToRemove
}) => {

  let itemOnSpecial = false;
  let specialItems = [];

  prevCartItems.map( (item) => {

    if(item.documentID === cartItemToRemove.documentID && item.OnSpecial === true) { 
      itemOnSpecial = true;
    }
  });

  prevCartItems.map( (item) => {

    if(item.documentID !== cartItemToRemove.documentID && item.OnSpecial === true) { 
      specialItems.push(item);
    }
  });

  // process product removal
  if(itemOnSpecial  && specialItems.length > 0) {
    
    cartspecialsCheck(specialItems, cartItemToRemove,  "REMOVE" );
  }

  return prevCartItems.filter(item => item.documentID !== cartItemToRemove.documentID);
}

export const handleReduceCartItem = ({
  prevCartItems,
  cartItemToReduce
}) => {
  const existingCartItem = prevCartItems.find(cartItem =>
    cartItem.documentID === cartItemToReduce.documentID);

  if (existingCartItem.quantity === 1) {
    return prevCartItems.filter(
      cartItem => cartItem.documentID !== existingCartItem.documentID
    );
  }

  return prevCartItems.map(cartItem =>
    cartItem.documentID === existingCartItem.documentID ?
    {
      ...cartItem,
      quantity: cartItem.quantity - 1
    } : cartItem)
};