
import React, {  useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  editColorStart } from '../../../redux/ProductColors/color.actions';

import FormInput from '../../Forms/FormInput/index';
import { Button } from 'react-bootstrap';

import '../style.css';

const mapState = state => ({
    color: state.colorsData.color
});

const ColorEditModal = props => {
    
    const dispatch = useDispatch();
   
    const [ value, setValue] = useState('');
    const [ label, setLabel ] = useState('');
    const [ message, setMessage ] = useState('');
    const { color } = useSelector(mapState);
    const {
        documentID
    } =  color;


    useEffect(() => {
       if( color )
        initialSet( color);
    },[ color]);

    const initialSet = (color) => {
       
        setValue(color.value);
        setLabel(color.label);
    
    };

    const resetForm = () => {
       
      setValue('');
      setLabel('');
    };

  
    const handleSubmit = e => {
      e.preventDefault();

      let modified ={
        documentID,
        value,
        label
      }
      
     
      dispatch(
        editColorStart(modified)
      );
      resetForm();


    };
  
   return (             
    <div className="modal fade" id="colorEditModal" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal__container" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>

                <div className="modal-body">
                    <div className="modal-product">
                        
                        <form onSubmit={ handleSubmit }>
                        <div className="product-info">
                            <h1>Product Color Update</h1>
                            

                            <div className="quick-desc">
                                <FormInput
                                label="Name"
                                type="text"
                                value={value}
                                handleChange={e => setValue(e.target.value)}
                                /> 
                            </div>

                            <div className="quick-desc">
                                <FormInput
                                label="Label"
                                type="text"
                               
                                value={label}
                                handleChange={e => setLabel(e.target.value)}
                                />
                            </div>
                      
                            <div className="htc__submit__btn">
                                <Button className="btn link" onClick={ handleSubmit } data-dismiss="modal" aria-label="Close">
                                    Update  Color
                                </Button>
                            </div>
 
                        </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>   

 )

  
};

export default ColorEditModal;
