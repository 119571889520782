import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';     
import ProductCategory from '../ProductCategories/ProductCategory';
import ProductTopCarouselCol from './ProductTopCarouselCol';
import { fetchHomeProductStart } from '../../redux/Products/products.actions';
import { fetchCategoriesStart } from '../../redux/Product-categories/categories.actions';
import { fetchCategoryGroupStart } from '../../redux/CategoryGroups/category.group.actions';

const ProductList = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCategoryGroupStart());
        dispatch(fetchCategoriesStart());
        dispatch(fetchHomeProductStart(''));
       
    }, [dispatch]);
 

    return (
        
        <section className="htc__product__area bg__white">
        <div className="container">
            <div className="row">
                
                <ProductCategory />
                
                <div className="col-md-9">
                    <div className="product-style-tab">
                        
                        <div className="product-tab-list">
                            <ul className="tab-style" role="tablist">
                                <li className="active">
                                    <a href="#home1" data-toggle="tab">
                                        <div className="tab-menu-text">
                                            <h4>Anointed Republic </h4>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="tab-content another-product-style jump">
                            <div className="tab-pane active" id="home1">
                                <div className="row">
                                    
                                    <ProductTopCarouselCol columns= {3} />

                                </div>
                            </div>
                        
                        </div>

                    </div>
                </div>
            </div>
        </div>
        </section>
      )

}

export default ProductList;
