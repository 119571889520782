import { auth } from '../../firebase/util';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import { setShirts, setShirt } from './shirts.actions';
import { handleFetchShirts , handleFetchShirt } from './shirts.helpers';
import shirtsTypes from './shirts.types';


/**
 * fetchProducts
 */
export function* fetchShirts({ payload }) {
  try {
    const shirts = yield handleFetchShirts(payload);
    yield put(
      setShirts(shirts)
    );

  } catch (err) {
  }
}

export function* onFetchShirtsStart() {
  yield takeLatest(shirtsTypes.FETCH_SHIRTS_START, fetchShirts);
}


/**
 * fetchProduct
 */
export function* fetchShirt({ payload }) {
  try {
    const shirt = yield handleFetchShirt(payload);
    yield put(
      setShirt(shirt)
    );

  } catch (err) {
  }
}

export function* onFetchShirtStart() {
  yield takeLatest(shirtsTypes.FETCH_SHIRT_START, fetchShirt);
}


/**
 * productsSagas
 */
export default function* shirtsSagas() {
  yield all([
    call(onFetchShirtsStart),
    call(onFetchShirtStart),
  ])
}