import { firestore } from '../../firebase/util';

/**
 * handleFetchProducts
 */
export const handleFetchShirts = ({ filterType, startAfterDoc, persistShirts=[] }) => {
  return new Promise((resolve, reject) => {
   
    const pageSize = 6;
    let ref = firestore.collection('products').orderBy('createdDate').limit(pageSize);

    if (filterType) ref = ref.where('productCategory', '==', filterType);
    if (startAfterDoc) ref = ref.startAfter(startAfterDoc);

    ref
      .get()
      .then(snapshot => {
        const totalCount = snapshot.size;

        const data = [
          ...persistShirts,
          ...snapshot.docs.map(doc => {
            return {
              ...doc.data(),
              documentID: doc.id
            }
          })
        ];

        resolve({
          data,
          queryDoc: snapshot.docs[totalCount - 1],
          isLastPage: totalCount < 1
        });
      })
      .catch(err => {
        reject(err);
      })
  })
}

/**
 * handleFetchProduct
 */
export const handleFetchShirt = (shirtID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('products')
      .doc(shirtID)
      .get()
      .then(snapshot => {

        if (snapshot.exists) {
          resolve({
            ...snapshot.data(),
            documentID: shirtID
          });
        }
      })
      .catch(err => {
        reject(err);
      })
  })
}