import React, { Component } from 'react';

const SiteContext = React.createContext();
//Provider
//Consumer

class SiteProvider extends Component {

    state = {
       
        mobileMenuOpen: false,
        filterOpen: false,
        searchOpen: false,
        cartOpen: false,
        settingsOpen: false,
        selectedProduct: {}
    }

    // modal functions

     // ------- search
    openSearchModal = () => {
        this.setState( () => {
            return { searchOpen: true}
        });
    }

    closeSearchModal = () => {
        this.setState( () => {
            return { searchOpen : false};
        });
    }

    // ------- cart
    openCartModal = () => {
        this.setState( () => {
            return { cartOpen: true}
        });
    }

    closeCartModal = () => {
        this.setState( () => {
            return { cartOpen : false};
        });
    }

    // ------- settings
    openSettingsModal = () => {
        this.setState( () => {
            return { settingsOpen: true}
        });
    }

    closeSettingsModal = () => {
        this.setState( () => {
            return { settingsOpen : false};
        });
    }

     // ------- product filter
     openFilterModal = () => {
        this.setState( () => {
            return { filterOpen: true}
        });
    }

    closeFilterModal = () => {
        this.setState( () => {
            return { filterOpen : false};
        });
    }

     // ------- mobile menu
     toogleMobileMenu = (paramMobileMenuOpen) => {
        this.setState( () => {
            if(paramMobileMenuOpen) {
             return { mobileMenuOpen: false}
            }else {
             return { mobileMenuOpen: true}
            }
        });
    }

    // --------- selected product id
    setSelectedProduct = ( product) => {
        this.setState( () => {
            return { selectedProduct : product };
        });

    }

    render() {
        return (
          <SiteContext.Provider value={{
            ...this.state,
         
            openSearch: this.openSearchModal,
            closeSearch: this.closeSearchModal,
            openCart: this.openCartModal,
            closeCart: this.closeCartModal,
            openSettings: this.openSettingsModal,
            closeSettings: this.closeSettingsModal,
            openFilter: this.openFilterModal,
            closeFilter: this.closeFilterModal,
            toogleMobileMenu: this.toogleMobileMenu,

            setSelectedProduct: this.setSelectedProduct
           }} >
            {this.props.children}
          </SiteContext.Provider>
        )
    }
}

const SiteConsumer = SiteContext.Consumer;
export { SiteConsumer, SiteProvider };