import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addArticleStart, fetchArticlesStart, deleteArticleStart } from '../../redux/articles/articles.actions';

import { Link } from 'react-router-dom';
import LoadMore from '../../components/LoadMore/index';
import { Button }  from 'react-bootstrap';
import './styles.css';

const mapState = ({ articlesData }) => ({
    articles: articlesData.articles
});

const ArticlesAdmin = (props) => {
    const dispatch = useDispatch();
    const { articles } = useSelector(mapState);
   
    const [ hideModal, setHideModal] = useState(true);
    const [ articleCategory, setArticleCategory] = useState('latest');
    const [ articleName, setArticleName] = useState('');
    const [ articleThumbnail, setArticleThumbnail] = useState('');
    const [ articleDesc, setArticleDesc] = useState('');
    
    const { data, queryDoc, isLastPage } = articles;
   

    useEffect(() => {
      dispatch(
        fetchArticlesStart()
      );

    }, []);
  
    const toggleModal = () => setHideModal(!hideModal);
  
    const configModal = {
      hideModal,
      toggleModal
    };
  
    const resetForm = () => {
      setHideModal(true);
      setArticleCategory('mens');
      setArticleName('');
      setArticleThumbnail('');
    
      setArticleDesc('');
    };
  
    const handleSubmit = e => {
      e.preventDefault();
  
      dispatch(
        addArticleStart({
          articleCategory,
          articleName,
          articleThumbnail,
          articleDesc,
        })
      );
      resetForm();
    };
  
    const handleLoadMore = () => {
      dispatch(
        fetchArticlesStart({
          startAfterDoc: queryDoc,
          persistArticles: data
        })
      );
    };
  
    const configLoadMore = {
      onLoadMoreEvt: handleLoadMore,
    };

    return (    
        <section className="our-checkout-area bg__white">
        <div className="container">
            <div className="row main-table-row">

              <div className="col-md-9">
                <div className="product-style-tab">
                    
                    <div className="product-tab-list">
                        
                        <ul className="tab-style" role="tablist">
                            <li className="active">
                                <Link to="#home1" data-toggle="tab">
                                    <div className="tab-menu-text">
                                        <div className="htc__login__btn">
                                            <li>
                                            <Link data-toggle="modal" data-target="#articleModal" title="Quick View" className="quick-view modal-view detail-link" to="#/addarticle">Add article</Link>
                                            <Link data-toggle="modal" data-target="#categoryModal" title="Quick View" className="quick-view modal-view detail-link" to="#/addcatogory">Add Category</Link>
                                            </li>

                                            <li></li>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                            
                        </ul>
                    </div>

                    <div className="tab-content another-article-style jump wishlist-area">
                        <div className="">
                        <div className="row">
                            <div className="">

                           <div className="wishlist-table table-responsive">
                            <table>
                                <tbody>
                                {(Array.isArray(data) && data.length > 0) && data.map((article, index) => {
                                    const {
                                        articleName,
                                        articleThumbnail,
                            
                                        documentID
                                    } = article;

                                    return (
                                        <tr key={index}>
                                        <td>
                                            <img className="thumb" src={articleThumbnail} />
                                        </td>
                                        <td>
                                            {articleName}
                                        </td>
                                        <td>
                                           
                                        </td>
                                        <td>
                                            <Button onClick={() => dispatch(deleteArticleStart(documentID))}>
                                             Delete
                                            </Button>
                                        </td>
                                        </tr>
                                    )
                                    })}
                                </tbody>
                                </table>

                                <table border="0" cellPadding="10" cellSpacing="0">
                                    <tbody>
                                        <tr>
                                        <td>
                                            {!isLastPage && (
                                            <LoadMore {...configLoadMore} />
                                            )}
                                        </td>
                                        </tr>
                                    </tbody>
                                </table>
                             </div>
                            </div>

                            </div>
                        </div>
                    
                    </div>

                </div>
            </div>

           
       </div>
       </div>
       </section>
    );
}

 
export default ArticlesAdmin;