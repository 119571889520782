import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { checkUserSession } from './redux/User/user.actions';
import { fetchTokenValidateStart, fetchTokenStart } from './redux/AppAuth/app.auth.actions';


import './App.css';

//components
import AdminToolbar from './components/AminToolbar/AdminToolbar';

// layouts
import MainLayout from './Layouts/MainLayout';
import AdminLayout from './Layouts/AdminLayout';

import HomePage from './pages/HomePage';
import ProductDetailsPage from './pages/ProductDetailsPage'
import DefaultPage from './pages/DefaultPage';
import ShopPage from './pages/ShopPage';

//hoc
import WithAuth from './hoc/withAuth';
import WithAdminAuth from './hoc/withAuthAdmin';

// pages
import RecoveryPage from './pages/RecoveryPage';
import LoginRegisterPage from './pages/LoginRegisterPage';
import RegisterPage from './pages/RegisterPage';
import Dashboard from './pages/Dashboard';
import UserAccountPage from './pages/UserAccountPage';
import CheckoutPage from './pages/CheckoutPage';
import PaymentPage from './pages/PaymentPage';
import ArticlesAdmin from './pages/Admin/ArticlesAdmin';
import PolicyPrivacyPag from './/pages/PolicyPrivacyPag';
import PolicyReturnsPage from './pages/PolicyReturnsPage';
import PolicyDeliveryPage from './pages/PolicyDeliveryPage';
import ContactPage from './pages/ContactPage';
import AboutPage from './pages/About';
import SearchResultsPage from './pages/search-results';

//admin
import Admin from './pages/Admin/Admin';
import AdminSpecial from './pages/Admin/AdminSpecial';
import AdminSlide from './pages/Admin/AdminSlide';
import AdminCategory from './pages/Admin/AdminCategory';
import AdminCategoryGroup from './pages/Admin/AdminCategoryGroup';
import AdminProductSizes from './pages/Admin/AdminProductSizes';
import AdminProductColors from './pages/Admin/AdminProductColors';

// const mapState = ({ appAuthData }) => ({
//   token: appAuthData.token
// });

const App = props => {

  const dispatch = useDispatch();
  //const { token } = useSelector(mapState);

  //const [ authState ]  = useSelector( (state) => [ state.appAuthData ]);

    useEffect(() => {
      dispatch(fetchTokenStart());
      dispatch(checkUserSession());
    });

    // if(state.loading) {
    //   return(<h1>loading</h1>)
    // }

    return (
      <React.Fragment>  
        
         <AdminToolbar />
          <Switch>
            <Route exact path="/" render={ () => (
              <MainLayout >
                <HomePage ></HomePage>
              </MainLayout>
              )}></Route>

            <Route exact path="/shop" render={ () => (
              <MainLayout>
                <ShopPage></ShopPage>
              </MainLayout>
              )}></Route>

            <Route path="/shop/:filterType" render={ () => (
              <MainLayout>
                <ShopPage/>
              </MainLayout>
              )}></Route>

           <Route path="/search-results/:keywords" render = { () => (
                <MainLayout >
                  <SearchResultsPage />
                </MainLayout>
              )} 
            />

            <Route path="/product/:productID" render={ () => (
              <MainLayout>
                <ProductDetailsPage />
              </MainLayout>
              )}></Route>

            <Route path="/checkout" render={ () => (
              <MainLayout >
                <CheckoutPage></CheckoutPage>
              </MainLayout>
              )}></Route>

            <Route path="/user/account" render={ () => (
              <MainLayout >
                <UserAccountPage ></UserAccountPage>
              </MainLayout>
              )}></Route>

            <Route path="/recovery" render={ () => (
              <MainLayout >
                <RecoveryPage ></RecoveryPage>
              </MainLayout>
              )}>
              </Route>

              <Route path="/payment" render={ () => (
              <MainLayout >
                <PaymentPage ></PaymentPage>
              </MainLayout>
              )}>
              </Route>

            <Route path="/login" render={ () => (
                <MainLayout  >
                  <LoginRegisterPage></LoginRegisterPage>
                </MainLayout>
              
              )}></Route>
          
            <Route path="/register" render = { () => (
                <MainLayout >
                  <RegisterPage></RegisterPage>
                </MainLayout>
              )} 
            />

      
            <Route path="/dashboard" render={ () =>  (
                <WithAuth>
                  <MainLayout  >
                    <Dashboard />
                  </MainLayout>
                </WithAuth>
              )} />

            <Route  exact path="/admin" render={ () => (
              <WithAdminAuth>
                <AdminLayout>
                  <Admin />
                </AdminLayout>
              </WithAdminAuth>
            )} />

          <Route path="/admin/specials" render={ () => (
              <WithAdminAuth>
                <AdminLayout>
                  <AdminSpecial />
                </AdminLayout>
              </WithAdminAuth>
            )} />


            <Route path="/admin/categories" render={ () => (
              <WithAdminAuth>
                <AdminLayout>
                  <AdminCategory />
                </AdminLayout>
              </WithAdminAuth>
            )} />

          <Route path="/admin/category-groups" render={ () => (
              <WithAdminAuth>
                <AdminLayout>
                  <AdminCategoryGroup />
                </AdminLayout>
              </WithAdminAuth>
            )} />

          <Route path="/admin/product-sizes" render={ () => (
            <WithAdminAuth>
              <AdminLayout>
                <AdminProductSizes />
              </AdminLayout>
            </WithAdminAuth>
          )}
          />

        <Route path="/admin/product-colors" render={ () => (
            <WithAdminAuth>
              <AdminLayout>
                <AdminProductColors />
              </AdminLayout>
            </WithAdminAuth>
          )}
          />

           <Route path="/admin/slides" render={ () => (
              <WithAdminAuth>
                <AdminLayout>
                  <AdminSlide />
                </AdminLayout>
              </WithAdminAuth>
            )} />


          <Route path="/admin/banner" render={ () => (
              <WithAdminAuth>
                <AdminLayout>
                  <Admin />
                </AdminLayout>
              </WithAdminAuth>
            )} />

          <Route path="/admin/pics" render={ () => (
              <WithAdminAuth>
                <AdminLayout>
                  <ArticlesAdmin />
                </AdminLayout>
              </WithAdminAuth>
            )} />


          <Route path="/policy/returns" render = { () => (
                <MainLayout >
                  <PolicyReturnsPage />
                </MainLayout>
              )} 
            />

<         Route path="/policy/privacy" render = { () => (
                <MainLayout >
                  <PolicyPrivacyPag />
                </MainLayout>
              )} 
            />
          <Route path="/policy/delivery" render = { () => (
                <MainLayout >
                  <PolicyDeliveryPage />
                </MainLayout>
              )} 
            />

          <Route path="/contact-us" render = { () => (
                <MainLayout >
                  <ContactPage />
                </MainLayout>
              )} 
            />

          <Route path="/about-us" render = { () => (
                <MainLayout >
                  <AboutPage />
                </MainLayout>
              )} 
            />
            <Route  component={ DefaultPage }></Route>

          </Switch>

        </React.Fragment>
      )
    
    }


export default App;
