import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./styles.css";

const mapState = ({ categoriesData, categoryGroupsData }) => ({
  groups: categoryGroupsData.groups,
  categories: categoriesData.categories,
  groupedCategories: categoriesData.groupedCategories,
});
const ProductCategory = () => {
  const { groupedCategories } = useSelector(mapState);
  const { groups } = useSelector(mapState);

  const [organisedGroupCategories, setOrganisedGroupCategories] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      Array.isArray(groups) &&
      groups.length > 0 &&
      Array.isArray(groupedCategories) &&
      groupedCategories.length > 0
    ) {
      let reoganisedGroups = [];
      groups.forEach((group) => {
        let obj = groupedCategories.find(
          (o) => o.key === group.title.toLowerCase()
        );
        reoganisedGroups.push(obj);
      });

      setOrganisedGroupCategories(reoganisedGroups);
    }
  }, [dispatch, groups, groupedCategories]);

  return (
    <div className="col-md-3">
      <div className="product-categories-all">
        <div className="product-categories-title">
          <h3>Product Categories</h3>
        </div>
        <div className="product-categories-menu">
          <ul className="main-list">
            <li key="0" value="">
              <Link to={`/shop/${""}`} className="main">
                Show All
              </Link>
            </li>

            {Array.isArray(organisedGroupCategories) &&
              organisedGroupCategories.length > 0 &&
              organisedGroupCategories.map((group, mainIndex) => {
                const { key, value } = group;
                return (
                  <li key={mainIndex} value={key}>
                    <Link
                      to={`/shop/${value.join(",").toLowerCase()}`}
                      className="main"
                      title={key}
                    >
                      {key === "undefined" ? "None" : key}
                    </Link>

                    <ul className="inner-list">
                      {Array.isArray(value) &&
                        value.length > 0 &&
                        value.map((category, innerIdx) => {
                          return (
                            <li key={innerIdx} value={category}>
                              <Link
                                to={`/shop/${category.toLowerCase()}`}
                                title={category}
                              >
                                {category === "undefined" ? "None" : category}
                              </Link>
                            </li>
                          );
                        })}
                    </ul>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProductCategory;
