import { firestore } from '../../firebase/util';

/**
 * handleAddProduct
 */
export const handleAddSlide = slide => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('slides')
      .doc()
      .set(slide)
      .then(() => {
        resolve();
      })
      .catch(err => {
        reject(err);
      })
  });
}

/**
 * handleFetchsides
 */
export const handleFetchSlides = ({ filterType, startAfterDoc, persistSlides=[] }) => {
  return new Promise((resolve, reject) => {


    let axios = require('axios');
    var config = {
    method: 'get',
    url: process.env.REACT_APP_MINI_SLIDES_ENDPOINT ,
    headers: { 
        'Content-Type': 'application/json'
        },
    };

    axios(config)
    .then(function (response) {
    
        console.log(response);
        const totalCount = response.data.size;

        const data = [
          response.data
        ]

        resolve({  data  });
    })
    .catch(function (error) {
      console.log(error);
      reject(error);
    });

    // const pageSize = 10;
    // let ref = firestore.collection('slides').orderBy('createdDate').limit(pageSize);

    // if (startAfterDoc) ref = ref.startAfter(startAfterDoc);

    // ref
    //   .get()
    //   .then(snapshot => {
    //     const totalCount = snapshot.size;

    //     const data = [
    //       ...persistSlides,
    //       ...snapshot.docs.map(doc => {
    //         return {
    //           ...doc.data(),
    //           documentID: doc.id
    //         }
    //       })
    //     ];

    //     resolve({
    //       data,
    //       queryDoc: snapshot.docs[totalCount - 1],
    //       isLastPage: totalCount < 1
    //     });
    //   })
    //   .catch(err => {
    //     reject(err);
    //   })
  })
}



/**
 * handleDeleteSlide
 */
export const handleDeleteSlide = documentID => {
  return new Promise((resolve, reject) => {
    
  
    
   
    firestore
      .collection('slides')
      .doc(documentID)
      .delete()
      .then(() => {
        console.log(documentID, 2)
        resolve();
      })
      .catch(err => {
        reject(err);
      })


  });
}

/**
 * handleFetchSlide
 */
export const handleFetchSlide = (slideID) => {
  return new Promise((resolve, reject) => {
    
    
   
    
    firestore
      .collection('slides')
      .doc(slideID)
      .get()
      .then(snapshot => {

        if (snapshot.exists) {
          resolve({
            ...snapshot.data(),
            documentID: slideID
          });
        }
      })
      .catch(err => {
        reject(err);
      })



  })
}
