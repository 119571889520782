import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import logo from '../../assets/logo.png';
import { SiteConsumer } from '../../context';

import './styles.css';

const mapState = ({ user , cartData }) => ({
    currentUser : user.currentUser,
    cartItems: cartData.cartItems,
});

const Navbar = props => {
    
    const { currentUser } = useSelector(mapState);
    const { cartItems } = useSelector(mapState);
  
    return (
        <SiteConsumer>
          {(param) => {
                
        const totalQuantityItems = () => {
            
            let totalQantity = 0;

            cartItems.map((product, idx) => {
                return totalQantity = totalQantity  + product.quantity;
            });
           return totalQantity;
        }

        return(
        
            <header id="header" className="htc-header header--3 bg__white">
            <div id="sticky-header-with-topbar" className="mainmenu__area sticky__header">
                <div className="container">
                    <div className="row row-logo-line">
                        <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3">
                            <div className="logo">
                                <Link to='/' >
                                    <img src={ logo } alt="Anointed" className="navbar-brand" />
                                </Link>
                            </div>
                        </div>

                     
                        <div className="col-md-8 col-lg-8 col-sm-6 col-xs-6">

                            <nav className="mainmenu__nav hidden-xs hidden-sm">
                                <ul className="main__menu">
                                    <li className="drop">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="drop">
                                        <Link to="/shop">Shop</Link>
                                    </li>
                                    <li className="drop">
                                        <Link to="/about-us">About Us</Link>
                                    </li>
                                    
                                </ul>
                            </nav>
                            
                        </div>
                        <div className="col-md-2 col-sm-4 col-xs-3">  
                            <ul className="menu-extra">
                                

                            <li className="search search__open hidden-xs"
                                onClick={() => {
                                param.openSearch();
                            }}
                            ><span className="ti-search" style={{ fontSize: '21px', marginTop: '5px'}}></span></li>


                                <li className="search search__open hidden-xs">
                                    <Link className=""  
                                        to={{ pathname:"https://api.whatsapp.com/send?phone=27634481630&text=online shop query:&source=&data=&app_absent=" }}
                                        target="_blank" alt="whatsapp" >
                                            <span className="zmdi zmdi-whatsapp whatsapp-btn"></span>
                                    </Link>
                                </li>
                                        
                                <li>
                                    { currentUser &&  (
                                        <Link to="/user/account" title="LOG OUT"><span className="ti-user"></span></Link>
                                    )}
                                    { !currentUser && ( 
                                            <Link to="/login" title="LOGIN"><span className="ti-user"></span></Link>
                                    )}

                                </li>
                                    
                                <li className="cart__menu"
                                    onClick={ () => {
                                        param.openCart();
                                    }}
                                ><span className="ti-shopping-cart"></span>
                                <span className="cart-quantity">
                                    { cartItems.length > 0 ? 
                                        (
                                            totalQuantityItems()
                                        ) : 0
                                    }
                                 </span>
                                
                                </li>
                                <li className="toggle__menu hidden-xs hidden-sm"
                                    onClick={ () => {
                                        param.openSettings();
                                    }}
                                ><span className="ti-menu"></span></li>
                            </ul>
                        </div>
                    </div>
                    
                    <div className="mobile-menu-area mean-container"><div className="mean-bar">
                        <Link to="#nav"  
                            className={`meanmenu-reveal ${param.mobileMenuOpen ? "meanclose" : "" }`} 
                            style={{ right:"0",left: "auto", textAlign: "center", fontSize: "18px", textIndent: "0" }}
                            onClick={ () => {
                                param.toogleMobileMenu(param.mobileMenuOpen);
                            }}
                        >
                            <span></span><span></span><span></span>
                        </Link>
                        <nav className="mean-nav">
                            <ul className={`mobilemenu ${param.mobileMenuOpen ? "open-menu" : "close-menu" }`} >
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/shop">Shop</Link></li>
                                <li><Link to="/about-us">About us</Link></li>
                                <li className="mean-last"><Link to="/contact-us">Contact us</Link></li>
                            </ul>
                        </nav>
                        </div>
                        </div>

                            
                </div>
            </div>
        </header>
        )
        }}
    </SiteConsumer>
    )     

}

Navbar.defaultProps = {
    currentUser : null,
    mobileMenuOpen: false,
}

export default Navbar;