const articlesTypes = {
    ADD_NEW_ARTICLE_START: 'ADD_NEW_ARTICLE_START',
    FETCH_ARTICLES_START: 'FETCH_ARTICLES_START',
    FETCH_ARTICLES_CATEGORY_START: 'FETCH_ARTICLES_CATEGORY_START',
    SET_ARTICLES: 'SET_ARTICLES',
    DELETE_ARTICLE_START: 'DELETE_ARTICLE_START',
    FETCH_ARTICLE_START: 'FETCH_ARTICLE_START',
    SET_ARTICLE: 'SET_ARTICLE',
  };
  
  export default articlesTypes;