import React, { Component } from 'react';
import { FacebookProvider, Like, Share, ShareButton , Comments, EmbeddedPost, Page, Feed} from 'react-facebook';

export default class PolicyReturnsPage extends Component {
    render() {
        return (
            <div className="wrapper fixed__footer">
                <div className="row">

                <div className="ht__bradcaump__area">
                    <div className="ht__bradcaump__wrap">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="bradcaump__inner text-center">
                                        <h2 className="bradcaump-title">Private Policy</h2>
                                        <nav className="bradcaump-inner">
                                        <a className="breadcrumb-item" href="/">Home</a>
                                        <span className="brd-separetor">/</span>
                                        <span className="breadcrumb-item active">Returns Policy</span>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <section className="htc__store__area ptb--50 bg__white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section__title section__title--2 text-center">
                                    <h2 className="title__line">Returns Policy</h2>

                                    <p>
                                    Should you wish to exchange or return goods purchased within 14 days, we will gladly exchange them for
                                    you or refund you if you are not satisfied with our products.
                                    All returned items need to be unused and in perfect condition, and in their original
                                    packaging. We cannot accept returns of worn or damaged products.
                                    </p>
                                    <p> 
                                        The product must be defective in itself (due to manufacture or Print fault) and not damaged due to
                                    inappropriate use. A customer must provide full reasons for alleging that the product is defective,
                                    including stating how the product was utilized during the period that it was in the customer’s possession.
                                    </p>

                                    <p>
                                    We gladly exchange or refund the purchase within 7 working days. Where possible all refunds will be
                                    returned to the original forms of payment, otherwise an alternative refund will be offered.
                                    </p>

                                </div>
                             </div>
                        </div>
                    </div>
                </section>

            </div>
        </div>
        )
    }
}
