import React from "react";
import { useSelector } from "react-redux";

import Product from "./Product";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../Slideshow/slider.css";

const mapState = ({ productsData }) => ({
  homeLatestProducts: productsData.latestProducts,
});

const ProductTopCarouselCol = (props) => {
  const { homeLatestProducts } = useSelector(mapState);
  const column = 3;
  const { data } = homeLatestProducts; //, queryDoc, isLastPage

  if (!Array.isArray(data)) return null;
  if (data.length < 1) {
    <p>no products</p>;
  }

  return (
    <OwlCarousel
      className="product-slider-active owl-carousel"
      loop
      margin={0}
      nav
      navText={[
        '<i class="zmdi zmdi-chevron-left"></i>',
        '<i class="zmdi zmdi-chevron-right"></i>',
      ]}
      navElement={"div"}
      autoplayTimeout={10000}
      items={4}
      responsive={{
        0: {
          items: 1,
        },
        600: {
          items: 2,
        },
        800: {
          items: 2,
        },
        1024: {
          items: `${column}`,
        },
        1200: {
          items: `${column}`,
        },
        1400: {
          items: `${column}`,
        },
        1920: {
          items: `${column}`,
        },
      }}
    >
      {data.map((product, pos) => {
        const { productThumbnail, productName, productPrice } = product;
        if (
          !productThumbnail ||
          !productName ||
          typeof productPrice === "undefined"
        )
          return null;

        const configProduct = {
          ...product,
        };

        return <Product key={pos} {...configProduct} />;
      })}
    </OwlCarousel>
  );
};

export default ProductTopCarouselCol;
