import React from 'react'
import SlideshowCarausel from '../components/Slideshow/SlideshowCarousel';
import ProductList from '../components/Products/ProductList';
import FullWidthProductList from '../components/Products/FullWidthProductList';
import RandomSlides from '../components/Slides/RandomSlides';
import Banner from '../components/Banner/Banner';

import SubscriptionPopup from '../components/Subscription/SubscriptionPopup';

const HomePage = ( props ) => {

    return (
        <React.Fragment>
            
            <SlideshowCarausel />

            <div className="only-banner ptb--30 bg__white"></div>

            <ProductList />  

            <Banner />
            <div className="only-banner ptb--30 bg__white"></div>

            <FullWidthProductList />
            <div className="only-banner ptb--30 bg__white"></div>
            
             <RandomSlides /> 

             {/* <SubscriptionPopup /> */}

        </React.Fragment>
    ) 
    
}

export default  HomePage;