import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import './Page.css';


export default class DefaultPage extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-10 mx-auto text-center text-title text-uppercase pt-5">

                        <div class="coverlogo">
                                <Link Link to='/' >
                                    <img src={ logo } alt="Anointed"  />
                                </Link>
                        </div>


                        <h1 className="display-3">404</h1>
                        <h1>Error</h1>
                        <h2>page not found</h2>
                        <h3>the requested URL 
                            <span className="text-danger">
                            {this.props.location.pathname}
                            </span>
                          </h3>
                    </div>
                </div>
            </div>
        )
    }
}
