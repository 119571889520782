import sizeTypes from './size.types';

export const addSizeStart = data => ({
  type: sizeTypes.ADD_NEW_SIZE_START,
  payload: data
});

export const fetchSizesStart = (filters={}) => ({
  type: sizeTypes.FETCH_SIZES_START,
  payload: filters
});

export const setSizes = data => ({
  type: sizeTypes.SET_SIZES,
  payload: data
});

export const deleteSizeStart = sizeID => ({
  type: sizeTypes.DELETE_SIZE_START,
  payload: sizeID
});

export const fetchSizeStart = sizeID => ({
  type: sizeTypes.FETCH_SIZE_START,
  payload: sizeID
});

export const setSize = data => ({
  type: sizeTypes.SET_SIZE,
  payload: data
});

//---
export const editSizeStart = data => ({
  type: sizeTypes.EDIT_SIZE_START,
  payload: data
});
export const setEditSize = data => ({
  type: sizeTypes.SET_EDIT_SIZE,
  payload: data
});