const SPECIAL_PRICE = 399.0;
const NORMAL_PRICE = 249.95;

const cartspecials = (cartItems, currentProduct, processing) => {
  function roundN(num, n) {
    return parseFloat(
      Math.round(num * Math.pow(10, n)) / Math.pow(10, n)
    ).toFixed(n);
  }

  const processProductsOnSpecial = (cartItems, product) => {
    let totalQuantity = 1;

    cartItems.map((item) => {
      totalQuantity += item.quantity;
      item.productPrice = 0;
      item.special.description =
        "On special: 2 for  R " +
        SPECIAL_PRICE +
        " and 1 for  R " +
        NORMAL_PRICE;
    });

    //return;
    // hrzntl: quantity has remainders(ODD number)
    // [ODD]
    if (totalQuantity % 2 > 0) {
      if (totalQuantity === 1) {
        if (product.productPrice === SPECIAL_PRICE) {
          product.productPrice = product.productPrice + NORMAL_PRICE;
          product.special.description =
            "On special: 2 for  R " +
            SPECIAL_PRICE +
            " + , 1 x  R " +
            NORMAL_PRICE;
        }
        return;
      }

      let numProductQuantitySpecial = Math.floor(totalQuantity / 2); //eg [3/2 = 1] / [1/1 = 1]
      let numProductQuantityNormal = totalQuantity % 2; //eg. [1 % 2 = 1] [1%2] = 1

      let newPrice =
        numProductQuantityNormal * NORMAL_PRICE +
        numProductQuantitySpecial * SPECIAL_PRICE; //price for single product * quantity
      product.productPrice = newPrice;
      product.special.horzntl_alg_applied = false;
      product.special.description =
        "On special: " +
        numProductQuantitySpecial +
        " x  R " +
        SPECIAL_PRICE +
        " + , " +
        numProductQuantityNormal +
        " x  R " +
        NORMAL_PRICE;
    } else {
      // hrzntl: quantity has an even number
      // [EVEN]

      //else just continue to apply special this product
      let numProductQuantitySpecial = totalQuantity / 2;
      let newPrice = numProductQuantitySpecial * SPECIAL_PRICE;
      product.productPrice = newPrice;
      product.special.horzntl_alg_applied = false;
      if (!product.special.description)
        product.special.description =
          "On special: buy 2 for  R " + SPECIAL_PRICE;
    }
  };

  const processProductRemoval = (cartItems) => {
    let totalQuantity = 0;

    cartItems.map((item) => {
      totalQuantity += item.quantity;
      item.productPrice = 0;
      item.special.description =
        "On special: 2 for  R " +
        SPECIAL_PRICE +
        " and 1 for  R " +
        NORMAL_PRICE;
    });

    const product = [...cartItems].pop();

    //return;
    // hrzntl: quantity has remainders(ODD number)
    // [ODD]
    if (totalQuantity % 2 > 0) {
      if (totalQuantity === 1) {
        if (product.productPrice === SPECIAL_PRICE) {
          product.productPrice = product.productPrice + NORMAL_PRICE;
          product.special.description =
            "On special: 2 for  R " +
            SPECIAL_PRICE +
            "  and 1 for  R " +
            NORMAL_PRICE;
        } else if (product.productPrice === 0) {
          product.productPrice = product.productPrice + NORMAL_PRICE;
          product.special.description = "On special: 2 for  R " + SPECIAL_PRICE;
        }
        return;
      }

      let numProductQuantitySpecial = Math.floor(totalQuantity / 2); //eg [3/2 = 1] / [1/1 = 1]
      let numProductQuantityNormal = totalQuantity % 2; //eg. [1 % 2 = 1] [1%2] = 1

      let newPrice =
        numProductQuantityNormal * NORMAL_PRICE +
        numProductQuantitySpecial * SPECIAL_PRICE; //price for single product * quantity
      product.productPrice = newPrice;
      product.special.horzntl_alg_applied = false;
      product.special.description =
        "On special: " +
        numProductQuantitySpecial +
        " x  R " +
        SPECIAL_PRICE +
        " + , " +
        numProductQuantityNormal +
        " x  R " +
        NORMAL_PRICE;
    } else {
      // hrzntl: quantity has an even number
      // [EVEN]

      //else just continue to apply special this product
      let numProductQuantitySpecial = totalQuantity / 2;
      let newPrice = numProductQuantitySpecial * SPECIAL_PRICE;
      product.productPrice = newPrice;
      product.special.horzntl_alg_applied = false;
      if (!product.special.description)
        product.special.description =
          "On special: buy 2 for  R " + SPECIAL_PRICE;
    }
  };

  if (processing === "REMOVE") {
    processProductRemoval(cartItems, currentProduct);
    return;
  }

  processProductsOnSpecial(cartItems, currentProduct);
};

export default cartspecials;
