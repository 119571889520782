import articlesTypes from './articles.types';

export const addArticleStart = articleData => ({
  type: articlesTypes.ADD_NEW_ARTICLE_START,
  payload: articleData
});

export const fetchArticlesStart = (filters={}) => ({
  type: articlesTypes.FETCH_ARTICLES_START,
  payload: filters
});

export const fetchArticlesCategoryStart = (category) => ({
  type: articlesTypes.FETCH_ARTICLES_START,
  payload: category
});

export const setArticles = articles => ({
  type: articlesTypes.SET_ARTICLES,
  payload: articles
});

export const deleteArticleStart = articleID => ({
  type: articlesTypes.DELETE_ARTICLE_START,
  payload: articleID
});

export const fetchArticleStart = articleID => ({
  type: articlesTypes.FETCH_ARTICLE_START,
  payload: articleID
});

export const setArticle = article => ({
  type: articlesTypes.SET_ARTICLE,
  payload: article
});