import { firestore } from '../../firebase/util';

export const handleAddColor = color => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('product-colors')
      .doc()
      .set(color)
      .then(() => {
        resolve();
      })
      .catch(err => {
        reject(err);
      })
  });
}

export const handleFetchColors = ({ filterType, startAfterDoc, persistColors=[] }) => {
  return new Promise((resolve, reject) => {

    let ref = firestore.collection('product-colors').orderBy('value');

    if (startAfterDoc) ref = ref.startAfter(startAfterDoc);

    ref
      .get()
      .then(snapshot => {
        const totalCount = snapshot.color;

        const data = [
          ...persistColors,
          ...snapshot.docs.map(doc => {
            return {
              ...doc.data(),
              documentID: doc.id
            }
          })
        ];

        resolve({
          data,
          queryDoc: snapshot.docs[totalCount - 1],
          isLastPage: totalCount < 1
        });
      })
      .catch(err => {
        reject(err);
      })
  })
}

export const handleDeleteColor = documentID => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('product-colors')
      .doc(documentID)
      .delete()
      .then(() => {
        resolve();
      })
      .catch(err => {
        reject(err);
      })
  });
}

export const handleFetchColor = (colorID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('product-colors')
      .doc(colorID)
      .get()
      .then(snapshot => {

        if (snapshot.exists) {
          resolve({
            ...snapshot.data(),
            documentID: colorID
          });
        }
      })
      .catch(err => {
        reject(err);
      })
  })
}


/**
 * handleEditcolor
 */
 export const handleEditColor = color => {

  return new Promise((resolve, reject) => {
    firestore
      .collection('product-colors')
      .doc(color.documentID)
      .update (color)
      .then(() => {
        resolve();
      })
      .catch(err => {
        console.log(err);
        reject(err);
      })
  });
}