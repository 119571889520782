import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchColorsStart, setColor } from '../../redux/ProductColors/color.actions';

import { Link } from 'react-router-dom';
import LoadMore from '../../components/LoadMore/index';

//import AddRoundedIcon from '@material-ui/icons/AddRounded';
import Table from 'react-bootstrap/Table'

const mapState = ({ colorsData }) => ({
    colors: colorsData.colors
});

const AdminProductColors = props => {

  const dispatch = useDispatch();
  const { colors } = useSelector(mapState);
  const { data, queryDoc, isLastPage } = colors;

  const [ currentDocumentID, setCurrentDocumentID] = useState('');
  const [ showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
 
    useEffect(() => {
      dispatch(
        fetchColorsStart()
      );
    }, [dispatch]);

    const handleLoadMore = () => {
      dispatch(
        fetchColorsStart({
          startAfterDoc: queryDoc
        })
      );
    };

    const handleEditColor = (color) => {
        if(!color) return;
  
        dispatch(
          setColor(color)
        );
      }

      const handleDeletePopup = (color) =>{
        if (!color) return;

        dispatch(
          setColor(color)
       );
    }
    
      const configLoadMore = {
        onLoadMoreEvt: handleLoadMore,
       };
  

    return (   
      <React.Fragment>
       
            <div className="col-lg-9 col-md-8" style={{ minHeight: '600px' }} >

               <div className="property-filter d-sm-flex">

                  <ul className="property-short list-unstyled d-sm-flex mb-0">
                    <li>
                        <div className="form-group d-lg-flex d-block">
                          <label className="justify-content-start">Product Colors</label>
                        </div>
                    </li>
                  </ul>

                  <ul className="property-view-list list-unstyled d-flex mb-0">
                    <li>
                        <Link data-toggle="modal" data-target="#ColorAddModal" title="Quick View" className="btn btn-primary btn-block quick-view modal-view detail-link" to="#/addproductcolors"><i className="fa fa-circle" /> Add Product Color</Link>
                    </li>
                  </ul>
                </div>

                  <Table striped bordered hover size="sm">
                
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Value</th>
                      <th>Label</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>

          
                  {(Array.isArray(data) && data.length > 0) && data.map((color, index) => {
                      const {
                          value,
                          label,
                          documentID
                      } = color;

                      return (

                        <tr key={index}> 
                          <td title={ documentID }>{ index }</td>
                          <td>{ value }</td>
                          <td>{ label  }</td>
                          <td>
                              <div className="agent-social ml-auto mt-2 mt-sm-0">
                                <ul className="list-unstyled list-inline">
                                  <li className="list-inline-item"><a href="#/editcolor" id={documentID} onClick={() => handleEditColor(color)} data-toggle="modal" data-target="#colorEditModal" title="Update Product color"><i className="fab fa-edit"></i>EDIT </a></li>
                                  <li className="list-inline-item"><a href="#/deletecolor" id={documentID} onClick={() => handleDeletePopup(color) } data-toggle="modal" data-target="#colorDelModal" title="Remove Product color"><i className="fab fa-delete"></i>REMOVE </a></li>
                                </ul>
                              </div>
                          </td>
                        </tr>

      
                      )
                      })}

                        </tbody>
                      </Table>

                      <br />
          
                      {!isLastPage && (
                      <LoadMore {...configLoadMore} />
                      )}
        </div>

     </React.Fragment>
  );
}

export default AdminProductColors;