import React, { useState} from 'react';
import { SiteConsumer } from '../../context';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Checkout from '../Cart/Checkout/Checkout';
import LogoImg from '../../assets/logo.png';
import FormInput from '../Forms/FormInput/index';

const OffsetWrapper = props => {

    const { currentUser } = props;
    const [ keywords, setKeywords ] = useState('');
    const history = useHistory();

    return (
        <SiteConsumer> 
            {(param) => {
                
            const { 
                searchOpen, closeSearch,
                settingsOpen, closeSettings 
            
            } = param;

            document.body.classList.toggle('search__box__show__hide', searchOpen);

            const handleSearchSubmit = (e) => {
                e.preventDefault();
                closeSearch();
                if(!keywords) {
                    return;
                }
                history.push( '/search-results/' + keywords );

                setKeywords('');
        
            }
            
            return(
                <div className="offset__wrapper">

                    <div className="search__area">
                        <div className="container" >
                            <div className="row" >
                                <div className="col-md-12" >
                                    <div className="search__inner">
                                        
                                        <form  method="get" onSubmit={ (e) => handleSearchSubmit(e) }>
                                         
                                            <FormInput
                                                type="text"
                                                placeholder="Type here..."
                                                value={ keywords }
                                                handleChange={e => setKeywords(e.target.value)}
                                            />
        
                                            <button type="submit"><span className="zmdi zmdi-search search-btn"></span></button>
                                        </form> 


                                        <div className="search__close__btn">
                                            <span className="search__close__btn_icon"
                                                onClick={ () => { closeSearch();  setKeywords(''); } }
                                            ><i className="zmdi zmdi-close"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            
                
                    <div className={`offsetmenu ${settingsOpen ? "offsetmenu__on" : "" }`} >
                        <div className="offsetmenu__inner">
                            <div className="offsetmenu__close__btn">
                                <span 
                                    onClick={ () => closeSettings() }
                                ><i className="zmdi zmdi-close"></i>
                                </span>
                            </div>
                            <div className="off__contact settings">
                                <div className="logo">
                                    <Link to="/">
                                        <img src={ LogoImg} alt="Anointed" />
                                    </Link>
                                </div>

                                <li style={{ listStyle: "none" }}>
                                { currentUser &&  (
                                    <Link to="/user/account" title="Dashboard" onClick={  (e) => { closeSettings(); }}><span className="ti-user"></span>{ currentUser.displayName }</Link>
                                )}

                                </li>
                                <hr></hr>
                                <h2 className="delivery__heading">DELIVERY TERMS AND CONDITIONS</h2>
                                <p className="delivery__terms"> 
                                    Orders will be processed within 24 hours after receipt of payment In the event of the delivery being to a Regional Area, allow for 72 to 96 hours for delivery. 
                                    PAXI orders are only collected at preferred PEP branch. 
                                    Postnet orders are only collected at preferred POSTNET branch.
                                    ARAMEX is store to door delivery.
                                </p>
                            </div>
                            <ul className="sidebar__thumd">
                                
                            </ul>
                            <h4 className="offset__title btm__title">Delivery Options</h4>
                            <div className="offset__widget">
                                <div className="offset__single">
                                    
                                    <ul>
                                        <li><b> PAXI (PEP) </b></li>
                                        <li><b> PAXI (PEP) </b></li>
                                        <li><b> POSTNET </b></li>
                                        <li><b> ARAMEX </b></li>
                                    </ul>
                                </div>
                                <div className="offset__single">
                                   
                                    <ul>
                                        <li>R59.99 (7 - 9 Business days delivery)</li>
                                        <li> R99.99 (2 - 3 Business days delivery) </li>
                                        <li>R99 (1 - 3 Business days delivery) </li>
                                        <li>R99 (1 - 2 Business days door delivery)</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="offset__sosial__share">
                                <h4 className="offset__title">Follow Us On Social</h4>
                                <ul className="off__soaial__link">
                                    
                                    
                                    <li><Link className="bg--instagram" target="_blank" to={{ pathname: "https://instagram.com/anointed_republic" }} title="Instagram"><i className="zmdi zmdi-instagram"></i></Link></li>
                                    <li><Link className="bg--facebook" target="_blank" to={{ pathname: "https://facebook.com/anointedrepublic"}} title="Facebook"><i className="zmdi zmdi-facebook"></i></Link></li>

                                </ul>
                            </div>
                        </div>
                    </div>
            
                    <div>
                      <Checkout />
                    </div>
                  

                </div>
             )
            }}
        </SiteConsumer>
    );
    
}

OffsetWrapper.defaultProps = {
    currentUser : null
}

const mapStateToProps = ({ user }) => ({
    currentUser : user.currentUser
});


export default connect(mapStateToProps, null) (OffsetWrapper);