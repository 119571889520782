import sizeTypes from './size.types';

const INITIAL_STATE = {
  sizes: [],
  size: {},
};

const sizesReducer = (state=INITIAL_STATE, action) => {
  switch(action.type) {
    case sizeTypes.SET_SIZES:
      return {
        ...state,
        sizes: action.payload
      }

    case sizeTypes.SET_SIZE:
      return {
        ...state,
        size: action.payload
      }
    default:
      return state;
  }
};

export default sizesReducer;