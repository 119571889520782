import React, { Component } from 'react'
import { SiteConsumer } from '../../context';
export default class Banner extends Component {
    render() {
        return (
          <SiteConsumer>
            {( param ) => {
     
                return(
                    <div className="only-banner ptb--100 bg__white">
                        <div className="container">
                            <div className="only-banner-img">
                               <img src='https://www.services.anointedrepublic.co.za/assets/Banner/1170x300.jpeg?vid=2'  alt="banner" />
                            </div>
                        </div>
                    </div>
                )

                }}
           
            </SiteConsumer>  
        )
    }
}
